import React from "react"
import {RouteComponentProps} from "react-router"
import {useTranslation} from "react-i18next"

import {useKeys as useItems} from "../../contexts/KeyContext"

import ItemForm                     from "../../components/Keys/Form"
import ItemView                     from "../../components/KeysInUse/View"
import LockingSystemPermissionsList from "../../components/Keys/KeyLockingSystemPermissions/List"
import LockingSystemZonesList       from "../../components/Keys/KeyLockingSystemZones/List"
import PageItem                     from "../common/Item"
import NotFound                     from "../../components/KeysInUse/NotFound"

interface pageProps extends RouteComponentProps<{
    id:      string
    segment: string
}> {}


const PageKeysInUseItem: React.FC<pageProps> = ({ match }) => {
    const { t }        = useTranslation()
    const { itemsInUse: items, fetchItemsInUse } = useItems()

    const
        itemType:     string = 'KeysInUse',
        itemTypeName: string = t('keys.keyGroup.keys_in_use.title_item'),
        itemTypeUrl:  string = 'keys-in-use'

    return (
        <PageItem
            useItems={useItems}
            fetchItems={fetchItemsInUse}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            ItemForm={ItemForm}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            LockingSystemPermissionsList={LockingSystemPermissionsList}
            LockingSystemZonesList={LockingSystemZonesList}
            segment={match.params.segment}
        />
    )
}

export default PageKeysInUseItem