import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon, useIonAlert} from "@ionic/react"
import {trashOutline, trashSharp} from "ionicons/icons"


import {useKeys} from "../../../contexts/KeyContext";
import Key from "../../../types/Key"

interface componentProps {
    item: Key
    setSelectedLockingSystemPermissions: any;
}

const ButtonDeleteAll: React.FC<componentProps> = ({item, setSelectedLockingSystemPermissions} ) => {

    const { t } = useTranslation()
    const [presentAlert] = useIonAlert();

    const { deleteAllPermissions } = useKeys()

    const alertDeleteAll = (ev: any) => {
        return presentAlert({
            header:  t('keyLockingSystemPermissions.alerts.delete_all.header'),
            message: t('keyLockingSystemPermissions.alerts.delete_all.message'),
            buttons: [
                {
                    text: t('common.cancel'),
                    role: 'cancel',
                },
                {
                    text: t('common.continue'),
                    role: 'confirm',
                    handler: () => {
                        onDeleteAll()
                    },
                },
            ]
        })
    }

    const onDeleteAll = async () => {
        await deleteAllPermissions(item)
        setSelectedLockingSystemPermissions([])
    }

    return (
        <IonButton color="danger" fill="clear" onClick={(ev) => alertDeleteAll(ev)}
                   size="small" type="button">
            <IonIcon slot="start" ios={trashOutline} md={trashSharp} />
            {t('common.remove_all_permissions')}
        </IonButton>
    )

}

export default ButtonDeleteAll