import React               from "react"

import IconLockingSystem   from "../common/Icons/LockingSystem"

import ItemViewHeader      from "../common/Headers/ItemViewHeader"
import ItemGroupApiLink    from "./ItemGroupApiLink"
import LockingSystem       from "../../types/LockingSystem"
import SyncCollectionsList from "./SyncCollectionsList"

export interface props {
    item: LockingSystem;
}

const View: React.FC<props> = ({ item }: props) => {

    const
        viewHeaderIcon: JSX.Element = <IconLockingSystem link={'/locking-systems/' + item.id + '/form'} size="large"
                                            text={item.name} />,
        viewHeaderTitle: string     = item?.name,
        viewHeaderSubTitle: string  = item?.lockingSystemType?.brand.name + ' - ' + item?.lockingSystemType?.name

    return (
        <div className="ion-text-center">
            <ItemViewHeader icon={viewHeaderIcon} title={viewHeaderTitle} subTitle={viewHeaderSubTitle} />
            <SyncCollectionsList item={item} />
            {item.lockingSystemApi?.id && <ItemGroupApiLink item={item} />}
        </div>
    );
}

export default View