import React from "react"
import {useTranslation} from "react-i18next"

import {useLockingSystemZones as useItems} from "../../contexts/LockingSystemZoneContext"

import ItemList from '../../components/LockingSystemZones/List'
import PageList from "../common/List"


const PageLockingSystemZonesList: React.FC = () => {

    const { t } = useTranslation()

    const { fetchItems } = useItems()

    const itemType: string     = 'lockingSystemZones'
    const itemTypeName: string = t('locking-system-zones.title_list')
    const itemTypeUrl: string  = 'locking-system-zones'

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            showAddButton={false}
            fetchItems={fetchItems}
            useItems={useItems}
        />
    )
}

export default PageLockingSystemZonesList