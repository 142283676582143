import React from "react"
import {User} from "../types/User";
import {UserApi} from "../services/UserApi";

export type UserContextType = {
    fetchItems: (search?: string, force?: boolean) => void
    items:      User[] | undefined

    loadingItems: boolean
}

const initialState = {
    fetchItems:   () => {},
    items:        undefined,
    loadingItems: true
}

export const UserContext = React.createContext<UserContextType>(initialState)

interface UserProviderProps {
    children: React.ReactNode
}

const UserProvider: React.FC<UserProviderProps> = ( { children }) => {

    const [items, setItems] = React.useState<User[] | undefined>(initialState.items)
    const [loadingItems, setLoadingItems] = React.useState<boolean>(initialState.loadingItems)

    const fetchItems = async (search?: string, force: boolean = false) => {
        if (items === undefined || force) {
            setLoadingItems(true)
            setItems( await UserApi.getAll(search) )
            setLoadingItems(false)
        }
    }

    return (
        <UserContext.Provider
            value={{
                fetchItems,
                items,
                loadingItems
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export const useUsers = () => {
    return React.useContext(UserContext) as UserContextType
}

export default UserProvider