import React from 'react'
import {IonIcon, IonItemOption, useIonAlert} from "@ionic/react";
import {trashOutline, trashSharp} from "ionicons/icons";
import {useTranslation} from "react-i18next";

import LockType from "../../types/Lock";
import {useLocks as useItems} from "../../contexts/LockContext";

interface componentProps {
    item: LockType;
}

const ListItemOptionDelete: React.FC<componentProps> = ({item}) => {
    const { deleteItem } = useItems()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertDelete = async () => {
        return presentAlert({
            header: 'Wil je dit slot verwijderen?',
            buttons: [
                {
                    text:    t('common.cancel'),
                    role:    'cancel',
                    handler: () => onCancel(),
                },
                {
                    text:    t('common.delete'),
                    role:    'confirm',
                    handler: () => onDelete(),
                },
            ],
        })
    }

    const onCancel = () => {
        closeItemSliding()
    }

    const onDelete = () => {
        closeItemSliding()
        deleteItem(item.id)
    }

    return (
        <IonItemOption color="danger" onClick={() => alertDelete()}>
            <IonIcon slot="top" ios={trashOutline} md={trashSharp} />
            {t('common.delete')}
        </IonItemOption>
    )
}

export default ListItemOptionDelete