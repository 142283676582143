import React from "react"
import {useTranslation} from "react-i18next"

import {useUsers as useItems} from "../../contexts/UserContext"

import ItemList from '../../components/Users/List'
import PageList from '../common/List'

const PageUsersList: React.FC = () => {

    const { t } = useTranslation()

    const { fetchItems } = useItems()

    const
        itemType: string     = 'Users',
        itemTypeName: string = t('users.title_list'),
        itemTypeUrl: string  = 'users'

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            showAddButton={false}
            fetchItems={fetchItems}
            useItems={useItems}
        />
    )
}

export default PageUsersList