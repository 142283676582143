import React from "react"
import {IonButton, IonIcon, useIonAlert} from "@ionic/react";
import {refreshCircleOutline, refreshCircleSharp} from "ionicons/icons";
import {useTranslation} from "react-i18next";

import KeyType from "../../types/Key";
import BlocklistKey from "../../types/BlocklistKey";
import {useKeys as useItems} from "../../contexts/KeyContext";
import {useHistory} from "react-router";

interface componentProps {
    item: KeyType;
    blocklistKey?: BlocklistKey;
}

const ButtonReset: React.FC<componentProps> = ({item, blocklistKey}) => {
    const { resetItem }  = useItems()
    const history        = useHistory()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const alertReset = async () => {
        return presentAlert({
            header: "Wil je deze sleutel helemaal leegmaken?",
            subHeader: 'Dit zal alle rechten van de sleutel halen en de omschrijving van de sleutel resetten.',
            message: 'Na een reset, is de sleutel in de Sleutelkluis terug te vinden.',
            buttons: [
                {
                    text:    t('common.cancel'),
                    role:    'cancel'
                },
                {
                    text:    t('common.reset'),
                    role:    'confirm',
                    handler: () => onReset(),
                },
            ],
        })
    }

    const onReset = async () => {
        const result = await resetItem(item, blocklistKey)

        if (result && result.id) {
            history.push('/keys-in-safe')
        }
    }

    return (
        <IonButton type="button" color="tertiary" fill="outline" className="ion-padding-start"
                   onClick={() => alertReset()}>
            <IonIcon slot="start" ios={refreshCircleOutline} md={refreshCircleSharp} />
            {t('common.reset')}
        </IonButton>
    )
}

export default ButtonReset
