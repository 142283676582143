import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound from "../common/Items/ItemNotFound"
import IconLock     from "../common/Icons/Lock"

const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/locks'
    const icon: JSX.Element = <IconLock link={link} size="large" />

    return (
        <ItemNotFound icon={icon} link={link} subTitle={t('locks.not_found')} />
    )
}

export default NotFound