import React from "react"

import ItemType from "../../types/Key"

import ItemGroupInfo   from "../Keys/View/ItemGroupInfo"
import ItemGroupLinked from "./ItemGroupLinked"
import ViewHeader      from "./ViewHeader"
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import ButtonSync from "../Keys/ButtonSync";
import ButtonBlock from "../Keys/ButtonBlock";

export interface props {
    item: ItemType
}

const View: React.FC<props> = ({ item }) => {

    const viewButtons = () => {
        if (undefined !== item) {
            return (
                <>
                    {item.options?.isSynchronizable && <ButtonSync item={item} />}
                    <ButtonBlock item={item} />
                </>
            )
        }
    }

    return (
        <div className="ion-text-center">
            <ViewHeader      item={item}    />
            <ItemGroupInfo   keyItem={item} />
            <ItemGroupLinked item={item}    />

            <IonGrid className="ion-margin-top">
                <IonRow>
                    <IonCol className="ion-text-right">
                        {viewButtons()}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default View