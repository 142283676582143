import React from "react"
import {Redirect, Route} from "react-router-dom"

import {IonRouterOutlet} from "@ionic/react";

import BlocklistKeys            from "./BlocklistKeys"
import DashboardPage            from "../pages/Dashboard/Dashboard"
import KeysInSafe               from "./KeysInSafe"
import KeysInUse                from "./KeysInUse"
import LockingSystemPermissions from "./LockingSystemPermissions"
import LockingSystems           from "./LockingSystems"
import LockingSystemZones       from "./LockingSystemZones"
import Locks                    from "./Locks"
import People                   from "./People"
import Users                    from "./Users"

import Logout                   from "../pages/Auth/Logout"

const RoutesAuthenticated = () => {
    return (
        <IonRouterOutlet id="main" animated={false}>

            <Route path="/blocklist-keys"             component={BlocklistKeys} />
            <Route path="/dashboard"                  component={DashboardPage} />
            <Route path="/keys-in-safe"               component={KeysInSafe} />
            <Route path="/keys-in-use"                component={KeysInUse} />
            <Route path="/locking-system-permissions" component={LockingSystemPermissions} />
            <Route path="/locking-system-zones"       component={LockingSystemZones} />
            <Route path="/locking-systems"            component={LockingSystems} />
            <Route path="/locks"                      component={Locks} />
            <Route path="/people"                     component={People} />
            <Route path="/users"                      component={Users} />

            <Route path="/logout" exact               component={Logout} />

            <Redirect from="/" to="/dashboard" exact />

            <Route render={() => <Redirect to="/dashboard" />} />

        </IonRouterOutlet>
    )
}

export default RoutesAuthenticated