import React from 'react'
import {IonSplitPane} from "@ionic/react"

import Menu                from "./components/common/Menu"
import NavBar              from "./components/common/Bars/NavBar"
import RoutesAuthenticated from "./routes/Authenticated"

import { default as PeopleProvider                  } from "./contexts/PersonContext"
import { default as KeyProvider                     } from "./contexts/KeyContext"
import { default as LockProvider                    } from "./contexts/LockContext"
import { default as LockingSystemPermissionProvider } from "./contexts/LockingSystemPermissionContext"
import { default as LockingSystemProvider           } from "./contexts/LockingSystemContext"
import { default as LockingSystemZoneProvider       } from "./contexts/LockingSystemZoneContext"
import { default as UserProvider                    } from "./contexts/UserContext"

const AuthenticatedApp = () => {
    return (
        <>
            <LockingSystemProvider>
            <UserProvider>
            <PeopleProvider>
            <KeyProvider>
            <LockProvider>
            <LockingSystemPermissionProvider>
            <LockingSystemZoneProvider>

                <NavBar />
                <IonSplitPane contentId="main">
                    <Menu />
                    <RoutesAuthenticated />
                </IonSplitPane>

            </LockingSystemZoneProvider>
            </LockingSystemPermissionProvider>
            </LockProvider>
            </KeyProvider>
            </PeopleProvider>
            </UserProvider>
            </LockingSystemProvider>
        </>
    )
}

export default AuthenticatedApp