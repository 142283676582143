import React from "react"

import {IonCol, IonGrid, IonRow} from "@ionic/react"

import ItemType        from "../../types/BlocklistKey"

import ButtonUnblock   from "../Keys/ButtonUnblock"
import ItemGroupInfo   from "../Keys/View/ItemGroupInfo"
import ItemGroupLinked from "../Keys/View/ItemGroupLinked"
import ViewHeader      from "./ViewHeader"

export interface props {
    item: ItemType
}

const View: React.FC<props> = ({ item }) => {

    return (
        <div className="ion-text-center">
            <ViewHeader item={item} />

            <ItemGroupInfo blocklistKeyItem={item} keyItem={item.key} />
            <ItemGroupLinked item={item.key} keyCollectionItemUrl={'/blocklist-keys/' + item.id} />

            <IonGrid className="ion-margin-top">
                <IonRow>
                    <IonCol className="ion-text-right">
                        <ButtonUnblock item={item} />
                    </IonCol>
                </IonRow>
            </IonGrid>

        </div>
    )
}

export default View