import ApiClient from "./ApiClient"

import KeyPhoneType from "../types/KeyPhone";
import SyncResult from "../types/SyncResult";

export const KeyPhoneApi = {

    apiPath: 'key-phones',

    reRegister: async(item: KeyPhoneType): Promise<any> => {

        const result = await ApiClient.post(KeyPhoneApi.apiPath + '/' + item.id + '/re-register')

        return result.data.data;
    },

    return: async(keyPhoneId: number): Promise<any> => {

        await ApiClient.post(KeyPhoneApi.apiPath + '/' + keyPhoneId + '/return')

    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(KeyPhoneApi.apiPath + '/sync/all', { params })

        return result?.data
    },

}

export default KeyPhoneApi