import axios from "axios"

export const ApiClient = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
        // 'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
    }

    , "xsrfCookieName": "XSRF-TOKEN"
    , "xsrfHeaderName": "X-XSRF-TOKEN"

    , "withXSRFToken": true,

    // headers: {
    //     accept: 'application/json',
    //     Authorization: 'Bearer ' + response.data.token

    // 'X-CSRF-TOKEN': ''

    // }
})

// axios.defaults.withXSRFToken = (config) => !!config.useCredentials;
/*

// this was a temp fix before withXSRFToken option was added to Axios

ApiClient.interceptors.request.use(function (config) {
   config.headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN')

   return config
});
*/


// defining a custom error handler for all APIs
/*
const errorHandler = (error: any) => {
    const statusCode = error.response?.status

    if (statusCode === 401 && window.location.pathname !== '/login') {
        document.location = '/login'

        return
    }

    // logging only errors that are not 401
    //if (statusCode && statusCode !== 401) {
    //    console.error('ApiClient error: ' + error)
    //}

    return Promise.reject(error)
}

// registering the custom error handler to the
// "ApiClient" axios instance

// ApiClient.interceptors.response.use(undefined, (error) => {
//     return errorHandler(error)
// })
*/

export default ApiClient