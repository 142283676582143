import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound from "../common/Items/ItemNotFound"
import IconKeyInUse from "../common/Icons/KeyInUse"

const NotFound: React.FC = () => {

    const
        { t } = useTranslation(),
        link: string = '/keys-in-use',
        icon: JSX.Element = <IconKeyInUse icon='key' link={link} size="large" />

    return <ItemNotFound icon={icon} link={link} subTitle={t('keys.not_found')} />
}

export default NotFound