import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon, useIonAlert} from "@ionic/react"
import {alertOutline, alertSharp, trashOutline, trashSharp} from "ionicons/icons"

import {useKeys} from "../../../contexts/KeyContext"

import Key                     from "../../../types/Key"
import LockingSystemPermission from "../../../types/LockingSystemPermission"

interface componentProps {
    item:    LockingSystemPermission
    keyItem: Key
}


const ButtonDelete: React.FC<componentProps> = ({item, keyItem} ) => {

    const { t } = useTranslation()
    const [presentAlert] = useIonAlert();

    const { deleteLockingSystemPermission } = useKeys()

    const alertDelete = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (item.options?.isRemovableFromKey) {
            return presentAlert({
                header:  t('keyLockingSystemPermissions.alerts.delete.header'),
                message: t('keyLockingSystemPermissions.alerts.delete.message'),
                buttons: [{
                    text: t('common.cancel'),
                    role: 'cancel',
                }, {
                    text: t('common.continue'),
                    role: 'confirm',
                    handler: () => { onDelete() },
                },]
            })
        }

        return presentAlert({
            header:  t('keyLockingSystemPermissions.alerts.delete_not_possible.header'),
            message: t('keyLockingSystemPermissions.alerts.delete_not_possible.message'),
            buttons: [{
                text: t('common.cancel'),
                role: 'cancel',
            }]
        })
    }

    const onDelete = async () => {
        await deleteLockingSystemPermission(keyItem, item)
    }

    return (
        <IonButton fill="clear" slot="end" onClick={(ev) => alertDelete(ev)} type="button">
            {item.options?.isRemovableFromKey ?
                <IonIcon color="medium" ios={trashOutline} md={trashSharp} slot="icon-only" /> :
                <IonIcon color="medium" ios={alertOutline} md={alertSharp} slot="icon-only" />
            }
        </IonButton>
    )
}

export default ButtonDelete