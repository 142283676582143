import React from 'react'
import {useHistory} from "react-router"

import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel} from "@ionic/react";
import {
    keyOutline,
    keySharp,
    lockClosedOutline,
    lockClosedSharp, shieldCheckmarkOutline, shieldCheckmarkSharp
} from "ionicons/icons";

import IconLockingSystemZone from "../common/Icons/LockingSystemZone";
import {LockingSystemZone as ItemType} from "../../types/LockingSystemZone"
import {useTranslation} from "react-i18next";

interface itemProps {
    item: ItemType;
}

const ListItem: React.FC<itemProps> = ({ item}) => {
    const
        keysCount: number  = item.keys?.length  || 0,
        locksCount: number = item.locks?.length || 0,
        lockingSystemPermissionCount: number = item.lockingSystemPermissions?.length || 0
    ;

    const { t } = useTranslation()

    const history = useHistory()

    const goToSegment = (ev: any, itemId: number, segment: string) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push('/locking-system-zones/' + itemId + '/' + segment)
    }

    return (
        <>
            <IonItem button routerLink={"/locking-system-zones/" + item.id + "/view"} slot="start" detail={true}>
                <IconLockingSystemZone slot="start" />
                <IonLabel>
                    <h2>{item.name}</h2>
                    <p>{item.description || '(' + t('common.no_description') + ')'}</p>
                </IonLabel>
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton className="list-item-count" color="medium" fill="clear"
                               onClick={ (ev) => goToSegment(ev, item.id, 'keys') }
                    >
                        {keysCount ? keysCount : '-'}
                        <IonIcon slot="end" color="bc-blue" ios={keyOutline} md={keySharp} />
                    </IonButton>
                    <IonButton className="list-item-count" color="medium" fill="clear"
                               onClick={ (ev) => goToSegment(ev, item.id, 'locks') }
                    >
                        {locksCount ? locksCount : '-'}
                        <IonIcon slot="end" color="bc-blue" ios={lockClosedOutline} md={lockClosedSharp} />
                    </IonButton>
                    <IonButton className="list-item-count" color="medium" fill="clear"
                               onClick={ (ev) => goToSegment(ev, item.id, 'lockingSystemPermissions') }
                    >
                        {lockingSystemPermissionCount ? lockingSystemPermissionCount : '-'}
                        <IonIcon slot="end" color="bc-blue" ios={shieldCheckmarkOutline} md={shieldCheckmarkSharp} />
                    </IonButton>

                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                {/*<ListItemOptionDelete item={item} />*/}
            </IonItemOptions>
        </>
    )
}

export default ListItem