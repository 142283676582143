import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {IonAccordion, IonItem, IonLabel, IonNote, IonSelect, IonSelectOption} from "@ionic/react"

import LockingSystemZone from "@customTypes/LockingSystemZone"
import Person from "@customTypes/Person"
import {LockingSystemZoneApi} from "../../../services/LockingSystemZoneApi"
import LockingSystem from "@customTypes/LockingSystem";

interface componentProps {
    formData:          Person
    lockingSystem:     LockingSystem | undefined
    setFormData:       React.Dispatch<React.SetStateAction<Person>>
    updateFormHandler: any
}

export const AccordionLockingSystem: React.FC<componentProps> = ({
    formData, lockingSystem, setFormData, updateFormHandler
}) => {

    const { t } = useTranslation()

    const [
        lockingSystemZones, setLockingSystemZones
    ] = React.useState<LockingSystemZone[]>([])



    React.useEffect(() => {
        const fetchLockingSystemZones = async () => {
            if (lockingSystemZones.length === 0) {
                setLockingSystemZones(await LockingSystemZoneApi.getAll())
            }
        }

        fetchLockingSystemZones()
    }, [])

    useEffect(() => {

        if (lockingSystemZones.length && formData.lockingSystemZoneId === 0) {
            let foundDefaultZoneForPeople: LockingSystemZone | undefined

            if (lockingSystem !== undefined && lockingSystem.defaultLockingSystemZoneIdPeople !== undefined) {
                // check if zone exists
                foundDefaultZoneForPeople = lockingSystemZones.find(
                    (zone) => zone.id === lockingSystem.defaultLockingSystemZoneIdPeople
                )
            }

            if (foundDefaultZoneForPeople === undefined) {
                foundDefaultZoneForPeople = lockingSystemZones.find(
                    (zone) => zone.isDefaultForPeople
                )
            }

            if (undefined !== foundDefaultZoneForPeople) {
                setFormData({ ...formData, lockingSystemZoneId: foundDefaultZoneForPeople.id })
            }
        }

    }, [lockingSystem, lockingSystemZones])

    return (
        <IonAccordion toggleIconSlot="start" value="lockingSystem">
            <IonItem className="bc-accordion" slot="header">
                <IonLabel>{t('common.locking_system')}</IonLabel>
            </IonItem>
            <IonItem slot="content">
                <IonLabel>{t('keys.form.locking_system')}</IonLabel>
                <IonNote slot="end">{lockingSystem ? lockingSystem.name : '?'}</IonNote>
            </IonItem>
            <IonItem slot="content">
                <IonSelect
                           okText={t('common.select') as string}
                           cancelText={t('common.cancel') as string}
                           label={t('common.select') + ' ' + t('locking-system-zones.title_item')}
                           labelPlacement="floating"
                           value={formData.lockingSystemZoneId}
                           tabIndex={0}
                           interfaceOptions={{cssClass: 'alert-select-width-90'}}
                           onIonChange={updateFormHandler('lockingSystemZoneId')}
                >
                    {lockingSystemZones.length && lockingSystemZones.map((lockingSystemZone) => (
                        <IonSelectOption key={lockingSystemZone.id} value={lockingSystemZone.id}>
                            {lockingSystemZone.name +
                                (lockingSystemZone.isDefaultForPeople
                                    ? ' (' + t('locking-system-zones.form.default_zone') + ')' : '')
                            }
                        </IonSelectOption>
                    ))}
                </IonSelect>
            </IonItem>
        </IonAccordion>
    )
}

export default AccordionLockingSystem