import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound  from "../common/Items/ItemNotFound"
import IconKeyInSafe from "../common/Icons/KeyInSafe"

const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/keys-in-safe'
    const icon: JSX.Element = <IconKeyInSafe icon='key' link={link} size="large" />

    return (
        <ItemNotFound icon={icon} link={link} subTitle={t('keys.not_found')} />
    )
}

export default NotFound