import ApiClient from "./ApiClient"

import KeyType                    from "../types/Key"
import SyncResult from "../types/SyncResult"

export const KeyApi = {

    get: async (id: number): Promise<KeyType> => {

        const response = await ApiClient.get('keys/' + id)

        return response.data.data
    },

    getKeysInSafe: async (search: string = ''): Promise<KeyType[]> => {
        const items: KeyType[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get('keys-in-safe', { params })

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    getKeysInUse: async (search: string = ''): Promise<KeyType[]> => {
        const items: KeyType[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get('keys-in-use', { params })

        if (response) {
            for (let item of response.data.data) {
                items.push(item)
            }
        }

        return items
    },

    add: async (newItem: KeyType, handleAddItem: (item: KeyType) => void): Promise<KeyType | void> => {

        const result = await ApiClient.post('keys', newItem)

        return result?.data.data || undefined;
    },

    edit: async (item: KeyType): Promise<any> => {

        const result = await ApiClient.put('keys/' + item.id, item);

        return result.data?.data
    },

    delete: async(id: number): Promise<any> => {

        const result = await ApiClient.delete('keys/' + id);

        return result.data
    },

    reset: async (item: KeyType): Promise<KeyType | void> => {
        if (item.lockingSystemId === undefined) return

        const params = new URLSearchParams([['lockingSystemId', item.lockingSystemId.toString()]])

        const result = await ApiClient.get('key-reset/' + item.id, { params })

        return result.data?.data

    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([
            ['lockingSystemId', lockingSystemId.toString()]
        ])

        const result = await ApiClient.get('keys/sync/all', { params })

        return result?.data
    },

    syncItemById: async (item: KeyType, softSync: boolean): Promise<KeyType | void> => {
        if (item.lockingSystemId === undefined) return

        const params = new URLSearchParams([['lockingSystemId', item.lockingSystemId.toString()]])

        if (softSync) params.append('soft', softSync.toString())

        const result = await ApiClient.get('keys/sync/' + item.id, { params })

        return result?.data?.data
    }
}

export default KeyApi