import React from "react"

import LockingSystemPermission from "../../types/LockingSystemPermission"
import ViewHeader              from "./ViewHeader"
import ViewItemGroupInfo       from "./ViewItemGroupInfo"
import ViewItemGroupLinked     from "./ViewItemGroupLinked"

export interface props {
    item: LockingSystemPermission
}

const View: React.FC<props> = ({ item }) => {

    return (
        <div className="ion-text-center">
            <ViewHeader          item={item} />
            <ViewItemGroupInfo   item={item} />
            <ViewItemGroupLinked item={item} />
        </div>
    )

}

export default View