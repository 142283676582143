import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import Key              from "../../../types/Key"
import ButtonReRegister from "../ButtonReRegister"

export interface props {
    item: Key
}

const ItemGroupKeyPhone: React.FC<props> = ({item}) => {

    const { t } = useTranslation()

    if (item.keyPhone === undefined || item.keyPhone === null) {
        return (<div>No KeyPhone</div>)
    }

    return (
        <IonItemGroup className="ion-margin-top">
            <IonItemDivider>{t('keyPhones.title_item')}</IonItemDivider>
            <IonItem lines="full">
                <IonLabel>{t('keyPhones.form.email')}</IonLabel>
                <IonNote slot="end">{item?.keyPhone?.email || '-' }</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('keyPhones.form.phone_no')}</IonLabel>
                <IonNote slot="end">{item?.keyPhone?.phoneNo || '-'}</IonNote>
            </IonItem>
            <ButtonReRegister item={item} />
        </IonItemGroup>
    )

}

export default ItemGroupKeyPhone