import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon, useIonAlert} from "@ionic/react"

import {useLocks as useItems} from "../../../contexts/LockContext"
import Lock                   from "../../../types/Lock"
import {trashOutline, trashSharp} from "ionicons/icons";

interface props {
    lock:                                 Lock
    setSelectedLockingSystemPermissions?: any
}

const ButtonDeleteAll: React.FC<props> = ({lock, setSelectedLockingSystemPermissions}: props) => {

    const { t } = useTranslation()
    const [ presentAlert ] = useIonAlert()

    const {deleteAllPermissions} = useItems()

    const alertDeleteAll = (ev: any) => {
        return presentAlert({
            header:  t('lockLockingSystemPermissions.alerts.delete_all.header'),
            message: t('lockLockingSystemPermissions.alerts.delete_all.message'),
            buttons: [{
                text: t('common.cancel'),
                role: 'cancel',
            }, {
                text: t('common.continue'),
                role: 'confirm',
                handler: () => {
                    onDeleteAll();
                },
            },]
        })
    }

    const onDeleteAll = async () => {
        await deleteAllPermissions(lock)
    }

    return (
        <IonButton color="danger" fill="clear" onClick={(ev) => alertDeleteAll(ev)}
                   size="small" type="button">
            <IonIcon slot="start" ios={trashOutline} md={trashSharp} />
            {t('common.remove_all_permissions')}
        </IonButton>
    )
}

export default ButtonDeleteAll
