import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import Lock from "../../types/Lock"


export interface componentProps {
    item: Lock
}

const ItemGroupInfo: React.FC<componentProps> = ( {item} ) => {

    const {t} = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>
                {t('common.info')}
            </IonItemDivider>
            {item.locationCode &&
                <IonItem lines="full">
                    <IonLabel>{t('locks.form.location_code')}</IonLabel>
                    <IonNote slot="end">{item.locationCode || '-'}</IonNote>
                </IonItem>
            }
            <IonItem lines="full">
                <IonLabel>{t('locks.form.object')}</IonLabel>
                <IonNote slot="end">{item.lockingObject || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('locks.form.programming_state')}</IonLabel>
                <IonNote slot="end">{item.lockProgrammingState?.name || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('locks.form.serial_number')}</IonLabel>
                <IonNote slot="end">{item.serial || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('common.synced_at')}</IonLabel>
                <IonNote slot="end">{item.meta?.syncedAt || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ItemGroupInfo