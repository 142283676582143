import React from "react"

import TypeIcon from "./TypeIcon"

import {getIosIcon, getMDIcon} from "../../../types/KeyTypeIcon"

interface componentProps {
    icon?: string;
    link?: string;
    size?: "small" | "default" | "large";
    slot?: string;
    text?: string;
}

const IconBlocklistKey: React.FC<componentProps> = (
    { icon = 'unknown', link, size, slot, text }
) => {

    return <TypeIcon
        backgroundColor={'red'}
        ios={getIosIcon(icon)}
        md={getMDIcon(icon)}
        link={link}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconBlocklistKey