import i18n                 from 'i18next'
import Backend              from 'i18next-http-backend'
import LanguageDetector     from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
        },
        debug: process.env.NODE_ENV === 'development',
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie']
        },
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false
        },
        supportedLngs: ['de', 'en', 'nl', ]
    }
)

export default i18n