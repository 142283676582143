import React from 'react'
import {useTranslation} from "react-i18next"

import {IonIcon, IonItemOption, useIonAlert} from "@ionic/react"
import {arrowUndoOutline, arrowUndoSharp} from "ionicons/icons"

import {useKeys as useItems} from "../../../contexts/KeyContext"
import BlocklistKey          from "../../../types/BlocklistKey"

interface props { item: BlocklistKey }

const ListItemOptionUnblock: React.FC<props> = ({item}) => {
    const { removeFromBlocklist } = useItems()
    const [presentAlert] = useIonAlert()
    const { t }                   = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertUnblock = async () => {
        return presentAlert({
            header:  t('keys.alerts.unblock.header'),
            message: t('keys.alerts.unblock.message'),
            buttons: [{
                text: t('common.cancel'), role: 'cancel',
                handler: () => onCancel(),
            }, {
                text: t('common.unblock'), role: 'confirm',
                handler: () => onUnblock(),
            },],
        })
    }

    const onCancel = () => { closeItemSliding() }

    const onUnblock = () => {
        closeItemSliding()
        removeFromBlocklist(item)
    }

    return (
        <IonItemOption color="medium" onClick={() => alertUnblock()}>
            <IonIcon slot="top" ios={arrowUndoOutline} md={arrowUndoSharp} />
            {t('common.unblock')}
        </IonItemOption>
    )
}

export default ListItemOptionUnblock