import ApiClient from "./ApiClient"

import LockType                         from "../types/Lock"
import {LockLockingSystemPermissionAdd} from "../types/LockLockingSystemPermission"
import SyncResult                       from "../types/SyncResult"


export const LockApi = {

    apiPath: 'locks',

    getAll: async (search?: string): Promise<LockType[]> => {
        const items: LockType[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get(LockApi.apiPath, { params });

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    add: async (newItem: LockType, handleAddItem: (id: number) => void): Promise<void> => {

        const result = await ApiClient.post(LockApi.apiPath, newItem);

        const itemRef = result.data.data;

        handleAddItem(itemRef.id);

        return
    },

    edit: async (item: LockType): Promise<any> => {

        const result = await ApiClient.put(LockApi.apiPath + '/' + item.id, item);

        return result.data?.data;
    },

    delete: async(id: number): Promise<void> => {

        await ApiClient.delete(LockApi.apiPath + '/' + id);

        return
    },

    deleteLockingSystemPermission: async(
        lockId: number, lockingSystemPermissionId: number
    ): Promise<void> => {

        await ApiClient.delete(LockApi.apiPath + '/' + lockId + '/lockingSystemPermissions/' + lockingSystemPermissionId)

        return

    },

    addLockingSystemPermission: async(lockPermissions: LockLockingSystemPermissionAdd): Promise<any> => {

        const result = await ApiClient.post(
            LockApi.apiPath + '/' + lockPermissions.lockId + '/lockingSystemPermissions',
            lockPermissions
        )

        return result.data?.data
    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(LockApi.apiPath + '/sync/all', { params })

        return result?.data
    },

    syncItemById: async (item: LockType, softSync: boolean): Promise<LockType | void> => {

        if (item.lockingSystemId === undefined) return

        const params = new URLSearchParams([['lockingSystemId', item.lockingSystemId.toString()]])

        if (softSync) params.append('soft', softSync.toString())

        const result = await ApiClient.get(LockApi.apiPath + '/sync/' + item.id, { params })

        return result?.data?.data
    }
}

export default LockApi