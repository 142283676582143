import React        from 'react'
import {useHistory} from "react-router"
import {IonButton, IonButtons, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {
    expandOutline, expandSharp, keyOutline, keySharp,
    lockClosedOutline, lockClosedSharp, manOutline, manSharp,
    shieldCheckmarkOutline, shieldCheckmarkSharp
} from "ionicons/icons";

import {default as ItemType} from "../../types/LockingSystem"
import {useLockingSystems}   from "../../contexts/LockingSystemContext"
import IconLockingSystem     from "../common/Icons/LockingSystem"

interface itemProps {
    item: ItemType;
    setDefault: any;
}

interface itemButton {
    countKey:  string;
    url:       string;
    iosIcon:   string;
    mdIcon:    string;
    iconColor: string;
}

const itemButtons: itemButton[] = [
    {
        countKey: 'keys',
        url: 'keys-in-use',
        iosIcon: keyOutline,
        mdIcon: keySharp,
        iconColor: 'green'
    },
    {
        countKey: 'locks',
        url: 'locks',
        iosIcon: lockClosedOutline,
        mdIcon: lockClosedSharp,
        iconColor: 'blue'
    },
    {
        countKey: 'people',
        url: 'people',
        iosIcon: manOutline,
        mdIcon: manSharp,
        iconColor: 'purple'
    },
    {
        countKey: 'lockingSystemPermissions',
        url: 'locking-system-permissions',
        iosIcon: shieldCheckmarkOutline,
        mdIcon: shieldCheckmarkSharp,
        iconColor: 'orange'
    },
    {
        countKey: 'lockingSystemZones',
        url: 'locking-system-zones',
        iosIcon: expandOutline,
        mdIcon: expandSharp,
        iconColor: 'DarkTurquoise'
    },
]

const ListItem: React.FC<itemProps> = ({ item, setDefault }) => {
    const history = useHistory()
    const { changeSelectedItemId } = useLockingSystems()

    const goTo = (ev: any, item: ItemType, url: string) => {
        ev.preventDefault()
        ev.stopPropagation()

        changeSelectedItemId(item.id)

        history.push('/' + url)
    }

    return (
        <>
            <IonItem button detail routerLink={"/locking-systems/" + item.id + '/view'} slot="start">
                <IconLockingSystem slot="start" />
                <IonLabel>
                    {item.name}
                    <p>{item.lockingSystemType?.brand.name} - {item.lockingSystemType?.name}</p>
                </IonLabel>
                <IonButtons className="ion-hide-md-down" slot="end">
                    {itemButtons.map((button, buttonIndex) => {
                        const count = item.counts[button.countKey] || 0

                        return (
                            <IonButton color="medium" key={buttonIndex}
                                       onClick={ (ev) => goTo(ev, item, button.url) }
                                       size="large" style={{ 'minWidth': '55px', 'fontSize': '17px' }}
                                       >
                                {count ? count : '-'}
                                <IonIcon slot="end" color="bc-blue" ios={button.iosIcon} md={button.mdIcon} />
                            </IonButton>
                        )
                    })}
                </IonButtons>
            </IonItem>
        </>
    )
}

export default ListItem