import React from "react"

import {IonCol, IonGrid, IonRow} from "@ionic/react"

import {Person as ItemType} from "../../types/Person"

import FormButtonDelete     from "../People/Form/ButtonDelete"
import ViewHeader           from "./View/Header"
import ViewItemGroupInfo    from "./View/ItemGroupInfo"
import ViewItemGroupAddress from "./View/ItemGroupAddress"
import ViewItemGroupLinked  from "./View/ItemGroupLinked"
import ViewItemDataSource   from "./View/ItemDataSource"

export interface formProps {
    item: ItemType
}

const View: React.FC<formProps> = ({ item }) => {

    const formButtons = () => {
        if (item !== undefined) {
            return (<>
                {item.options?.isDeletable && <FormButtonDelete item={item} />}
            </>)
        }
    }

    return (
        <div className="ion-text-center">
            <ViewHeader        item={item} />
            <ViewItemGroupInfo item={item} />
        {(item.address || item.zipcode || item.country) &&
            <ViewItemGroupAddress item={item} />
        }
        {(item.dataSourceId && item.sourceItemId) &&
            <ViewItemDataSource item={item} />
        }
            <ViewItemGroupLinked item={item} />

            <IonGrid className="ion-margin-top">
                <IonRow>
                    <IonCol className="ion-text-right">
                        {formButtons()}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default View