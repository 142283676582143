import React from "react"

import {default as ItemType}             from "../types/LockingSystemZone"
import {LockingSystemZoneApi, LockingSystemZoneApi as ItemApi} from "../services/LockingSystemZoneApi"

export type LockingSystemZoneContextType = {
    addItem:    (item: ItemType) => void;
    deleteItem: (id: number) => void;
    editItem:   (item: ItemType) => void;
    fetchItems: (search?: string, force?: boolean) => void;

    items:      ItemType[] | undefined;

    loadingItems: boolean;
}

const initialState = {
    addItem:    () => {},
    deleteItem: () => {},
    editItem:   () => {},
    fetchItems: () => {},
    items:      undefined,
    loadingItems: true,
}

export const LockingSystemZoneContext = React.createContext<LockingSystemZoneContextType>(initialState)

interface providerProps {
    children: React.ReactNode
}

const LockingSystemZoneProvider: React.FC<providerProps> = ({ children }) => {
    const [
        items, setItems
    ] = React.useState<ItemType[] | undefined>(initialState.items)
    const [
        loadingItems, setLoadingItems
    ] = React.useState<boolean>(initialState.loadingItems)

    const addItem = async (newItem: ItemType): Promise<ItemType> => {
        const newZone = await LockingSystemZoneApi.add(newItem, () => {})

        if (items && newZone) {
            setItems([newItem, ...items]);
        }

        return newZone
    }

    const deleteItem = (id: number) => {
        if (items === undefined) return

        ItemApi.delete(id);

        const newItems = [...items];
        newItems.splice(newItems.findIndex(i => i.id === id), 1);

        setItems(newItems);
    }

    const editItem = async (editedItem: ItemType): Promise<void> => {
        if (items === undefined) return;

        const result = await ItemApi.edit(editedItem);

        const newItems: ItemType[] = items.map(oldItem => {
            if (oldItem.id === editedItem.id && result.id) {
                return {
                    ...oldItem,
                    name: result.name,
                    description: result.description,
                }
            } else {
                return oldItem
            }
        })

        setItems(newItems)
    }

    const fetchItems = async (search?: string, force: boolean = false) => {
        if (items === undefined || force) {
            setLoadingItems(true)

            setItems( await ItemApi.getAll(search) )

            setLoadingItems(false)
        }
    }

    return (
        <LockingSystemZoneContext.Provider
            value={{
                addItem,
                deleteItem,
                editItem,
                fetchItems,
                items,
                loadingItems,
                // syncItems,
            }}
        >
            {children}
        </LockingSystemZoneContext.Provider>
    );
}

export const useLockingSystemZones = () => {
    return React.useContext(LockingSystemZoneContext) as LockingSystemZoneContextType
}

export default LockingSystemZoneProvider