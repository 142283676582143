import React      from "react"
import {IonBadge} from "@ionic/react"

import LockingSystemPermissionType from "../../../types/LockingSystemPermissionType"

interface props {
    backgroundColor?: string
    item?: LockingSystemPermissionType;
    name?: string
}

const BadgeLockingSystemPermissionType: React.FC<props> = ({ item, backgroundColor, name }: props) => {

    const
        color: string     = item?.backgroundColor         || backgroundColor     || 'transparent',
        nameShort: string = item?.nameShort.toLowerCase() || name?.toLowerCase() || '?'

    return (
        <IonBadge className="ion-hide-md-down" slot="end" style={{
            '--background': color,
            'fontWeight': 500,
            'minWidth': '100px',
            '--padding-top': '6px', '--padding-bottom': '6px',
            '--padding-start': '8px', '--padding-end': '8px',
            'marginRight': '6px' }}>
            {nameShort}
        </IonBadge>
    )

}

export default BadgeLockingSystemPermissionType