import React            from 'react'
import {useHistory}     from "react-router"
import {useTranslation} from "react-i18next"

import {IonIcon, IonItemOption, useIonAlert} from "@ionic/react"
import {trashOutline, trashSharp} from "ionicons/icons"

import {Person as ItemType}    from "../../types/Person"
import {usePeople as useItems} from "../../contexts/PersonContext"


interface componentProps {
    item: ItemType
}

const ListItemOptionDelete: React.FC<componentProps> = ({item}) => {
    const history = useHistory()
    const { deleteItem } = useItems()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertDelete = async () => {
        if (item.keys && item.keys?.length > 0) {
            return presentAlert({
                header:  t('people.alerts.delete.header_not_possible'),
                message: t('people.alerts.delete.message_not_possible'),
                buttons: [
                    {
                        text:    t('common.cancel'),
                        role:    'cancel'
                    },
                ],
            })
        }

        return presentAlert({
            header:  t('people.alerts.delete.header'),
            message: t('people.alerts.delete.message'),
            buttons: [{
                text:    t('common.cancel'),
                role:    'cancel'
            }, {
                text:    t('common.delete'),
                role:    'confirm',
                handler: () => onDelete(),
            },],
        })

    }

    const onDelete = async () => {
        closeItemSliding()

        await deleteItem(item)

        history.push('/people')
    }

    return (
        <IonItemOption color="danger" onClick={() => alertDelete()}>
            <IonIcon slot="top" ios={trashOutline} md={trashSharp} />
            {t('common.delete')}
        </IonItemOption>
    )
}

export default ListItemOptionDelete