import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next"

import {IonCol, IonGrid, IonItem, IonItemSliding, IonLabel, IonList, IonModal, IonRow} from "@ionic/react"

import {LockType as ItemType}    from "../../../types/Lock"

import {useLocks as useItems} from "../../../contexts/LockContext";

import LockingSystemPermission from "../../../types/LockingSystemPermission";

import ListItem from "./ListItem";
import ButtonDeleteAll from "./ButtonDeleteAll";
import ItemSegmentListHeader from "../../common/Headers/ItemSegmentListHeader";
import {useLockingSystemPermissions} from "../../../contexts/LockingSystemPermissionContext";
import SelectLockingSystemPermissions from "../../common/Modal/SelectLockingSystemPermissions";
import SelectOption from "../../../types/SelectOptions/LockingSystemPermissionSelectOption";

interface props {
    item: ItemType
}

const List: React.FC<props> = ({ item }) => {

    const { t } = useTranslation()

    const {addLockingSystemPermissions} = useItems()
    const {selectOptions, fetchSelectOptions} = useLockingSystemPermissions()

    const [modalReady, setModalReady] = useState<boolean>(false)
    const [selectedLockingSystemPermissions, setSelectedLockingSystemPermissions] = useState<number[]>([])

    const selectModal = useRef<HTMLIonModalElement>(null)

    const hasRemoveAll = (): boolean => {

        if (item.lockingSystemPermissions?.length) {
            const notRemovableFromLock: LockingSystemPermission | undefined
                = item.lockingSystemPermissions.find(p => ! p.options?.isRemovableFromLock)

            return ! notRemovableFromLock
        }

        return false
    }

    useEffect(() => {
        setModalReady(false)
        setTimeout((): void => setModalReady(true), 750)
    }, [item.lockingSystemPermissions])

    useEffect((): void => {
        const fetch = async (): Promise<void> => fetchSelectOptions()

        fetch()
    }, [selectOptions])

    const filterAvailableLockingSystemPermissions = (): SelectOption[] | [] => {
        const availableLockingSystemPermissions: SelectOption[] = []

        const existingPermissions: LockingSystemPermission[] = item?.lockingSystemPermissions || []

        if (existingPermissions && selectOptions) {
            selectOptions.map(selectOption => {
                const found = existingPermissions
                    .find(eLSP => selectOption.id === eLSP.id)

                if (undefined === found && selectOption.options.isAddableToKey) {
                    availableLockingSystemPermissions.push(selectOption)
                }

            })
        }

        return availableLockingSystemPermissions
    }

    const lockingSystemPermissionSelectionChanged = async (lockingSystemPermissions: number[]) => {
        setSelectedLockingSystemPermissions(lockingSystemPermissions);
        selectModal.current?.dismiss();

        if (lockingSystemPermissions.length > 0) {
            setModalReady(false)
            await addSelectedPermissions(lockingSystemPermissions)
        }
    }

    const addSelectedPermissions = async (lockingSystemPermissions: number[]) => {
        if (undefined === item || lockingSystemPermissions.length < 1) return

        await addLockingSystemPermissions(item, lockingSystemPermissions)

        setSelectedLockingSystemPermissions([])
    }

    const listButtons = () => {
        if (hasRemoveAll()) {
            return (
                <IonCol className="ion-text-center">
                    {item?.lockingSystemPermissions && item.lockingSystemPermissions?.length > 0
                        && <ButtonDeleteAll lock={item} />}
                    {/*setSelectedLockingSystemPermissions={setSelectedLockingSystemPermissions}*/}
                </IonCol>
            )
        }
    }

    const listLockingSystemPermissions = (lockingSystemPermissions: LockingSystemPermission[]) => {
        if (lockingSystemPermissions.length === 0) {
            return (
                <IonItem className="ion-text-center">
                    <IonLabel className="ion-text-wrap">{t('locks.no_permissions_for_lock')}</IonLabel>
                </IonItem>
            )
        }

        return lockingSystemPermissions.map((lockingSystemPermission: LockingSystemPermission) => {
            return (
                <IonItemSliding className={lockingSystemPermission.isHiddenByDefault ? 'hidden-by-default' : ''}
                                key={"item-sliding-key-locking-system-permission-" + lockingSystemPermission.id}>
                    <ListItem lock={item} lockingSystemPermission={lockingSystemPermission} />
                </IonItemSliding>
            )
        })
    }

    const title: string    = item?.lockingObject || item?.description || '-'
    const subTitle: string = item?.lockType?.lockingSystemType?.brand.name + ' - ' + item?.lockType?.name

    return (
        <>
            <IonList lines="full" className="maxWidth">
                <ItemSegmentListHeader title={title} subTitle={subTitle} />
                {listLockingSystemPermissions(item.lockingSystemPermissions || [])}
            </IonList>

            <IonGrid className="ion-margin-top">
                <IonRow>{listButtons()}</IonRow>
            </IonGrid>

            <IonModal trigger={modalReady ? "select-lock-locking-system-permissions" : undefined} ref={selectModal}>
                <SelectLockingSystemPermissions
                    hasShowHidden={true}
                    items={filterAvailableLockingSystemPermissions()}
                    selectedItems={selectedLockingSystemPermissions}
                    selectTypeTitle={t('locking-system-permissions.title_list')}
                    title={t('locking-system-permissions.add_permissions').toString()}
                    onSelectionCancel={() => selectModal.current?.dismiss()}
                    onSelectionChange={lockingSystemPermissionSelectionChanged}
                />
            </IonModal>
        </>
    )

}

export default List