import React from "react"

import {IonItem, IonLabel} from "@ionic/react"

import LockingSystemPermission from "../../../types/LockingSystemPermission"

import IconLockingSystemPermission from "../../common/Icons/LockingSystemPermission";
import BadgeLockingSystemPermissionType from "../../common/Badges/BadgeLockingSystemPermissionType";
import ButtonDelete from "./ButtonDelete";
import Key from "../../../types/Key";
import {useTranslation} from "react-i18next";

interface props {
    item:    LockingSystemPermission
    keyItem: Key
}

const ListItem: React.FC<props> = ( {item, keyItem } ) => {

    const { t } = useTranslation()

    const itemBadge = () => {
        if (item.lockingSystemPermissionType) {
            return (<BadgeLockingSystemPermissionType item={item.lockingSystemPermissionType} />)
        }
    }

    return (
        <IonItem button className={item.isHiddenByDefault ? 'hidden-by-default' : ''}
                 detail key={"item-key-locking-system-permission-" + item.id} lines="inset"
                 routerLink={"/locking-system-permissions/" + item.id + '/view'}>
            <IconLockingSystemPermission slot="start"/>
            <IonLabel>
                {item.name}
                {item.lockingSystemZone &&
                    <span style={{ 'fontWeight': 200 }}>{/* , 'fontSize': '14px' */}
                        &nbsp;- {item.lockingSystemZone.name}
                    </span>
                }
                <p>{item.description || <i>{'(' + t('common.no_description') + ')'}</i>}</p>
            </IonLabel>
            {itemBadge()}
            <ButtonDelete item={item} keyItem={keyItem} />
        </IonItem>
    )
}

export default ListItem