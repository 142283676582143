import ApiClient from "./ApiClient";
import {LockingSystemPermission as ItemType} from "../types/LockingSystemPermission";
import SyncResult from "../types/SyncResult";
import KeyType from "../types/Key";

import SelectOption from "../types/SelectOptions/LockingSystemPermissionSelectOption";

export const LockingSystemPermissionApi = {

    apiPath: 'locking-system-permissions',

    getAll: async (search?: string): Promise<ItemType[]> => {
        const items: ItemType[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get(LockingSystemPermissionApi.apiPath, { params });

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    getSelectOptions: async(): Promise<SelectOption[]> => {
        const selectOptions: SelectOption[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        const response = await ApiClient.get(
            'select-options/' + LockingSystemPermissionApi.apiPath, { params }
        );


        for (let selectOption of response.data.data) {
            selectOptions.push(selectOption)
        }

        return selectOptions
    },

    add: async (newItem: ItemType, handleAddItem: (id: number) => void): Promise<ItemType | false> => {

        const result = await ApiClient.post(LockingSystemPermissionApi.apiPath, newItem);

        if (result?.data) {
            const itemRef = result.data.data;

            handleAddItem(itemRef.id);

            return result?.data.data
        }

        return false
    },

    edit: async (item: ItemType): Promise<ItemType> => {

        const result = await ApiClient.put(LockingSystemPermissionApi.apiPath + '/' + item.id, item);

        return result.data?.data;
    },

    delete: async (id: number): Promise<any> => {

        return await ApiClient.delete(LockingSystemPermissionApi.apiPath + '/' + id)

    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(LockingSystemPermissionApi.apiPath + '/sync/all', { params })

        return result?.data
    },

    syncItemById: async (item: ItemType): Promise<KeyType | void> => {
        if (item.lockingSystemId === undefined) return

        const params = new URLSearchParams([['lockingSystemId', item.lockingSystemId.toString()]])

        const result = await ApiClient.get('keys/sync/' + item.id, { params })

        return result?.data
    }

}