import React from "react"

import { PersonApi } from "../services/PersonApi"

import Person from "../types/Person"
import Key    from "../types/Key"

import {MessageInterface} from "../components/common/Message";
import SelectOption from "../types/SelectOptions/PersonSelectOption";


export type PersonContextType = {
    addItem:      (person: Person) => Promise<Person | void> | void
    deleteItem:   (person: Person) => Promise<any> | void
    editItem:     (person: Person) => Promise<Person | void> | void
    fetchItems:   (search?: string, force?: boolean) => void
    items:        Person[] | undefined;
    loadingItems: boolean;

    inProgress:   boolean;

    message: MessageInterface | undefined
    closeMessage: () => void

    fetchSelectOptions: (force?: boolean) => void
    selectOptions: SelectOption[] | undefined

    linkKey:      (item: Key) => void;
    unlinkKey:    (item: Key) => void;
}

const initialState = {
    addItem:      () => {},
    deleteItem:   () => {},
    editItem:     () => {},
    fetchItems:   () => {},
    items:        undefined,
    loadingItems: true,

    inProgress:   false,

    message:      undefined,
    closeMessage: () => {},

    fetchSelectOptions: () => {},
    selectOptions: undefined,

    linkKey:    () => {},
    unlinkKey:  () => {},

    // addPersonWithNewKey: () => {},
}

export const PersonContext = React.createContext<PersonContextType>(initialState);

interface PersonProviderProps {
    children: React.ReactNode
}

const PersonProvider: React.FC<PersonProviderProps> = ( { children }) => {

    const [
        items, setItems
    ] = React.useState<Person[] | undefined>(initialState.items)
    const [
        loadingItems, setLoadingItems
    ] = React.useState<boolean>(initialState.loadingItems)
    const [
        inProgress, setInProgress
    ] = React.useState<boolean>(initialState.inProgress)
    const [
        message, setMessage
    ] = React.useState<MessageInterface | undefined>(initialState.message)
    const [
        selectOptions, setSelectOptions
    ] = React.useState<SelectOption[] | undefined>(initialState.selectOptions)


    const fetchItems = async (search?: string, force: boolean = false) => {
        if (items === undefined || force) {
            setLoadingItems(true)

            const result = await PersonApi.getAll(search)
                .catch((e) => setErrorMessage(e))

            if (result) {
                setItems(result)
                setLoadingItems(false)
            }
        }
    }

    const fetchSelectOptions = async (force: boolean = false): Promise<SelectOption[] | void> => {

        if (undefined === selectOptions || force) {
            setLoadingItems(true)

            const result = await PersonApi.getSelectOptions()
                .catch((e) => setErrorMessage(e))

            if (result) {
                setSelectOptions(result)

                setLoadingItems(false)
            }
        }
    }

    const addItem = async (newPerson: Person): Promise<Person | void> => {
        closeMessage()
        setInProgress(true)

        const result = await PersonApi.add(newPerson, () => {})
            .catch(e => setErrorMessage(e))

        if (items && result) {
            setInProgress(false)
            setItems([result, ...items])
        }

        return result
    }

    const editItem = async (item: Person): Promise<Person | void> => {
        closeMessage()
        setInProgress(true)

        const result = await PersonApi.edit(item)
            .catch((e) => setErrorMessage(e))

        if (items && result) {
            const newItems: Person[] = items.map(oldItem => {
                return (oldItem.id === item.id && result.id) ? {...result} : oldItem
            })

            setInProgress(false)
            setItems(newItems)
        }

        return result
    }

    const deleteItem = async (item: Person): Promise<any> => {
        closeMessage()
        setInProgress(true)

        const result = await PersonApi.delete(item.id)
            .catch((e) => setErrorMessage(e))

        if (items && result !== undefined) {
            const newItems = [...items]
            newItems.splice(newItems.findIndex(i => i.id === item.id), 1)

            setInProgress(false)
            setItems(newItems)
        }
    }

    const linkKey = async (keyItem: Key) => {
        setInProgress(true)

        if (items !== undefined) {
            const newItems: Person[] = items?.map(item => {
                if (item.id === keyItem.personId) item.keys?.push(keyItem)

                return item
            })

            setItems(newItems)
        }

        setInProgress(false)
    }

    const unlinkKey = (key: Key) => {
        setInProgress(true)

        // Remove key from person keys array
        const newItems: Person[] | undefined = items?.map(item => {
            if (item.id !== key.personId) return item

            const newKeys: Key[] = [...item.keys || []]
            newKeys.splice(newKeys.findIndex(i => i.id === key.id), 1)

            return {...item, keys: newKeys}
        })

        setItems(newItems)
        setInProgress(false)
    }

    const closeMessage = () => setMessage(undefined)

    const setErrorMessage = (e: any) => {
        setMessage({
            text: e.response.data.message || '?',
            type: 'error'
        })

        setInProgress(false)
        setLoadingItems(false)
    }

    return (
        <PersonContext.Provider
            value={{

                addItem,
                deleteItem,
                editItem,
                fetchItems,
                fetchSelectOptions,
                items,
                loadingItems,
                inProgress,

                message,
                closeMessage,

                selectOptions,

                linkKey,
                unlinkKey,

            }}
        >
            {children}
        </PersonContext.Provider>
    );
}

export const usePeople = () => {
    return React.useContext(PersonContext) as PersonContextType
}

export default PersonProvider