import React, {useRef, useState} from "react"
import {useTranslation} from "react-i18next"

import { CheckboxCustomEvent,
    IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonItem, IonList, IonSearchbar, IonTitle, IonToggle, IonToolbar
} from "@ionic/react"

import SelectOption from "../../../types/SelectOptions/PersonSelectOption"

import IconPerson from "../Icons/Person"

interface props {
    hasShowHidden?:     boolean
    items:              SelectOption[]
    selectedItems:      number[]
    selectTypeTitle:    string
    title?:             string
    onSelectionCancel?: () => void
    onSelectionChange?: (items: number[]) => void
}

const ModalSelectOptions: React.FC<props> = (props) => {

    const { t } = useTranslation()

    const [
        filteredItems, setFilteredItems
    ] = useState<SelectOption[] | undefined>(undefined); // [...props.items]
    const [
        searchQuery, setSearchQuery
    ] = useState<null | string | undefined>(undefined)
    const [
        workingSelectedValues, setWorkingSelectedValues
    ] = useState<number[]>([...props.selectedItems]);
    const [
        countHiddenItems, setCountHiddenItems
    ] = useState<number | undefined>(undefined)


    const showHiddenByDefaultRef = useRef<any>(undefined)


    const isItemChecked = (id: number) => {
        return workingSelectedValues.find((item) => item === id) !== undefined;
    };

    const cancelChanges = () => {
        const { onSelectionCancel } = props
        if (onSelectionCancel !== undefined) onSelectionCancel()
    }

    const confirmChanges = () => {
        const { onSelectionChange } = props

        if (onSelectionChange !== undefined) {
            onSelectionChange(workingSelectedValues)
        }
    }

    const searchbarInput = (ev: any) => {
        setSearchQuery(ev.target.value)
        filterList(ev.target.value)
    }


    const getFilteredItems = (searchQuery: string | null | undefined): SelectOption[] => {

        const items: SelectOption[] =
            props.hasShowHidden === true && countHiddenItems && showHiddenByDefaultRef?.current.checked
                ? [...props.items]
                : props.items.filter((item: SelectOption) => { return ! item.isHiddenByDefault})

        if (searchQuery === undefined || searchQuery === null) return items

        const normalizedQuery = searchQuery.toLowerCase()

        return items.filter((item) => {
            return item.title.toLowerCase().includes(normalizedQuery)
                || item.subTitle?.toLowerCase().includes(normalizedQuery)
        })
    }

    const filterList = (searchQuery: string | null | undefined) => {
        setFilteredItems( getFilteredItems(searchQuery) )
    }

    const checkboxChange = (ev: CheckboxCustomEvent) => {
        const { checked, value } = ev.detail

        setWorkingSelectedValues(checked
             ? [value]
             : workingSelectedValues.filter((item) => item !== value)
        )

        if (props.onSelectionChange !== undefined && checked) {
            props.onSelectionChange([value])
        }
    }

    React.useEffect(() => {
        if (undefined === filteredItems) {
            filterList(null)

            let count = 0

            props.items.forEach((item) => {
                if (item.isHiddenByDefault) count++
            })

            setCountHiddenItems(count)
        }
    }, [filteredItems])

    const toggleHidden = () => { filterList(searchQuery) }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={cancelChanges}>{t('common.cancel')}</IonButton>
                    </IonButtons>
                    <IonTitle>{props.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={confirmChanges}>{t('common.continue')}</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar autoFocus={true}
                        debounce={parseInt(process.env.REACT_APP_SEARCH_DEBOUNCE_MS || '') || 0}
                        onIonInput={searchbarInput}
                        placeholder={t('common.search_in') + ' "' + props.selectTypeTitle + '"'}
                    />
                </IonToolbar>
                {(props.hasShowHidden === true && countHiddenItems !== undefined && countHiddenItems > 0) &&
                    <IonToolbar>
                        <IonToggle
                            className="ion-padding-start" style={{ '--track-background-checked': 'purple'}}
                            labelPlacement="end" onIonChange={toggleHidden} ref={showHiddenByDefaultRef}>
                            {t('common.show_hidden') + ' (' + countHiddenItems + ')'}
                        </IonToggle>
                    </IonToolbar>
                }
            </IonHeader>

            <IonContent color="light">{ /* class="ion-padding" */ }
                <IonList id="select-modal-list">
                    {filteredItems && filteredItems?.length < 1 &&
                        <IonItem key="model-select-options-no-result" lines="none">
                            {t('common.no_results_found')}
                        </IonItem>
                    }
                    {filteredItems?.map((item) => (
                        <IonItem className={item.isHiddenByDefault ? 'hidden-by-default' : ''} detail={false} key={item.id} lines="inset">
                            <IconPerson slot="start" />
                            <IonCheckbox checked={isItemChecked(item.id)} justify="start"
                                         onIonChange={checkboxChange} labelPlacement="end" value={item.id}>
                                {item.title}<br />
                                <span style={{ 'fontSize': 'smaller' }}>{item.subTitle}</span>
                            </IonCheckbox>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </>
    )

}

export default ModalSelectOptions