import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound from "../common/Items/ItemNotFound"
import IconUser     from "../common/Icons/User"

const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/users'

    return (
        <ItemNotFound icon={<IconUser link={link} size="large" />}
                      link={link} subTitle={t('common.item_not_found', { itemType: t('users.title_item').toLowerCase() })} />
    )
}

export default NotFound