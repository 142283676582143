import React from "react"
import {useTranslation} from "react-i18next"

import Lock from "../../types/Lock"
import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react";

export interface componentProps {
    item: Lock
}

const ItemGroupLinked: React.FC<componentProps> = ( {item} ) => {

    const {t} = useTranslation()

    const lockingSystemPermissionsCount = item !== undefined ? item.lockingSystemPermissions?.length || 0 : 0

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            <IonItem button={lockingSystemPermissionsCount > 0} detail={lockingSystemPermissionsCount > 0} lines="full"
                     routerLink={'/locks/' + item.id + '/lockingSystemPermissions'}>
                <IonLabel>{t('locking-system-permissions.title_list')}</IonLabel>
                <IonNote slot="end">{lockingSystemPermissionsCount || '-'}</IonNote>
            </IonItem>
            {item.lockingSystemZone &&
                <IonItem button detail={true} lines="full"
                         routerLink={'/locking-system-zones/' + item.lockingSystemZone.id + '/view'}>
                    <IonLabel>Zone</IonLabel>
                    <IonNote slot="end">{item.lockingSystemZone.name || '-'}</IonNote>
                </IonItem>
            }
        </IonItemGroup>
    )
}

export default ItemGroupLinked