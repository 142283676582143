import React from "react"
import {IonLabel, IonListHeader} from "@ionic/react";

type props = {
    title:    string
    subTitle: string
}

const ItemSegmentListHeader: React.FC<props> = ({title, subTitle}) => {

    return (
        <IonListHeader lines="inset">
            <IonLabel>
                {title}
                <p style={{'lineHeight': 1.4}}>
                    {subTitle}
                </p>
            </IonLabel>
        </IonListHeader>
    )
}

export default ItemSegmentListHeader