import React from "react"
import {
    AlertInput,
    IonAlert,
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonSelect,
    IonSelectOption
} from "@ionic/react"
import {useTranslation} from "react-i18next"

import {useLockingSystems} from '../../../contexts/LockingSystemContext'
import SearchBar           from "./SearchBar"
import {bookmarkSharp} from "ionicons/icons";

import './ListBar.css'

interface componentProps {
    handleSearch: (ev: any) => void;
    itemType: string;
    pageName: string;
}

const ListBar: React.FC<componentProps> = ({ handleSearch, itemType, pageName }: componentProps) => {
    const { t } = useTranslation()

    const {
        changeSelectedItemId: changeSelectedLockingSystemId,
        fetchItems: fetchLockingSystems,
        items: lockingSystems,
        selectedLockingSystemId,
        selectedItem: selectedLockingSystem
    } = useLockingSystems()

    React.useEffect(() => {
        fetchLockingSystems();
    }, [selectedLockingSystem])

    const changeLockingSystem = (lockingSystemId: number) => {
        changeSelectedLockingSystemId( lockingSystemId )
    }

    const lockingSystemOptions = () => {

        if (lockingSystems === undefined) return

        if (lockingSystems.length === 0 ) {
            return (
                <IonSelectOption key="0" value="0">-</IonSelectOption>
            )
        }

        return lockingSystems.map((item, keyId) => {
            return (
                <IonSelectOption key={item.id} value={item.id}>{item.name}</IonSelectOption>
            );
        })
    }

    const alertInputs = (): AlertInput[] => {

        //if (lockingSystems === undefined) return
        let options: AlertInput[] = []

        if (lockingSystems === undefined || lockingSystems.length === 0) {
            const option: AlertInput = {
                label: 'Nog geen sluitsysteem',
                type:  'radio',
                value: 0,
            }

            options.push(option)
        } else {
            options = lockingSystems.map((item) => {
                const option: AlertInput = {
                    checked: item.id === selectedLockingSystemId,
                    label: item.name,
                    type: 'radio',
                    value: item.id,
                }

                return option
            })
        }

        return options
    }

    const onSelect = (lockingSystemId: number) => {
        changeLockingSystem(lockingSystemId)
        // console.log('lockingSystemId: ' + JSON.stringify(lockingSystemId))
    }

    const onCancel = (event: any) => {
        // console.dir(event)
    }

    const hasMultipleLockingSystems = (): boolean => {
        return lockingSystems !== undefined && lockingSystems.length > 1
    }

    return (
        <IonGrid className="ion-no-padding">
            {(lockingSystems && lockingSystems.length > 0) &&
            <IonRow>
                <IonCol size={hasMultipleLockingSystems() ? '10' : '12'}
                        size-sm={hasMultipleLockingSystems() ? '6' : '12'}>
                    <SearchBar handleSearch={handleSearch} pageName={pageName} />
                </IonCol>
                {hasMultipleLockingSystems() &&
                <IonCol size="2" size-sm="6" className="selectLockingSystem">
                    <IonSelect className="ion-hide-sm-down selectLockingSystem"
                               okText={t('common.select') as string}
                               cancelText={t('common.cancel') as string}
                               label={t('common.locking_system') as string}
                               aria-label={t('common.locking_system') as string}
                               // interface="popover"
                               placeholder={selectedLockingSystem.name}
                               value={selectedLockingSystemId ? selectedLockingSystemId : '0'}
                               onIonChange={(ev) => changeLockingSystem(ev.detail.value) }
                    >
                        {lockingSystemOptions()}
                    </IonSelect>
                    <div className="ion-hide-sm-up">
                        <IonButton id={`select-locking-system-${itemType}`} color="medium" size="small" fill="outline">
                            <IonIcon ios={bookmarkSharp} md={bookmarkSharp} slot="icon-only" size="small" />
                        </IonButton>
                        <IonAlert
                            trigger={`select-locking-system-${itemType}`}
                            header={t('common.select_locking_system').toString() + ':'}
                            buttons={[
                                {
                                    text:    t('common.cancel'),
                                    role:    'cancel',
                                    handler: (event) => onCancel(event),
                                }, {
                                    text:    t('common.select'),
                                    role:    'confirm',
                                    handler: (lockingSystemId: number) => onSelect(lockingSystemId),
                                },
                            ]}
                            inputs={alertInputs()}
                        />

                    </div>
                </IonCol>
                }
            </IonRow>
            }
        </IonGrid>
    )
}

export default ListBar