import React, {useState} from "react"

import {LockingSystemApi} from "../services/LockingSystemApi";
import {default as ItemType} from "../types/LockingSystem";
import SyncResult from "../types/SyncResult";
import {MessageInterface, MessageType} from "../components/common/Message";
import {KeyApi} from "../services/KeyApi";
import KeyPhoneApi from "../services/KeyPhoneApi";
import LockApi from "../services/LockApi";
import {PersonApi} from "../services/PersonApi";
import BlocklistKeyApi from "../services/BlocklistKeyApi";
import {LockingSystemPermissionApi} from "../services/LockingSystemPermissionApi";
import {LockingSystemZoneApi} from "../services/LockingSystemZoneApi";
import KeyLockingSystemPermissionApi from "../services/KeyLockingSystemPermissionApi";
import LockLockingSystemPermissionApi from "../services/LockLockingSystemPermissionApi";
import KeyLockingSystemZoneApi from "../services/KeyLockingSystemZoneApi";

export type LockingSystemContextType = {
    changeSelectedItemId:    (id: number)     => void
    editItem:                (key: ItemType)  => void
    fetchItems:              (query?: string, force?: boolean) => void
    items:                   ItemType[] | undefined
    loadingItems:            boolean
    selectedItem:            () => ItemType | null | void
    selectedItemFeatures:    () => any
    hasFeature:              (name: string) => boolean | void
    selectedLockingSystemId: number | undefined
    setDefault:              (item: ItemType) => void
    unsetItems:              ()               => void

    inProgress:      boolean
    isSynchronizing: string | null
    message:         MessageInterface | undefined;
    closeMessage:    () => void;
    setCustomMessage: (messageText: any, messageType: MessageType) => void;
    setSuccessMessage: (messageText: string) => void;

    getCollectionApi: (collection: string) => any;
    syncCollection: (key: string, api: any, lockingSystemId: number) => Promise<SyncResult | void> | void
}

const initialState = {
    changeSelectedItemId:    () => {},
    editItem:                () => {},
    fetchItems:              () => {},
    items:                   undefined,
    loadingItems:            true,
    selectedItem:            () => {},
    selectedItemFeatures:    () => {},
    hasFeature:              () => {},
    selectedLockingSystemId: undefined,
    setDefault:              () => {},
    unsetItems:              () => {},

    inProgress:      false,
    isSynchronizing: null,

    message:      undefined,
    closeMessage: () => {},
    setCustomMessage: () => {},
    setSuccessMessage: () => {},

    getCollectionApi: () => {},
    syncCollection: () => {},
}

export const LockingSystemContext = React.createContext<LockingSystemContextType>(initialState);

interface providerProps {
    children: React.ReactNode
}

const LockingSystemProvider: React.FC<providerProps> = ( { children }) => {
    const [items, setItems] = React.useState<ItemType[] | undefined>(initialState.items)
    const [loadingItems, setLoadingItems] = React.useState<boolean>(initialState.loadingItems)
    const [selectedLockingSystemId, setSelectedLockingSystemId] = React.useState<number | undefined>(initialState.selectedLockingSystemId)

    const [
        inProgress, setInProgress
    ] = React.useState<boolean>(initialState.inProgress)
    const [
        message, setMessage
    ] = React.useState<MessageInterface | undefined>(initialState.message)
    const [
        isSynchronizing, setIsSynchronizing
    ] = useState<string | null>(initialState.isSynchronizing)


    const changeSelectedItemId = (itemId: number) => {
        if (items === undefined) return

        if ( items.find(item => item.id === itemId) !== undefined ) {
            setSelectedLockingSystemId(itemId)

            localStorage.setItem('selectedLockingSystemId', itemId.toString())
        }
    }

    const fetchItems = async (query: string = '', force: boolean = false) => {

        if (items === undefined || force) {
            setLoadingItems(true)

            const result = await LockingSystemApi.getAll(query)

            setItems(result)

            setLoadingItems(false)

            if (! result) return


            // Check if there already is a selectedLockingSystemId in localStorage
            const selectedLockingSystemId: number | null | undefined = parseInt(
                localStorage.getItem('selectedLockingSystemId') as string
            )

            if (selectedLockingSystemId) {
                const index = result.find(item => item.id === selectedLockingSystemId)

                if (index !== undefined) {
                    setSelectedLockingSystemId(selectedLockingSystemId)

                    return
                }
            }

            // If not in localstorage / or lockingSystemId invalid
            if ( result.length === 1 ) {
                setSelectedLockingSystemId(result[0].id)
            } else {
                const defaultItem = result.find(item => item.isDefault)

                if (defaultItem !== undefined) {
                    setSelectedLockingSystemId(defaultItem.id)
                }
            }
        }
    }

    const unsetItems = async () => {
        setItems(undefined)
    }

    const editItem = async (editedItem: ItemType): Promise<void> => {
        if (items === undefined) return

        const result = await LockingSystemApi.edit(editedItem);

        const newItems: ItemType[] = items.map(oldItem => {
            if (oldItem.id === editedItem.id && result.id) {
                return {...result}
            } else {
                return oldItem
            }
        })

        setItems(newItems)
    }

    const selectedItem = (): ItemType | null => {
        if (items === undefined || selectedLockingSystemId === undefined) return null

        const item = items.find(item => item.id === selectedLockingSystemId)

        return item || null
    }

    const selectedItemFeatures = () => {
        const item = selectedItem()

        if (item) {
            return item.features
        }
    }

    const hasFeature = (name: string): boolean | void  => {
        const features = selectedItemFeatures()

        if (features) {
            const result = features.find(f => f.name === name)

            return result !== undefined
        }
    }

    const setDefault = async (item: ItemType): Promise<void> => {
        if (items === undefined) return

        const newItems: ItemType[] = items.map(oldItem => {
            if (oldItem.id === item.id) {
                return {
                    ...oldItem, isDefault: true
                }
            }

            return {
                ...oldItem, isDefault: false
            }
        })

        setItems(newItems)
    }

    const setCustomMessage = (messageText: any, messageType: MessageType): void => {
        setMessage({
            text: messageText,
            type: messageType
        })
    }

    const setErrorMessage = (e: any) => {
        setMessage({
            text: e.response.data.message || '?',
            type: 'error'
        })
    }

    const setSuccessMessage = (messageText: string): void => {
        setMessage({text: messageText, type: 'success'})
    }

    const getCollectionApi = (collectionName: string) => {
        switch (collectionName) {
            case 'blocklistKeys':                return BlocklistKeyApi
            case 'keys':                         return KeyApi
            case 'keyPhones':                    return KeyPhoneApi
            case 'keyLockingSystemPermissions':  return KeyLockingSystemPermissionApi
            case 'keyLockingSystemZones':        return KeyLockingSystemZoneApi
            case 'lockingSystemPermissions':     return LockingSystemPermissionApi
            case 'lockingSystemZones':           return LockingSystemZoneApi
            case 'lockLockingSystemPermissions': return LockLockingSystemPermissionApi
            case 'locks':                        return LockApi
            case 'people':                       return PersonApi
        }
    }

    const syncCollection = async (
        collectionKey: string, api: typeof KeyApi, lockingSystemId: number
    ): Promise<SyncResult | void> => {
        syncStart(collectionKey)

        return await api.syncAllByLockingSystemId(lockingSystemId)
            .catch((e) => setErrorMessage(e))
            .finally(() => syncEnd())
    }

    const syncStart = (collectionKey: string) => {
        closeMessage()
        setInProgress(true)
        setIsSynchronizing(collectionKey)
    }

    const syncEnd = () => {
        setInProgress(false)
        setIsSynchronizing(null)
    }

    const closeMessage = () => {
        setMessage(undefined)
    }

    return (
        <LockingSystemContext.Provider
            value={{
                changeSelectedItemId,
                editItem,
                fetchItems,
                items,
                loadingItems,
                selectedLockingSystemId,
                selectedItem,
                selectedItemFeatures,
                hasFeature,
                setDefault,
                unsetItems,

                inProgress,
                isSynchronizing,

                message,
                closeMessage,
                setCustomMessage,
                setSuccessMessage,

                getCollectionApi,
                syncCollection,
            }}
        >
            {children}
        </LockingSystemContext.Provider>
    );
}

export const useLockingSystems = () => {
    return React.useContext(LockingSystemContext) as LockingSystemContextType
}

export default LockingSystemProvider