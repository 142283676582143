import React from "react"
import {useHistory} from "react-router"

import {IonCheckbox, IonInput, IonItem, IonLabel, IonList, IonNote} from "@ionic/react"

import {default as ItemType} from "../../types/LockingSystem";

import {useLockingSystems as useItems} from "../../contexts/LockingSystemContext"
import {useTranslation} from "react-i18next"

export interface formProps {
    item: ItemType | undefined;
}


const Form: React.FC<formProps> = ({ item}) => {
    const { editItem } = useItems()
    const history      = useHistory()
    const { t }        = useTranslation()

    const nameRef   = React.useRef<any>('');

    const handleSave = async (e: any) => {
        e.preventDefault();

        const itemChanged: ItemType = {
            id:            item?.id || 0,
            accountId:     0,
            isDefault:     false,
            lockingSystemTypeId: 1,
            name:          nameRef.current?.value,
        };

        if (itemChanged && itemChanged.id) {
            await editItem(itemChanged);
        }

        history.push('/locking-systems')
    }

    const FormKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            handleSave(e);
        }
    }

    return (
        <IonList className="maxWidth">
            <form method="POST" id="formLockingSystem" onSubmit={handleSave} onKeyUp={ e => FormKeyUp(e) }>
                {item &&
                <IonItem>
                    <IonLabel>{t('locking-system-types.title')}</IonLabel>
                    <IonNote slot="end">
                        {item?.lockingSystemType?.brand.name} - {item?.lockingSystemType?.name}
                    </IonNote>
                </IonItem>
                }
                <IonItem>
                    <IonInput ref={nameRef} name="name" type="text" value={item?.name}
                              label={t('locking-systems.form.name') as string}
                              labelPlacement="floating"
                              placeholder={t('locking-systems.form.name') as string}
                              clearInput={true}
                              autofocus={true} required
                    />
                </IonItem>
                <IonItem>
                    <IonCheckbox labelPlacement="end" justify="start" checked={item?.isDefault}>
                        {t('locking-systems.form.default_for_account')}
                    </IonCheckbox>
                </IonItem>

                {/*<p className="ion-text-center">*/}
                {/*    <IonButton type="submit" color="bc-yellow" fill="outline">*/}
                {/*        {t('common.save')}*/}
                {/*    </IonButton>*/}
                {/*</p>*/}

            </form>
        </IonList>
    );
}

export default Form