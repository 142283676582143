import React from "react"
import {RouteComponentProps} from "react-router"
import {useTranslation} from "react-i18next"

import {useUsers as useItems} from "../../contexts/UserContext"

// import ItemForm from "../../components/People/Form"
import ItemView from "../../components/Users/View"
// import KeysList from "../../components/People/KeysList"
import PageItem from "../common/Item"
import NotFound from "../../components/Users/NotFound"

interface pageProps extends RouteComponentProps<{
    id:      string;
    segment: string;
}> {}

const PageUsersItem: React.FC<pageProps> = ({ match }) => {
    const { t }        = useTranslation()
    const { fetchItems, items} = useItems()

    const
        itemType:     string = 'User',
        itemTypeName: string = t('users.title_item'),
        itemTypeUrl:  string = 'users'

    return (
        <PageItem
            useItems={useItems}
            fetchItems={fetchItems}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            // ItemForm={ItemForm}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            // KeysList={KeysList}
            segment={match.params.segment}
        />
    )
}

export default PageUsersItem