import React from "react";
import {IonSearchbar} from "@ionic/react";
import {useTranslation} from "react-i18next";

interface componentProps {
    handleSearch: (ev: any) => void;
    pageName: string;
}

const SearchBar: React.FC<componentProps> = ({ handleSearch, pageName }: componentProps) => {
    const { t } = useTranslation()

    return (
        <IonSearchbar debounce={parseInt(process.env.REACT_APP_SEARCH_DEBOUNCE_MS || '') || 0}
                      onIonInput={(ev) => handleSearch(ev)}
                      placeholder={t('common.search_in') + ' "' + pageName + '"'}
                      enterkeyhint="search"
        />
    )
}

export default SearchBar