import React from "react"
import {IonButton, IonIcon, useIonAlert} from "@ionic/react";
import {arrowUndoOutline, arrowUndoSharp} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

import {useKeys} from "../../contexts/KeyContext";
import BlocklistKey from "../../types/BlocklistKey";

interface props {
    item: BlocklistKey
}

const ButtonUnblock: React.FC<props> = ({item}) => {

    const { inProgress, removeFromBlocklist } = useKeys()
    const history                 = useHistory()
    const [ presentAlert ]        = useIonAlert()
    const { t }                   = useTranslation()

    const alertUnblock = async () => {
        if (item === undefined) return

        return presentAlert({
            header:  t('keys.alerts.unblock.header'),
            message: t('keys.alerts.unblock.message'),
            buttons: [{
                text: t('common.cancel'),
                role: 'cancel'
            }, {
                text: t('common.continue'),
                role: 'confirm',
                handler: () => { onUnblock() },
            },],
        })
    }

    const onUnblock = async () => {
        const result = await removeFromBlocklist(item)

        if (result) history.push('/blocklist-keys')
    }

    return (
        <IonButton color="medium" disabled={inProgress} fill="outline" type="button" onClick={() => alertUnblock()}>
            <IonIcon slot="start" ios={arrowUndoOutline} md={arrowUndoSharp} />
            {t('common.unblock')}
        </IonButton>
    )
}

export default ButtonUnblock