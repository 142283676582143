import React from "react"
import {RouteComponentProps} from "react-router"
import {useTranslation}      from "react-i18next"

import {useLockingSystemZones as useItems} from "../../contexts/LockingSystemZoneContext"

import ItemView                     from "../../components/LockingSystemZones/View"
import KeysList                     from "../../components/LockingSystemZones/KeysList"
import LocksList                    from "../../components/LockingSystemZones/LocksList"
import LockingSystemPermissionsList from "../../components/LockingSystemZones/LockingSystemPermissionsList"

import PageItem from '../common/Item'
import NotFound from "../../components/LockingSystemZones/NotFound";

interface itemProps extends RouteComponentProps<{
    id:      string;
    segment: string;
}> {}

const PageLockingSystemZonesItem: React.FC<itemProps> = ({ match }) => {
    const { t }        = useTranslation()
    const { fetchItems, items} = useItems()

    const
        itemType:     string = 'LockingSystemZone',
        itemTypeName: string = t('locking-system-zones.title_item'),
        itemTypeUrl:  string = 'locking-system-zones'

    return (
        <PageItem
            useItems={useItems}
            fetchItems={fetchItems}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            KeysList={KeysList}
            LocksList={LocksList}
            LockingSystemPermissionsList={LockingSystemPermissionsList}
            segment={match.params.segment}
        />
    )
}

export default PageLockingSystemZonesItem