import ApiClient from "./ApiClient"

import User from "../types/User"

export const UserApi = {

    apiPath: 'users',

    getAll: async (search?: string): Promise<User[]> => {
        const items: User[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get(UserApi.apiPath, { params });

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    // getSelectOptions: async(): Promise<SelectOption[]> => {
    //     const selectOptions: SelectOption[] = []
    //     const params = new URLSearchParams([
    //         ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
    //     ])
    //
    //     const response = await ApiClient.get(
    //         'select-options/' + PersonApi.apiPath, { params }
    //     );
    //
    //
    //     for (let selectOption of response.data.data) {
    //         selectOptions.push(selectOption)
    //     }
    //
    //     return selectOptions
    // },
    //
    // add: async (newPerson: Person, handleAddItem: (id: number) => void): Promise<Person> => {
    //
    //     const result = await ApiClient.post(PersonApi.apiPath, newPerson);
    //
    //     const itemRef = result.data.data;
    //
    //     handleAddItem(itemRef.id);
    //
    //     return result?.data.data
    // },
    //
    // edit: async (person: Person): Promise<Persone> => {
    //
    //     const result = await ApiClient.put(PersonApi.apiPath + '/' + person.id, person);
    //
    //     return result?.data.data
    // },
    //
    // delete: async(id: number): Promise<any> => {
    //
    //     return await ApiClient.delete(PersonApi.apiPath + '/' + id);
    //
    // },
    //
    // syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
    //     const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])
    //
    //     const result = await ApiClient.get(PersonApi.apiPath + '/sync/all', { params })
    //
    //     return result?.data
    // },

}