import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound     from "../common/Items/ItemNotFound"
import IconBlocklistKey from "../common/Icons/BlocklistKey"

const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/blocklist-keys'
    const icon: JSX.Element = <IconBlocklistKey icon='key' link={link} size="large" />

    return (
        <ItemNotFound icon={icon} link={link} subTitle={t('keys.not_found')} />
    )
}

export default NotFound