import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonLabel, IonList} from "@ionic/react"

import Person  from "@customTypes/Person"
import KeyType from "@customTypes/Key"

import IconKeyInUse             from "../common/Icons/KeyInUse"
import BadgeKeyProgrammingState from "../common/Badges/BadgeKeyProgrammingState"
import IconBlocklistKey         from "../common/Icons/BlocklistKey"
import ItemSegmentListHeader    from "../common/Headers/ItemSegmentListHeader"



interface componentProps {
    item: Person | undefined;
    keys: KeyType[] | null | undefined;
}

const KeysList: React.FC<componentProps> = ({ item, keys }) => {

    const { t } = useTranslation()

    const title:  string = item?.firstName + ' ' + item?.lastName
    let subTitle: string = item?.companyName ? item?.companyName : ''

    if (item?.email) {
        subTitle += subTitle.length > 0 ? ' - ' + item?.email : item?.email
    }

    return (
        <IonList lines="full">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />

            {keys === undefined || keys?.length === 0 ? (
                <IonItem className="ion-text-center" lines="inset">
                    <IonLabel className="ion-text-wrap">
                        {t('people.no_keys_for_person')}
                    </IonLabel>
                </IonItem>
            ) : (
                keys?.map( (key: KeyType, keyId: number) => {

                    const icon: JSX.Element = key.blocklistKey?.id
                        ? <IconBlocklistKey icon={key.keyType?.icon} slot="start" />
                        : <IconKeyInUse icon={key.keyType?.icon} slot="start" />

                    const routerLink: string = key.blocklistKey?.id
                        ? '/blocklist-keys/' + key.blocklistKey?.id + '/view'
                        : '/keys-in-use/' + key.id + '/view'

                    return (
                        <IonItem button detail key={'key-' + key.id} lines="inset" routerLink={routerLink}>
                            {icon}
                            <IonLabel>
                                <h2>{key.description}</h2>
                                <p style={{ 'textTransform': 'uppercase' }}>
                                    {key.keyType?.lockingSystemType?.brand?.name} - {key.keyType?.name}
                                </p>
                            </IonLabel>
                            {key.keyProgrammingState &&
                                <BadgeKeyProgrammingState item={key.keyProgrammingState} />
                            }
                        </IonItem>
                    )
                }))}
        </IonList>
    )

}

export default KeysList