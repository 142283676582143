import React from "react"
import {IonItemGroup, IonItemSliding, IonList} from "@ionic/react"

import {useLockingSystemZones as useItems} from '../../contexts/LockingSystemZoneContext'
import {default as ItemType}               from "../../types/LockingSystemZone"

import {default as ItemSkeleton} from "./ListItemSkeleton"
import {default as Item}         from "./ListItem"
import ListItemNoResult from "../common/Items/ListItemNoResult";



interface componentProps {
    //
}

const List: React.FC<componentProps> = () => {
    const { items, loadingItems } = useItems()

    const skeletonList = () => {
        return [...Array(6)].map((item, index) => {
            return (
                <IonItemGroup key={index}>
                    <ItemSkeleton/>
                </IonItemGroup>
            );
        })
    }

    const itemList = (items: ItemType[]) => {
        if (items.length === 0 ) {
            return <ListItemNoResult />
        }

        return items.map((item, keyId) => {
            return (
                <IonItemSliding key={item.id}>
                    <Item item={item} />
                </IonItemSliding>
            );
        })
    }

    return (
        <IonList>
            { items === undefined || loadingItems
                ? skeletonList()
                : itemList(items)
            }
        </IonList>
    )
}

export default List