import React, {SetStateAction} from "react"

import { LockApi }                      from "../services/LockApi"
import Lock                             from "../types/Lock"
import {LockLockingSystemPermissionAdd} from "../types/LockLockingSystemPermission"
import lockingSystemPermission          from "../types/LockingSystemPermission"
import {MessageInterface}               from "../components/common/Message"
import SyncResult                       from "../types/SyncResult"
import LockLockingSystemPermissionApi   from "../services/LockLockingSystemPermissionApi"


export type LockContextType = {
    items:      Lock[] | undefined
    addItem:    (key: Lock) => void
    deleteItem: (id: number) => Promise<boolean | void> | void;
    editItem:   (key: Lock) => Promise<Lock | void> | void;
    syncItem:   (item: Lock, soft?: boolean) => Promise<Lock | void> | void


    addLockingSystemPermissions: (item: Lock, lockingSystemPermissionIds: number[]) => Promise<void> | void
    deleteLockingSystemPermission: (item: Lock, lockingSystemPermission: lockingSystemPermission) => void
    deleteAllPermissions: (item: Lock) => void;

    fetchItems: (query?: string, force?: boolean) => void;

    loadingItems: boolean;

    inProgress:   boolean;

    message: MessageInterface | undefined;
    closeMessage: () => void;

    syncLockLockingSystemPermissions: (lockingSystemId: number, setIsSynchronizing: React.Dispatch<SetStateAction<boolean>>)
        => Promise<SyncResult> | void;
}

const initialState = {
    items:      undefined,
    addItem:    () => {},
    editItem:   () => {},
    deleteItem: () => {},
    syncItem:   () => {},

    addLockingSystemPermissions:   () => {},
    deleteLockingSystemPermission: () => {},
    deleteAllPermissions:          () => {},

    fetchItems:   () => {},

    loadingItems: true,

    inProgress:   false,

    message:      undefined,
    closeMessage: () => {},

    syncLockLockingSystemPermissions: () => {},
}

export const LockContext = React.createContext<LockContextType>(initialState);

interface providerProps {
    children: React.ReactNode
}

const LockProvider: React.FC<providerProps> = ( { children }) => {
    const [
        items, setItems
    ] = React.useState<Lock[] | undefined>(initialState.items)
    const [
        loadingItems, setLoadingItems
    ] = React.useState<boolean>(initialState.loadingItems)

    const [inProgress, setInProgress] = React.useState<boolean>(initialState.inProgress)
    const [message, setMessage] = React.useState<MessageInterface | undefined>(initialState.message)

    const fetchItems = async (search?: string,  force: boolean = false) => {
        if (items === undefined || force) {
            setLoadingItems(true)

            setItems( await LockApi.getAll(search) )

            setLoadingItems(false)
        }
    }

    React.useEffect(() => {
        if (items === undefined) return

        const handleAddItem = (id: number) => {
            const newItems = items?.map(item => {
                if (item.id === 0) {
                    return {
                        ...item,
                        id
                    }
                } else {
                    return item;
                }
            })

            setItems(newItems);
        }

        for (let item of items) {
            if (!item.id) {
                LockApi.add(item, handleAddItem);
            }
        }

    }, [items]);

    const addItem = (newItem: Lock) => {
        closeMessage()
        setInProgress(true)

        if (items) {
            setItems([newItem, ...items]);
        }

        setInProgress(false)
    }

    const editItem = async (editedItem: Lock): Promise<Lock | void> => {
        closeMessage()
        setInProgress(true)

        const result = await LockApi
            .edit(editedItem)
            .catch((e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return
            })
        ;

        if (items && result) {
            const newItems: Lock[] = items.map(oldItem => {
                if (oldItem.id === editedItem.id && result.id) {
                    return {
                        ...result
                    }
                } else {
                    return oldItem
                }
            })

            setItems(newItems)
            setInProgress(false)
        }

        return result
    }

    const deleteItem = (id: number): Promise<boolean> | void => {
        closeMessage()
        setInProgress(true)

        LockApi.delete(id)
            .catch((e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return false
            })
        ;

        if (items !== undefined) {
            const newItems = [...items]
            newItems.splice(newItems.findIndex(i => i.id === id), 1)

            setItems(newItems)
            setInProgress(false)

            return
        }

        return
    }

    const deleteLockingSystemPermission = async (
        item: Lock, lockingSystemPermission: lockingSystemPermission
    ): Promise<void> => {
        closeMessage()
        setInProgress(true)

        await LockApi.deleteLockingSystemPermission(item.id, lockingSystemPermission.id)
            .catch(e => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return
            })

        const newItems = items?.map(i => {
            if (i.id !== item.id) {
                return i;
            } else {
                const newPermissions = [...i.lockingSystemPermissions || []]
                newPermissions.splice(
                    newPermissions.findIndex(i => i.id === lockingSystemPermission.id),
                    1
                )

                return {
                    ...i,
                    lockingSystemPermissions: newPermissions
                }
            }
        })

        setItems(newItems)
        setInProgress(false)
    }

    const deleteAllPermissions = async (item: Lock): Promise<void> => {
        closeMessage()
        setInProgress(true)

        const result = await LockLockingSystemPermissionApi
            .deleteAll(item.id)
            .catch((e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return
            })

        const status: boolean = result.data.status === 'success'

        if (status && items !== undefined) {
            const newItems: Lock[] = items.map(i => {
                if (i.id === item.id) {
                    i.lockingSystemPermissions = []
                }

                return i
            })

            setItems(newItems)
            setInProgress(false)
        }

        return
    }

    const addLockingSystemPermissions = async (
        item: Lock, lockingSystemPermissionIds: number[]
    ): Promise<any | void> => {
        closeMessage()
        setInProgress(true)

        const lockLockingSystemPermissions: LockLockingSystemPermissionAdd = {
            lockId: item.id,
            lockingSystemPermissionIds: lockingSystemPermissionIds
        }

        const result = await LockLockingSystemPermissionApi
            .add(lockLockingSystemPermissions)
            .catch(e => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return
            })

        if ( result )
        {
            const newItems = items?.map( item => {
                if (item.id === lockLockingSystemPermissions.lockId) item.lockingSystemPermissions = result
                return item
            })

            setItems(newItems)
            setInProgress(false)
        }
    }

    const syncLockLockingSystemPermissions = async(
        lockingSystemId: number, setIsSynchronizing: React.Dispatch<SetStateAction<boolean>>, fetch: boolean = true
    ): Promise<SyncResult> => {

        const result = await LockLockingSystemPermissionApi.syncAllByLockingSystemId(lockingSystemId);

        if (fetch) {
            await fetchItems('', true);
        }

        setIsSynchronizing(false)

        return result
    }

    const syncItem = async (item: Lock, softSync: boolean = false): Promise<Lock | void> => {
        closeMessage()

        if (inProgress) return

        setInProgress(true)

        const newItem = await LockApi
            .syncItemById(item, softSync)
            .catch((e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)
            })

        if (newItem) {
            setInProgress(false)

            return newItem
        }
    }

    const closeMessage = () => {
        setMessage(undefined)
    }

    return (
        <LockContext.Provider
            value={{
                items,
                addItem,
                editItem,
                deleteItem,

                addLockingSystemPermissions,
                deleteLockingSystemPermission,
                deleteAllPermissions,

                fetchItems,

                loadingItems,

                inProgress,

                message,
                closeMessage,

                syncItem,
                syncLockLockingSystemPermissions,
            }}
        >
            {children}
        </LockContext.Provider>
    );
}

export const useLocks = () => {
    return React.useContext(LockContext) as LockContextType
}

export default LockProvider