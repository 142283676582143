import React from "react"
import {useTranslation} from "react-i18next"

import {useKeys as useItems}   from "../../contexts/KeyContext"

import ItemList from '../../components/KeysInSafe/List'
import PageList from '../common/List'

const PageKeysInSafeList: React.FC = () => {
    const { t } = useTranslation()

    const
        itemType: string     = 'keysInSafe',
        itemTypeName: string = t('keys.keyGroup.keys_in_safe.title_list'),
        itemTypeUrl: string  = 'keys-in-safe'

    // const { fetchItemsInUse, fetchItemsOnBlocklist, fetchItemsInSafe } = useItems()

    const { fetchItemsInSafe } = useItems()

    // React.useEffect(() => {
    //     fetchItemsInUse()
    //     fetchItemsOnBlocklist()
    // })

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            fetchItems={fetchItemsInSafe}
            useItems={useItems}
        />
    )
}

export default PageKeysInSafeList