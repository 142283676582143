import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonLabel, IonList} from "@ionic/react"

import IconLock from "../common/Icons/Lock"

import LockType              from "../../types/Lock"
import LockingSystemZone from "../../types/LockingSystemZone"

import ItemSegmentListHeader from "../common/Headers/ItemSegmentListHeader";

interface componentProps {
    item: LockingSystemZone | undefined;
    locks: LockType[] | undefined;
}

const List: React.FC<componentProps> = ({ item, locks }) => {

    const { t } = useTranslation()

    const title: string    = item?.name || '-'
    const subTitle: string = item?.description || '(' + t('common.no_description') + ')'

    return (
        <IonList lines="full" className="maxWidth">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />

            {locks === undefined || locks?.length === 0 ? (
                <IonItem className="ion-text-center">
                    <IonLabel className="ion-text-wrap">
                        {t('locking-system-zones.no_locks_for_zone')}.
                    </IonLabel>
                </IonItem>
            ) : (
                locks.filter((lock: LockType): boolean => {
                    return !!lock.id
                }).map( (lock: LockType) => {
                    return (
                        <IonItem button detail key={'lock-' + lock.id} lines="inset"
                                 routerLink={'/locks/' + lock.id + '/view'}>
                            <IconLock slot="start" />
                            <IonLabel>
                                <h2>{lock.lockingObject}</h2>
                                <p style={{ 'textTransform': 'uppercase' }}>
                                    {lock.lockType?.lockingSystemType?.brand?.name} - {lock.lockType?.name}
                                </p>
                            </IonLabel>
                        </IonItem>
                    )
                })
            )}
        </IonList>
    )
}

export default List
