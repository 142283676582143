import React from "react"
import {useTranslation} from "react-i18next"

import LockingSystemPermission     from "../../types/LockingSystemPermission"
import IconLockingSystemPermission from "../common/Icons/LockingSystemPermission"
import ItemViewHeader              from "../common/Headers/ItemViewHeader"


export interface componentProps {
    item: LockingSystemPermission
}

const ViewHeader: React.FC<componentProps> = ({item}) => {

    const { t } = useTranslation()

    return (
        <ItemViewHeader
            icon={<IconLockingSystemPermission
            link={'/locking-system-permissions/' + item.id + '/form'}
            text={item.name} />}
            title={item.name || ''}
            subTitle={item?.description || '(' + t('common.no_description') + ')'}
        />
    )

}

export default ViewHeader