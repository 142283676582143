import React from 'react'

import { useAuth } from "./contexts/AuthContext"

import AuthenticatedApp      from "./AuthenticatedApp"
import Loader                from "./pages/Loader/Loader"
import RoutesUnauthenticated from "./routes/Unauthenticated"

const AuthApp: React.FC = () => {
    const { userInfo  } = useAuth()

    switch (userInfo)
    {
        case undefined: {
            return <Loader />
        }
        case null: {
            return <RoutesUnauthenticated />
        }
        default: {
            return <AuthenticatedApp />
        }
    }
}

export default AuthApp