import React from "react"
import {IonButton, IonIcon, useIonAlert} from "@ionic/react";
import {closeCircleOutline, closeCircleSharp} from "ionicons/icons";

import KeyType   from "../../types/Key";
import {useKeys} from "../../contexts/KeyContext";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

interface componentProps {
    item: KeyType;
}

const ButtonBlock: React.FC<componentProps> = ({item}) => {

    const { addToBlocklist, inProgress } = useKeys()
    const history            = useHistory()
    const [ presentAlert ]   = useIonAlert()
    const { t }              = useTranslation()

    const alertAddToBlocklist = async () => {
        if (item === undefined) return

        return presentAlert({
            header:  t('keys.alerts.block.header'),
            message: t('keys.alerts.block.message'),
            buttons: [
                {
                    text: t('common.cancel'),
                    role: 'cancel'
                },
                {
                    text: t('common.continue'),
                    role: 'confirm',
                    handler: () => {
                        onBlock()
                    },
                },
            ],
        })
    }

    const onBlock = async () => {
        await addToBlocklist(item)

        history.push('/blocklist-keys')
    }

    return (
        <IonButton disabled={inProgress} color="dark" fill="outline" className="ion-padding-start" type="button"
                   onClick={() => alertAddToBlocklist()}>
            <IonIcon slot="start" ios={closeCircleOutline} md={closeCircleSharp} />
            {t('common.block')}
        </IonButton>
    )
}

export default ButtonBlock