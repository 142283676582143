import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

// import Person from "../../../types/Person"
import Person from "@customTypes/Person"

export interface componentProps {
    item: Person
}

const ViewItemGroupInfo: React.FC<componentProps> = ({item} ) => {

    const {t} = useTranslation()

    const keysCount: number  = item !== undefined ? item.keys?.length  || 0 : 0

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            <IonItem lines="full" button={keysCount > 0} detail={keysCount > 0}
                     routerLink={'/people/' + item.id + '/keys'}>
                <IonLabel>{t('keys.title_list')}</IonLabel>
                <IonNote slot="end">{item?.keys?.length || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ViewItemGroupInfo