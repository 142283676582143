import React from "react"
import { KeyApi } from "../services/KeyApi";

import BlocklistKey                    from "../types/BlocklistKey"
import {KeyLockingSystemPermissionAdd} from "../types/KeyLockingSystemPermission"
import Key                             from "../types/Key"
import KeyPhone                        from "../types/KeyPhone"
import LockingSystemPermission         from "../types/LockingSystemPermission"

import KeyPhoneApi from "../services/KeyPhoneApi"


import {MessageInterface} from "../components/common/Message"

import BlocklistKeyApi from "../services/BlocklistKeyApi";
import KeyLockingSystemPermissionApi from "../services/KeyLockingSystemPermissionApi";


export type KeyContextType = {
    itemsInUse:       Key[]          | undefined
    itemsInSafe:      Key[]          | undefined
    itemsOnBlocklist: BlocklistKey[] | undefined

    addItem:    (key: Key)                               => Promise<Key  | void> | void
    deleteItem: (item: Key, blocklistKey?: BlocklistKey) => Promise<any> | void
    editItem:   (originalKey: Key, editedKey: Key)       => Promise<Key  | void> | void
    syncItem:   (item: Key, soft?: boolean)              => Promise<Key  | void> | void

    moveKeyFromInUseToSafe: (item: Key)                              => void
    resetItem:              (item: Key, blocklistKey?: BlocklistKey) => Promise<Key | void> | void

    addLockingSystemPermissions:   (item: Key, lockingSystemPermissionIds: number[]) => Promise<void> | void
    deleteLockingSystemPermission: (item: Key, lockingSystemPermission:    LockingSystemPermission) => Promise<void> | void
    deleteAllPermissions:          (item: Key) => Promise<void> | void

    clearItemsInUse:       () => void

    fetchItemsInUse:       (search?: string, force?: boolean) => void;
    fetchItemsInSafe:      (search?: string, force?: boolean) => void;
    fetchItemsOnBlocklist: (search?: string, force?: boolean) => void;

    loadingItemsInUse: boolean;
    loadingItemsInSafe: boolean;
    loadingItemsOnBlocklist: boolean;

    inProgress:   boolean;

    message: MessageInterface | undefined;
    closeMessage: () => void;

    addToBlocklist:      (item: Key)          => Promise<any> | void
    removeFromBlocklist: (item: BlocklistKey) => Promise<any> | void

    reRegisterKeyPhone: (item: KeyPhone) => void
    returnKeyPhone:     (item: KeyPhone) => void
}

const initialState = {
    itemsInUse:       undefined,
    itemsInSafe:      undefined,
    itemsOnBlocklist: undefined,

    addItem:                () => {},
    editItem:               () => {},
    deleteItem:             () => {},
    moveKeyFromInUseToSafe: () => {},
    resetItem:              () => {},
    syncItem:               () => {},

    addLockingSystemPermissions:   () => {},
    deleteLockingSystemPermission: () => {},
    deleteAllPermissions:          () => {},

    clearItemsInUse:       () => {},

    fetchItemsInUse:       () => {},
    fetchItemsInSafe:      () => {},
    fetchItemsOnBlocklist: () => {},

    loadingItemsInUse:       true,
    loadingItemsInSafe:      true,
    loadingItemsOnBlocklist: true,

    inProgress:   false,

    message:      undefined,
    closeMessage: () => {},

    addToBlocklist:      () => {},
    removeFromBlocklist: () => {},

    reRegisterKeyPhone: () => {},
    returnKeyPhone: () => {},
}

export const KeyContext = React.createContext<KeyContextType>(initialState);

interface providerProps {
    children: React.ReactNode
}

const KeyProvider: React.FC<providerProps> = ( { children }) => {
    const [itemsInUse,  setItemsInUse ] = React.useState<Key[] | undefined>(initialState.itemsInUse)
    const [itemsInSafe, setItemsInSafe] = React.useState<Key[] | undefined>(initialState.itemsInSafe)
    const [itemsOnBlocklist, setItemsOnBlocklist] = React.useState<BlocklistKey[] | undefined>(initialState.itemsOnBlocklist)

    const [loadingItemsInUse, setLoadingItemsInUse]        = React.useState<boolean>(initialState.loadingItemsInUse)
    const [loadingItemsInSafe, setLoadingItemsInSafe] = React.useState<boolean>(initialState.loadingItemsInSafe)
    const [loadingItemsOnBlocklist, setLoadingItemsOnBlocklist] = React.useState<boolean>(initialState.loadingItemsOnBlocklist)

    const [inProgress, setInProgress] = React.useState<boolean>(initialState.inProgress)
    const [message, setMessage] = React.useState<MessageInterface | undefined>(initialState.message)

    const clearItemsInUse = () => {
        setItemsInUse(undefined)
    }

    const fetchItemsInUse = async (search?: string, force: boolean = false): Promise<any> => {

        if (itemsInUse === undefined || force) {
            setLoadingItemsInUse(true)

            const result = await KeyApi.getKeysInUse(search)
                .catch((e) => setErrorMessage(e))

            if (result !== undefined) {
                setItemsInUse(result);

                setLoadingItemsInUse(false)
            }
        }
    }

    const fetchItemsInSafe = async (search?: string, force: boolean = false) => {
        if (itemsInSafe === undefined || force) {
            setLoadingItemsInSafe(true)
            setItemsInSafe( await KeyApi.getKeysInSafe(search) )
            setLoadingItemsInSafe(false)
        }
    }

    const fetchItemsOnBlocklist = async (search?: string, force: boolean = false) => {
        if (itemsOnBlocklist === undefined || force) {
            setLoadingItemsOnBlocklist(true)
            setItemsOnBlocklist(await BlocklistKeyApi.get(search))
            setLoadingItemsOnBlocklist(false)
        }
    }

    const addItem = async (newItem: Key): Promise<Key | void> => {
        closeMessage()
        setInProgress(true)

        // const newKey = await KeyApi.add(newItem, () => {})
        const newKey = await KeyApi.add(newItem, () => {})
            .catch((e) => setErrorMessage(e))

        if (itemsInUse && newKey) {
            setInProgress(false)
            setItemsInUse([newKey, ...itemsInUse])
        }

        return newKey
    }

    const editItem = async (originalKey: Key, editedItem: Key): Promise<Key | void> => {
        closeMessage()
        setInProgress(true)

        const result = await KeyApi.edit(editedItem)
            .catch((e) => setErrorMessage(e))


        if (itemsInUse === undefined && itemsInSafe === undefined) return;

        // Key stays in use by a person
        if (result && itemsInUse !== undefined
            && typeof originalKey.personId === 'number' && typeof editedItem.personId === 'number')
        {
            const newItems: Key[] = itemsInUse.map(oldItem => {
                return (oldItem.id === editedItem.id && result.id) ? {...result} : oldItem
            })

            setItemsInUse(newItems)
            setInProgress(false)

            return result
        }

        // Key stays in Key Safe
        if (result && itemsInSafe !== undefined
            && typeof originalKey.personId !== 'number' && typeof editedItem.personId !== 'number')
        {
            const newItems: Key[] = itemsInSafe.map(oldItem => {
                return (oldItem.id === editedItem.id && result.id) ? {...result} : oldItem
            })

            setItemsInSafe(newItems)
            setInProgress(false)

            return result
        }

        // Key was assigned to person but now goes to Key Safe
        if (result && itemsInUse !== undefined && itemsInSafe !== undefined
            && typeof originalKey.personId === 'number' && typeof editedItem.personId !== 'number')
        {
            // Remove from items
            const newItems: Key[] = [...itemsInUse];
            newItems.splice(newItems.findIndex(i => i.id === originalKey.id), 1);
            setItemsInUse(newItems)

            // Add to itemsInSafe
            setItemsInSafe([result, ...itemsInSafe])
            setInProgress(false)

            return result
        }

        // Key was in Key Safe but now is assigned to person
        if (result && itemsInUse !== undefined && itemsInSafe !== undefined
            && typeof originalKey.personId !== 'number' && typeof editedItem.personId === 'number')
        {
            // Remove from itemsInSafe
            const newItemsInSafe = [...itemsInSafe];
            newItemsInSafe.splice(newItemsInSafe.findIndex(i => i.id === originalKey.id), 1);
            setItemsInSafe(newItemsInSafe)

            // Add to items
            setItemsInUse([result, ...itemsInUse])
            setInProgress(false)

            return result
        }
    }

    const deleteItem = async (item: Key, blocklistKey?: BlocklistKey): Promise<any> => {
        closeMessage()
        setInProgress(true)

        const result = await KeyApi.delete(item.id)
            .catch((e) => setErrorMessage(e))

        // Key was on blocklist
        if (blocklistKey !== undefined) {
            if (itemsOnBlocklist !== undefined) {
                const newItems: BlocklistKey[] = [...itemsOnBlocklist]
                newItems.splice(newItems.findIndex(i => i.id === blocklistKey.id), 1)

                setItemsOnBlocklist(newItems)
            }

            setInProgress(false)

            return result
        }

        // Key is in Key Safe
        if (typeof item.personId !== 'number') {
            if (itemsInSafe !== undefined) {
                const newItems: Key[] = [...itemsInSafe]
                newItems.splice(newItems.findIndex(i => i.id === item.id), 1)

                setItemsInSafe(newItems)
            }

            setInProgress(false)

            return result
        }

        // Key was in use
        if (itemsInUse !== undefined) {
            const newItems: Key[] = [...itemsInUse]
            newItems.splice(newItems.findIndex(i => i.id === item.id), 1)

            setItemsInUse(newItems)
            setInProgress(false)
        }

        return result
    }

    const moveKeyFromInUseToSafe = async (keyItem: Key): Promise<any> => {
        let index: number | undefined = itemsInUse?.findIndex(i => i.id === keyItem.id)

        if (index !== undefined && itemsInUse) {
            let key = itemsInUse[index]

            key.personId = null
            key.person   = null

            // Add key to itemsInSafe
            setItemsInSafe([key, ...itemsInSafe || []]);

            // Remove key from itemsInUse
            const newItems: Key[] = [...itemsInUse]
            newItems.splice(index, 1)

            setItemsInUse(newItems)
        }
    }

    const resetItem = async (item: Key, blocklistKey?: BlocklistKey): Promise<Key | void> => {
        closeMessage()
        setInProgress(true)

        const newKey = await KeyApi.reset(item)
            .catch((e) => setErrorMessage(e))

        // Add to Key Safe
        if (itemsInSafe !== undefined && newKey) {
            setItemsInSafe([newKey, ...itemsInSafe]);
        }

        // In case it was on the blocklist
        if (blocklistKey !== undefined && newKey) {
            if (itemsOnBlocklist !== undefined) {
                const newItems: BlocklistKey[] = [...itemsOnBlocklist]
                newItems.splice(newItems.findIndex(i => i.id === blocklistKey.id), 1)

                setItemsOnBlocklist(newItems)
            }

            setInProgress(false)

            return newKey
        }

        // In case the Key was in use
        if (itemsInUse !== undefined && newKey) {
            const newItems: Key[] = [...itemsInUse]
            newItems.splice(newItems.findIndex(i => i.id === item.id), 1)

            setItemsInUse(newItems)
            setInProgress(false)

            return newKey
        }
    }

    const syncItem = async (item: Key, softSync: boolean = false): Promise<Key | void> => {
        closeMessage()

        if (inProgress) return

        setInProgress(true)

        const newItem: Key | void = await KeyApi.syncItemById(item, softSync)
            .catch((e) => setErrorMessage(e))

        if (newItem) {
            setInProgress(false);

            return newItem
        }
    }

    const deleteLockingSystemPermission = async (
        item: Key, lockingSystemPermission: LockingSystemPermission
    ): Promise<void> => {
        closeMessage()
        setInProgress(true)

        const result = await KeyLockingSystemPermissionApi.delete(item, lockingSystemPermission)
            .catch(e => setErrorMessage(e))

        if (result) {

            // Key is in Key Safe
            if (typeof item.personId !== 'number') {
                if (itemsInSafe === undefined) return

                const newItems: Key[] = itemsInSafe.map(i => {
                    if (i.id !== item.id) return i

                    const newPermissions = [...i.lockingSystemPermissions || []]
                    newPermissions.splice(
                        newPermissions.findIndex(fi => fi.id === lockingSystemPermission.id)
                        , 1
                    )

                    return {...i, lockingSystemPermissions: newPermissions}
                })

                setItemsInSafe(newItems)
                setInProgress(false)

                return
            }

            if (itemsInUse === undefined) return

            const newItems: Key[] = itemsInUse.map(i => {
                if (i.id !== item.id) return i

                const newPermissions = [...i.lockingSystemPermissions || []]
                newPermissions.splice(newPermissions.findIndex(fi => fi.id === lockingSystemPermission.id), 1)

                return {...i, lockingSystemPermissions: newPermissions}
            })

            setItemsInUse(newItems)
            setInProgress(false)
        }

    }

    const deleteAllPermissions = async (item: Key): Promise<void> => {
        closeMessage()
        setInProgress(true)

        const result = await KeyLockingSystemPermissionApi.deleteAll(item)
            .catch((e) => setErrorMessage(e))

        const status: boolean = result.data.status === 'success'

        // Key is in Key Safe
        if (status && typeof item.personId !== 'number') {
            if (itemsInSafe === undefined) return

            const newItems: Key[] = itemsInSafe.map(i => {
                if (i.id === item.id) i.lockingSystemPermissions = []

                return i
            })

            setItemsInSafe(newItems)
            setInProgress(false)

            return
        }

        // Key is in Use
        if (status && itemsInUse !== undefined) {
            const newItems: Key[] = itemsInUse.map(i => {
                if (i.id === item.id) i.lockingSystemPermissions = []

                return i
            })

            setItemsInUse(newItems)
            setInProgress(false)

            return
        }
    }

    const addLockingSystemPermissions = async (
        item: Key, lockingSystemPermissionIds: number[]
    ): Promise<any | void> => {
        closeMessage()
        setInProgress(true)

        //keyLockingSystemPermissions: KeyLockingSystemPermissionAdd

        const keyLockingSystemPermissions: KeyLockingSystemPermissionAdd = {
            keyId:                      item.id,
            lockingSystemId:            item.lockingSystemId || 0,
            lockingSystemPermissionIds: lockingSystemPermissionIds
        }

        const result = await KeyLockingSystemPermissionApi.add(keyLockingSystemPermissions)
            .catch((e) => setErrorMessage(e))

        if ( result ) {

            // When Key is in Key Safe
            if (typeof item.personId !== 'number') {
                const newItems: Key[] | undefined = itemsInSafe?.map(item => {
                    if (item.id === keyLockingSystemPermissions.keyId) item.lockingSystemPermissions = result

                    return item
                })

                setItemsInSafe(newItems)

                setInProgress(false)

                return
            }

            const newItems: Key[] | undefined = itemsInUse?.map( item => {
                if (item.id === keyLockingSystemPermissions.keyId) item.lockingSystemPermissions = result

                return item
            })

            setItemsInUse(newItems)

            setInProgress(false)
        }
    }

    const addToBlocklist = async (item: Key): Promise<any> => {
        closeMessage()
        setInProgress(true)

        const result = await BlocklistKeyApi.add(item)
            .catch((e) => setErrorMessage(e))

        if (itemsOnBlocklist && result) {
            setItemsOnBlocklist([result, ...itemsOnBlocklist]);
        }

        // Remove from keysInUse
        if (typeof item.personId === 'number' && itemsInUse !== undefined) {
            const newItems = [...itemsInUse]
            newItems.splice(newItems.findIndex(i => i.id === item.id), 1)

            setItemsInUse(newItems)
            setInProgress(false)

            return
        }

        if (itemsInSafe !== undefined) {
            const newItems = [...itemsInSafe]
            newItems.splice(newItems.findIndex(i => i.id === item.id), 1)

            setItemsInSafe(newItems)
            setInProgress(false)
        }
    }

    const removeFromBlocklist = async (item: BlocklistKey): Promise<any> => {
        closeMessage()
        setInProgress(true)

        const result = await BlocklistKeyApi.delete(item)
            .catch((e) => setErrorMessage(e))

        if (itemsOnBlocklist !== undefined && result !== undefined) {
            const newBlocklistItems: BlocklistKey[] = [...itemsOnBlocklist]
            newBlocklistItems.splice(newBlocklistItems.findIndex(i => i.id === item.id), 1)

            // Now get the key and at to KeysInUse or KeysInSafe
            const key: Key | void = await KeyApi
                .get(item.keyId)
                .catch((e) => {
                    setMessage({
                        text: e.response.data.message || '?',
                        type: 'error'
                    })

                    setItemsOnBlocklist(newBlocklistItems)
                    setInProgress(false)

                    return
                })

            if (key && typeof key.personId === 'number' && itemsInUse !== undefined) {
                setItemsInUse([key, ...itemsInUse])
                setItemsOnBlocklist(newBlocklistItems)

                setInProgress(false)

                return true
            } else if (key && itemsInSafe !== undefined) {
                setItemsInSafe([key, ...itemsInSafe])
                setItemsOnBlocklist(newBlocklistItems)

                setInProgress(false)

                return true
            }
        }
    }

    const reRegisterKeyPhone = async(item: KeyPhone): Promise<any> => {

        await KeyPhoneApi.reRegister(item)

    }

    const returnKeyPhone = async (item: KeyPhone): Promise<any> => {

        await KeyPhoneApi.return(item.id)
            .catch((e) => setErrorMessage(e))

    }

    const closeMessage = () => setMessage(undefined)

    const setErrorMessage = (e: any) => {
        setMessage({
            text: e.response.data.message || '?',
            type: 'error'
        })

        setInProgress(false)
    }

    return (
        <KeyContext.Provider
            value={{
                itemsInUse,
                itemsInSafe,
                itemsOnBlocklist,

                addItem,
                editItem,
                deleteItem,
                moveKeyFromInUseToSafe,
                resetItem,
                syncItem,

                addLockingSystemPermissions,
                deleteLockingSystemPermission,
                deleteAllPermissions,

                clearItemsInUse,

                fetchItemsInUse,
                fetchItemsInSafe,
                fetchItemsOnBlocklist,

                loadingItemsInUse,
                loadingItemsInSafe,
                loadingItemsOnBlocklist,

                inProgress,

                message,
                closeMessage,

                addToBlocklist,
                removeFromBlocklist,

                reRegisterKeyPhone,
                returnKeyPhone,
            }}
        >
            {children}
        </KeyContext.Provider>
    );
}

export const useKeys = () => {
    return React.useContext(KeyContext) as KeyContextType
}

export default KeyProvider