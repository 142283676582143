import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import LockingSystemPermission from "../../types/LockingSystemPermission"

export interface componentProps {
    item: LockingSystemPermission
}

const ViewItemGroupInfo: React.FC<componentProps> = ({item} ) => {

    const {t} = useTranslation()

    const keysCount:  number = item !== undefined ? item.keys?.length  || 0 : 0
    const locksCount: number = item !== undefined ? item.locks?.length || 0 : 0

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            <IonItem lines="full" button={keysCount > 0} detail={keysCount > 0}
                     routerLink={'/locking-system-permissions/' + item.id + '/keys'}>
                <IonLabel>{t('keys.title_list')}</IonLabel>
                <IonNote slot="end">{item?.keys?.length || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full" button={locksCount > 0} detail={locksCount > 0}
                     routerLink={'/locking-system-permissions/' + item.id + '/locks'}>
                <IonLabel>{t('locks.title_list')}</IonLabel>
                <IonNote slot="end">{item?.locks?.length || '-'}</IonNote>
            </IonItem>
            {item.lockingSystemZone &&
                <IonItem lines="full" button detail={true}
                         routerLink={'/locking-system-zones/' + item.lockingSystemZone.id + '/view'}>
                    <IonLabel>Zone</IonLabel>
                    <IonNote slot="end">{item.lockingSystemZone.name || '-'}</IonNote>
                </IonItem>
            }
        </IonItemGroup>
    )
}

export default ViewItemGroupInfo