import React from 'react'
import {useTranslation} from 'react-i18next'

import {useKeys as useItems} from '../../contexts/KeyContext'

import ItemList from '../../components/KeysInUse/List'
import PageList from '../common/List'
import {useLockingSystems} from "../../contexts/LockingSystemContext";


const PageKeysInUseList: React.FC = () => {
    const { t } = useTranslation()

    const
        itemType: string     = 'keysInUse',
        itemTypeName: string = t('keys.keyGroup.keys_in_use.title_list'),
        itemTypeUrl: string  = 'keys-in-use'

    const [showAddButton, setShowAddButton] = React.useState<boolean>(false)

    const { fetchItemsInUse } = useItems()

    const { selectedLockingSystemId, hasFeature: hasLockingSystemFeature } = useLockingSystems()

    React.useEffect(() => {
        if (selectedLockingSystemId) {
            const hasFeature = hasLockingSystemFeature('CAN_HAVE_PHONE_KEYS')

            if (hasFeature !== undefined) {
                setShowAddButton(hasFeature)
            }
        }
    }, [selectedLockingSystemId])

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            showAddButton={showAddButton}
            fetchItems={fetchItemsInUse}
            useItems={useItems}
        />
    )
}

export default PageKeysInUseList