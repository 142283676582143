import React from "react"

import {useLockingSystems as useItems} from "../../contexts/LockingSystemContext"
import LockingSystem from "../../types/LockingSystem";

import {default as LockingSystemGrid} from "./Grid"
import {IonCol, IonGrid, IonItem, IonLabel, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";

const List: React.FC = () => {

    const { items } = useItems()
    const { t }  = useTranslation()

    const itemList = (items: LockingSystem[]) => {

        if (items.length === 0 ) {
            return (
                <IonItem lines="full" style={{ 'marginTop': '24px' }}>
                    <IonLabel className="ion-text-center">{t('dashboard.no_locking_systems')}.</IonLabel>
                </IonItem>
            )
        }

        return items.map((item) => {
            return (
                <div key={item.id} className="ion-padding">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeSm="5" style={{ 'fontWeight': 700, 'fontSize': '22px' }}>
                                {item.name}
                            </IonCol>
                            <IonCol sizeSm="7" className="ion-hide-md-down"
                                    style={{ 'fontWeight': 'normal', 'fontSize': '20px', 'color': '#888', 'textAlign': 'right' }}>
                                {item.lockingSystemType?.brand.name} - {item.lockingSystemType?.name}
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <LockingSystemGrid item={item} />
                </div>
            )
        })
    }


    return (
        <div className="maxWidth">
            { items === undefined
                ? <div>...</div>
                : itemList(items)
            }
        </div>
    )
}

export default List