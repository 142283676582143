import React from "react"

import Key            from "../../types/Key"
import ItemViewHeader from "../common/Headers/ItemViewHeader"
import IconKeyInSafe from "../common/Icons/KeyInSafe";
import {useTranslation} from "react-i18next";

export interface componentProps {
    item: Key
}

const ViewHeader: React.FC<componentProps> = ({item}) => {

    const { t }   = useTranslation()

    const itemTitle = (): string => {
        return ('SPARE_KEY' === item.description)
            ? t('keys.spare_key')
            : item.description || '-'
    }

    return <ItemViewHeader
        icon={<IconKeyInSafe icon={item?.keyType?.icon} link={'/keys-in-safe/' + item.id + '/form'} size="large" />}
        title={itemTitle()}
        subTitle={item.keyType?.lockingSystemType?.brand.name + ' - ' + item.keyType?.name}
    />

}

export default ViewHeader