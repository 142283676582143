import React from "react"

import {IonContent, IonItem, IonLabel, IonList, IonPage, IonSkeletonText, RefresherEventDetail} from '@ionic/react'

import './Dashboard.css'

import {useLockingSystems as useItems} from "../../contexts/LockingSystemContext"
import LockingSystemList from "../../components/Dashboard/List"
import ListPageHeader    from "../../components/common/Headers/ListPageHeader"

const DashboardPage: React.FC = () => {

    const { fetchItems, items, loadingItems } = useItems()

    React.useEffect(() => {
        fetchItems();
    })

    async function handleRefresh (event?: CustomEvent<RefresherEventDetail>) {
        fetchItems('', true)

        setTimeout(() => {
            if (event?.detail?.complete) {
                event?.detail?.complete();
            }
        }, 400);
    }

    return (
        <IonPage className="dashboard">
            <ListPageHeader handleRefresh={handleRefresh} itemType="Dashboard" itemTypeName="Dashboard" itemTypeUrl="dashboard" />

            <IonContent className="dashboard" forceOverscroll={false} fullscreen>
                <div className="pageContentContainer">
                    { items !== undefined && ! loadingItems ? (
                        <LockingSystemList />
                    ) : (
                        <IonList className="maxWidth">
                            <IonItem lines="none" slot="start">
                                <IonLabel>
                                    <IonSkeletonText animated={true} style={{'width': '100%', 'height': '33px'}}/>
                                </IonLabel>
                            </IonItem>
                            <IonItem lines="none" slot="start">
                                <IonLabel>
                                    <IonSkeletonText animated={true} style={{'width': '100%', 'height': '140px'}}/>
                                </IonLabel>
                            </IonItem>
                            <IonItem lines="none" slot="start">
                                <IonLabel>
                                    <IonSkeletonText animated={true} style={{'width': '100%', 'height': '140px'}}/>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    )}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default DashboardPage