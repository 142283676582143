import React from 'react'
import {useTranslation} from "react-i18next"

import {IonItem, IonLabel} from "@ionic/react"

import IconLockingSystemPermission      from "../../common/Icons/LockingSystemPermission"
import BadgeLockingSystemPermissionType from "../../common/Badges/BadgeLockingSystemPermissionType"
import ButtonDelete                     from "./ButtonDelete"

import Lock                    from "../../../types/Lock"
import LockingSystemPermission from "../../../types/LockingSystemPermission"

interface props {
    lock:                    Lock;
    lockingSystemPermission: LockingSystemPermission;
}

const ListItem: React.FC<props> = ({lock, lockingSystemPermission}: props) => {

    const { t } = useTranslation()

    const itemBadge = () => {
        if (lockingSystemPermission.lockingSystemPermissionType) {
            return (
                <BadgeLockingSystemPermissionType item={lockingSystemPermission.lockingSystemPermissionType} />
            )
        }
    }

    return (
        <IonItem button className={lockingSystemPermission.isHiddenByDefault ? 'hidden-by-default' : ''}
                 detail key={"lock-" + lock.id + '-permission-' + lockingSystemPermission.id}
            lines="inset" routerLink={"/locking-system-permissions/" + lockingSystemPermission.id + '/view'}>
            <IconLockingSystemPermission slot="start" />
            <IonLabel>
                {lockingSystemPermission.name}
                {lockingSystemPermission.lockingSystemZone &&
                    <span style={{ 'fontWeight': 200 }}>
                        &nbsp;- {lockingSystemPermission.lockingSystemZone.name}
                    </span>
                }
                <p>{lockingSystemPermission.description || <i>{'(' + t('common.no_description') + ')'}</i>}</p>
            </IonLabel>
            {itemBadge()}
            <ButtonDelete lock={lock} lockingSystemPermission={lockingSystemPermission} />
        </IonItem>
    )
}

export default ListItem