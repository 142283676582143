import React              from 'react'
import { useHistory     } from "react-router"
import { useTranslation } from "react-i18next"

import {
    IonCard, IonCardTitle, IonCardHeader, IonCardContent,
    IonItem, IonInput, IonButton, IonContent, IonPage, IonNote, useIonViewWillEnter
} from '@ionic/react'

import './Login.css';

import { useAuth      } from "../../contexts/AuthContext";
import LanguageSelector from "../../components/common/LanguageSelector";
import Message          from "../../components/common/Message";


const PageLoginForm: React.FC = () => {

    const { handleLogin, errors, clearErrors, inProgress, message } = useAuth()
    const history         = useHistory()
    const { t }     = useTranslation()

    const email    = React.useRef<HTMLIonInputElement>(null);
    const password = React.useRef<HTMLIonInputElement>(null);

    useIonViewWillEnter(() => {
        if (clearErrors !== undefined) {
            clearErrors()
        }
    })

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const result = await handleLogin(email.current?.value, password.current?.value)

        if (result) history.push('/dashboard')
    }

    return (
        <IonPage>
            <IonContent className="login" forceOverscroll={false}>
            <div className="bc-flex-center">
                <IonCard>
                    <img className="logo" src="assets/img/logo.svg" width="180" height="80" alt="{process.env.REACT_APP_BRAND_NAME}" />
                    <IonCardHeader>
                        <IonCardTitle style={{ 'fontSize': '24px', 'fontWeight': 400, 'lineHeight': 1.5 }}>
                            {t('login.title')}
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {errors.length > 0 &&
                        <IonNote style={{
                            'width': '100%',
                            'backgroundColor': 'red',
                            'color': 'white',
                            'padding': '8px',
                            'fontWeight': 900
                        }}>
                            {errors}
                        </IonNote>
                        }
                        {message && <Message message={message} />}

                        <form onSubmit={handleSubmit}>
                        <IonItem>
                            <IonInput name="email" type="email" ref={email}
                                      label="E-mail" labelPlacement="floating"
                                      placeholder="demo@brightconnect.nl"
                                      // helperText={t('login.supply_email') as string}
                                      // errorText="Ongeldig e-mailadres"
                                      autofocus={true} required
                            />
                        </IonItem>
                        <IonItem>
                            <IonInput name="password" type="password" ref={password}
                                      label={t('login.password') as string} labelPlacement="floating"
                                      minlength={6} maxlength={128}
                                      placeholder={t('login.password') as string}
                                      // helperText={t('login.supply_password') as string}
                                      // errorText="Ongeldig wachtwoord"
                                      required
                            />
                        </IonItem>

                        <IonButton className="buttonLogin ion-margin-top" color="success"
                                   disabled={inProgress} expand="full" strong type="submit">
                            {t('login.title')}
                        </IonButton>

                        <p>
                            <IonButton className="buttonForgot ion-margin-top" color="dark" expand="full"
                                       fill="clear" routerLink="/forgot-password">
                                {t('login.password_forgotten')}
                            </IonButton>
                        </p>

                        {/*<IonButton className="buttonRegister" color="success"
                        expand="full" fill="clear">Nog geen account?</IonButton>*/}
                        </form>

                    </IonCardContent>
                    <div className="ion-text-center ion-padding-top">
                        <LanguageSelector color="medium" />
                    </div>
                    <div className="ion-text-center ion-padding-top" style={{ 'fontSize': '12px' }}>
                        <p>
                            &copy; 2022-{new Date().getFullYear()}, {process.env.REACT_APP_BRAND_NAME},
                            v{process.env.REACT_APP_VERSION}
                        </p>
                    </div>
                </IonCard>

            </div>
            </IonContent>
        </IonPage>
    );
};

export default PageLoginForm