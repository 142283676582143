import React, {useState} from "react"
import AuthProvider from "../services/Auth";
import {AuthType} from "../types/Auth";
import {MessageInterface} from "../components/common/Message";
import {useTranslation} from "react-i18next";

export type AuthContextType = {
    userInfo: AuthType | null | undefined;
    errors: string[];
    clearErrors: () => void
    handleForgotPassword: (email: any)    => Promise<any>
    handleLogin: (email: any, password: any) => Promise<boolean | void> | void
    handleLogout: () => Promise<any>
    handleResetPassword: (password: string, uuid: string, token: string) => Promise<any>

    handleCallback:    (code: string, state: string) => Promise<any>
    handleCustomLogin: (customId: string)            => Promise<any>

    inProgress: boolean

    message: MessageInterface | undefined
    closeMessage: () => void
}

interface AuthContextProviderProps {
     children: React.ReactNode;
}

const initialState = {
    userInfo:     undefined,
    errors:       [],
    clearErrors: () => {},
    handleForgotPassword: async () => {},
    handleLogin:  async () => {},
    handleLogout: async () => {},
    handleResetPassword: async () => {},

    handleCallback:    async () => {},
    handleCustomLogin: async () => {},

    inProgress: false,

    message:      undefined,
    closeMessage: () => {},
}

export const AuthContext = React.createContext<AuthContextType>(initialState);

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {

    const [inProgress, setInProgress] = useState<boolean>(initialState.inProgress)
    const [
        userInfo, setUserInfo
    ] = useState<AuthType | null | undefined>(initialState.userInfo)
    const [errors, setErrors] = useState<string[]>(initialState.errors)
    const [
        message, setMessage
    ] = useState<MessageInterface | undefined>(initialState.message)

    const {t} = useTranslation()

    React.useEffect(() => {
        if (userInfo === undefined) {
            AuthProvider.observe(setUserInfo);
        }
    },[userInfo]);

    const handleForgotPassword = async (email: any): Promise<any> => {
        clearErrors()
        closeMessage()
        setInProgress(true)

        return await AuthProvider
            .forgotPassword(email, setErrors)
            .catch((err) => {
                setErrors((prev: any) => ([...prev, err.response.data.message]))
            })
            .finally(() => {
                setInProgress(false)
            })
            .then((result) => {
                if (result === 'success') {
                    setMessage({
                        text: t('forgot-password.messages_success'),
                        type: 'notice'
                    })
                }

                return result
            })
    }

    const handleCallback = async(code: string, state: string): Promise<any> => {
        clearErrors()
        closeMessage()
        setInProgress(true)

        return await AuthProvider
            .callback(code, state, setUserInfo)
            .catch((err) => {
                //setErrors((prev: any) => ([...prev, err.response.data.message]))
                setErrors([err.response.data.message])
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    const handleCustomLogin = async (customId: string): Promise<any> => {
        clearErrors()
        closeMessage()
        setInProgress(true)

        return await AuthProvider
            .custom(customId)
            .catch((err) => {
                setErrors((prev: any) => ([...prev, err.response.data.message]))
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    const handleLogin = async (email: any, password: any): Promise<boolean | void> => {
        clearErrors()
        closeMessage()
        setInProgress(true)

        return await AuthProvider
            .login(email, password, setUserInfo, setErrors)
            .catch((err) => {
                setErrors((prev: any) => ([...prev, err.response.data.message]))
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    const handleLogout = async(): Promise<any> => {
        setInProgress(true)

        return await AuthProvider.logout(setUserInfo, setErrors)
    }

    const handleResetPassword = async (password: string, uuid: string, token: string): Promise<any> => {
        closeMessage()

        const status = await AuthProvider.resetPassword(password, uuid, token, setErrors)

        if (status === 'success') {
            setMessage({
                text: t('reset-password.messages_success'),
                type: 'notice'
            })

            return status
        }
    }

    const clearErrors = () => {
        setErrors([])
    }

    const closeMessage = () => {
        setMessage(undefined)
    }

    return (
        <AuthContext.Provider value={{
            userInfo,
            errors,
            clearErrors,
            handleForgotPassword,
            handleLogin,
            handleLogout,
            handleResetPassword,

            handleCallback,
            handleCustomLogin,

            inProgress,

            message,
            closeMessage,
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return React.useContext(AuthContext) as AuthContextType
}

export default AuthContextProvider