import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import Key from "../../types/Key"

export interface componentProps {
    item: Key
}

const ItemGroupLinked: React.FC<componentProps> = ({item}) => {

    const { t } = useTranslation()

    const permissionsCount = item.lockingSystemPermissions?.length || 0
    const zonesCount = item.lockingSystemZones?.length || 0

    return (
        <IonItemGroup className="ion-margin-top">
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            <IonItem button={false} detail={false} lines="full">
                <IonLabel>{t('keys.form.permissions_on_key')}</IonLabel>
                <IonNote slot="end">{permissionsCount || '-'}</IonNote>
            </IonItem>
            <IonItem button={false} detail={false} lines="full">
                <IonLabel>{t('keys.form.zones_for_key')}</IonLabel>
                <IonNote slot="end">{zonesCount || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ItemGroupLinked