import React from 'react'
import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel} from "@ionic/react"
import {banOutline, banSharp, shieldCheckmarkOutline, shieldCheckmarkSharp} from "ionicons/icons"
import {useHistory} from "react-router"

import {LockType as ItemType} from "../../types/Lock"
import ListItemOptionDelete   from "./ListItemOptionDelete"

import IconLock from "../common/Icons/Lock";
import BadgeLockProgrammingState from "../common/Badges/BadgeLockProgrammingState";

interface itemProps {
    item: ItemType;
}

const ListItem: React.FC<itemProps> = ({ item }) => {
    const lockPermissionsCount: number = item.lockingSystemPermissions?.length || 0;

    const history = useHistory()

    const goToLockPermissions = (ev: any, itemId: number) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push('/locks/' + itemId + '/lockingSystemPermissions')
    }

    return (
        <>
            <IonItem button detail slot="start" routerLink={"/locks/" + item.id + '/view'}>
                <IconLock slot="start" />
                <IonLabel>
                    {item.lockingObject}
                    {(item.serial && item.serial !== '0') &&
                    <span style={{ 'fontWeight': '200', 'fontSize': '14px' }}>
                        &nbsp;({item.serial})
                    </span>
                    }
                    {item.lockingSystemZone &&
                    <span style={{ 'fontWeight': '200', 'fontSize': '14px' }}>
                        &nbsp;- {item.lockingSystemZone.name}
                    </span>
                    }
                    <p>
                        {item.lockType?.lockingSystemType?.brand?.name} - {item.lockType?.name}
                    </p>
                </IonLabel>
                {item.lockProgrammingState &&
                    <BadgeLockProgrammingState item={item.lockProgrammingState} />
                }
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton color="medium" fill="clear"
                               onClick={ (ev) => goToLockPermissions(ev, item.id) }
                               size="large" style={{ 'fontSize': '17px' }}
                    >
                        {lockPermissionsCount ? lockPermissionsCount : ''}
                        <IonIcon slot="end" color="bc-blue"
                                 ios={lockPermissionsCount ? shieldCheckmarkOutline : banOutline}
                                 md={lockPermissionsCount  ? shieldCheckmarkSharp   : banSharp} />
                    </IonButton>
                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                <ListItemOptionDelete item={item} />
            </IonItemOptions>
        </>
    )
}

export default ListItem