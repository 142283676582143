import React from "react"
import {IonItem, IonItemGroup, IonItemSliding, IonLabel, IonList} from "@ionic/react";
import {useTranslation} from "react-i18next";

import {useKeys as useItems}     from '../../contexts/KeyContext'
import {Key     as ItemType}     from '../../types/Key'

import {default as ItemSkeleton} from "./../Keys/List/ListItemSkeleton"
import {default as Item}         from "./ListItem"


const List: React.FC = () => {
    const { itemsInUse: items, loadingItemsInUse: loadingItems } = useItems()
    const { t } = useTranslation()

    const skeletonList = () => {
        return [...Array(6)].map((item, index) => {
            return (<IonItemGroup key={index}><ItemSkeleton/></IonItemGroup>);
        })
    }

    const itemList = (items: ItemType[]) => {
        if (items.length === 0 ) {
            return (
                <IonItem>
                    <IonLabel className="ion-text-center">{t('common.no_results_found')}.</IonLabel>
                </IonItem>
            )
        }

        return items.map(
            (item: ItemType) => <IonItemSliding key={item.id}><Item item={item} /></IonItemSliding>
        )
    }

    return (
        <IonList>
            {items === undefined || loadingItems ? skeletonList() : itemList(items)}
        </IonList>
    )
}

export default List