import React from "react"

import {useIonViewWillEnter} from "@ionic/react"

import ItemType from "../../types/Lock"
import {useLocks as useItems} from "../../contexts/LockContext"

import ItemGroupInfo   from "./ItemGroupInfo"
import ItemGroupLinked from "./ItemGroupLinked"
import ViewHeader      from "./ViewHeader"

export interface formProps {
    item: ItemType;
}

const View: React.FC<formProps> = ({ item }) => {

    const { fetchItems, inProgress, syncItem } = useItems()

    useIonViewWillEnter(() => {

        const handle = async () => {
            if (! inProgress && item.options?.isSynchronizable) {
                const newItem = await syncItem(item, true)

                if (newItem && newItem.meta?.isUpdated) {
                    fetchItems('', true)
                }
            }
        }

        handle()
    });

    // useIonViewWillEnter(async () => {
    //
    //     if (! inProgress && item.options?.isSynchronizable) {
    //         const newItem = await syncItem(item, true)
    //
    //         if (newItem && newItem.meta?.isUpdated) {
    //             fetchItems('', true)
    //         }
    //     }
    //
    // });

    return (
        <div className="ion-text-center">
            <ViewHeader      item={item} />
            <ItemGroupInfo   item={item} />
            <ItemGroupLinked item={item} />
        </div>
    )
}

export default View