import React from "react"
import {useTranslation} from "react-i18next"

import {useLocks as useItems}  from "../../contexts/LockContext"

import ItemList from '../../components/Locks/List'
import PageList from "../common/List"

const PageLocksList: React.FC = () => {
    const { t } = useTranslation()

    const { fetchItems } = useItems()

    const
        itemType: string     = 'Locks',
        itemTypeName: string = t('locks.title_list'),
        itemTypeUrl: string  = 'locks'

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            fetchItems={fetchItems}
            useItems={useItems}
        />
    )
}

export default PageLocksList