import React from "react"

import TypeIcon from "./TypeIcon"

import {getIosIcon, getMDIcon} from "../../../types/KeyTypeIcon"

interface componentProps {
    icon?: string;
    link?: string;
    size?: "small" | "default" | "large";
    slot?: string;
    text?: string;
}

const IconKeyInSafe: React.FC<componentProps> = (
    { icon, link, size, slot, text }
) => {
    icon = icon || 'unknown'

    return <TypeIcon
        backgroundColor={'grey'}
        link={link}
        ios={getIosIcon(icon)}
        md={getMDIcon(icon)}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconKeyInSafe