import React from "react"
import {manOutline, manSharp} from "ionicons/icons"

import TypeIcon from "./TypeIcon"

interface props {
    link?: string
    size?: "small" | "default" | "large"
    slot?: string
    text?: string
}

const IconPerson: React.FC<props> = ({ link, size, slot, text }) => {

    return <TypeIcon
        backgroundColor={'purple'}
        link={link}
        ios={manOutline}
        md={manSharp}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconPerson