import React, {CSSProperties} from "react"
import {
    IonContent,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail, useIonViewWillEnter
} from "@ionic/react"

import './List.css'

import ListBar        from "../../components/common/Bars/ListBar"
import ListPageHeader from "../../components/common/Headers/ListPageHeader"

import {useLockingSystems} from "../../contexts/LockingSystemContext"

import SearchBar from "../../components/common/Bars/SearchBar"
import Message from "../../components/common/Message";

interface listProps {
    fetchItems:     (search?: string, force?: boolean) => void;
    ItemList:       any;
    itemType:       string;
    itemTypeName:   string;
    itemTypeUrl:    string;
    showAddButton?: boolean;
    useItems?:      any;
}

const PageList: React.FC<listProps> = ({
    fetchItems, ItemList, itemType, itemTypeName, itemTypeUrl, showAddButton = false, useItems
}) => {

    const { inProgress, closeMessage, message }         = useItems()
    const { selectedLockingSystemId } = useLockingSystems()

    const [
        searchQuery, setSearchQuery
    ] = React.useState<string | undefined>(undefined)
    const [
        lockingSystemId, setLockingSystemId
    ] = React.useState<number | undefined>(selectedLockingSystemId)

    useIonViewWillEnter(() => {
        if (closeMessage !== undefined) closeMessage()
    });

    React.useEffect(() => {
        if (selectedLockingSystemId === undefined) {
            fetchItems()

            return
        }

        const reload: boolean = lockingSystemId !== undefined && lockingSystemId !== selectedLockingSystemId

        fetchItems(searchQuery, reload)

        setLockingSystemId(selectedLockingSystemId)

    }, [selectedLockingSystemId])

    async function handleRefresh (event?: CustomEvent<RefresherEventDetail>) {
        fetchItems(searchQuery, true)

        setTimeout(() => {
            if (event?.detail?.complete) event?.detail?.complete()
        }, 400)
    }

    const handleSearch = async (ev: Event) => {
        let query: string = '';
        const target = ev.target as HTMLIonSearchbarElement;
        if (target) query = target.value!.toLowerCase();

        setSearchQuery(query)

        fetchItems(query, true)
    }

    let style: CSSProperties = inProgress ? { 'opacity': 0.4, 'filter': 'grayscale(100%)' } : {}

    const listBar = () => {
        return (itemType === 'LockingSystems' && selectedLockingSystemId)
            ? <SearchBar handleSearch={handleSearch} pageName={itemTypeName} />
            : <ListBar   handleSearch={handleSearch} pageName={itemTypeName} itemType={itemType} />
    }

    return (
        <IonPage className="page-list">
            <ListPageHeader
                handleRefresh={handleRefresh}
                itemType={itemType} itemTypeName={itemTypeName} itemTypeUrl={itemTypeUrl}
                showAddButton={showAddButton}
            />
            <IonContent forceOverscroll={false} fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent />
                </IonRefresher>

                <div className="pageContentContainer">
                    <div className="maxWidth" style={style}>
                        {inProgress && <IonProgressBar type="indeterminate"></IonProgressBar>}
                        {message && <Message message={message} onClose={closeMessage} />}
                        {! message && listBar()}
                        <ItemList />
                    </div>
                </div>

            </IonContent>
        </IonPage>
    )
}

export default PageList