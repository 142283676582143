import React from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
  IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonFooter,
} from '@ionic/react';
import {
  keyOutline, keySharp,
  lockClosedOutline, lockClosedSharp,
  gridOutline, gridSharp,
  cubeOutline, cubeSharp,
  expandOutline, expandSharp,
  bookmarkOutline, bookmarkSharp,
  shieldCheckmarkOutline, shieldCheckmarkSharp,
  personCircleOutline, personCircleSharp, manOutline, manSharp,
  banOutline, banSharp
} from 'ionicons/icons';

import './Menu.css';

import LanguageSelector   from "./LanguageSelector";

import {useLockingSystems} from "../../contexts/LockingSystemContext"

interface MenuItem {
  url:     string;
  iosIcon: string;
  mdIcon:  string;
  title:   string;
  line?:   "none" | "full" | "inset" | undefined;
  needsFeature?: string;
}

const menuItems: MenuItem[] = [
  {
    title: 'dashboard.title',
    url: '/dashboard',
    iosIcon: gridOutline,
    mdIcon: gridSharp,
    line: 'full'
  },
  {
    title: 'locking-systems.title_list',
    url: '/locking-systems',
    iosIcon: bookmarkOutline,
    mdIcon: bookmarkSharp,
    line: 'full',
  },
  {
    title: 'keys.keyGroup.keys_in_use.title_list',
    url: '/keys-in-use',
    iosIcon: keyOutline,
    mdIcon: keySharp,
    line: 'none'
  },
  {
    title: 'keys.keyGroup.keys_in_safe.title_list',
    url: '/keys-in-safe',
    iosIcon: cubeOutline,
    mdIcon: cubeSharp,
    line: 'none',
    needsFeature: 'HAS_KEY_SAFE',
  },
  {
    title:   'keys.keyGroup.blocklist.title_list',
    url:     '/blocklist-keys',
    iosIcon: banOutline,
    mdIcon:  banSharp,
    line:    'full',
    needsFeature: 'HAS_KEY_BLOCKLIST',
  },
  {
    title: 'locks.title_list',
    url: '/locks',
    iosIcon: lockClosedOutline,
    mdIcon: lockClosedSharp,
    line: 'inset'
  },
  {
    title: 'people.title_list',
    url:    '/people',
    iosIcon: manOutline,
    mdIcon:  manSharp,
    line:    'inset'
  },
  {
    title:   'locking-system-permissions.title_list',
    url:     '/locking-system-permissions',
    iosIcon: shieldCheckmarkOutline,
    mdIcon:  shieldCheckmarkSharp,
    line:    'inset',
  },
  {
    title: 'locking-system-zones.title_list',
    url: '/locking-system-zones',
    iosIcon: expandOutline,
    mdIcon: expandSharp,
    line: 'full'
  },
  {
    title:   'users.title_list',
    url:     '/users',
    iosIcon: personCircleOutline,
    mdIcon:  personCircleSharp,
    line:    'none'
  }
];

const Menu: React.FC = () => {

  const location              = useLocation()
  const { t } = useTranslation()

  const { hasFeature: hasLockingSystemFeature } = useLockingSystems()

  const menuRef  = React.useRef<HTMLIonMenuElement>(null)

  const locationPathParts: string[] = location.pathname.split('/')
  const locationPathMain: string    = '/' + locationPathParts[1] || ''

  return (
    <IonMenu ref={menuRef} contentId="main" type="overlay" style={{ 'maxWidth': '300px' }}>
      <IonContent forceOverscroll={false}>

        <IonList id="inbox-list">
          {menuItems.filter((menuItem) => {
              return (menuItem.needsFeature && ! hasLockingSystemFeature(menuItem.needsFeature)) ? false : true
          }).map((menuItem, index) => {

            // if (menuItem.needsFeature) {
            //   const hasFeature = hasLockingSystemFeature(menuItem.needsFeature)
            //
            //   if (! hasFeature) {
            //     return
            //   }
            // }

            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={locationPathMain === menuItem.url ? 'selected' : ''}
                  routerLink={menuItem.url}
                  lines={menuItem.line} detail={false}>
                  <IonIcon slot="start" ios={menuItem.iosIcon} md={menuItem.mdIcon} />
                  <IonLabel>{t(menuItem.title)}</IonLabel>
                  {/*<IonBadge slot="end" style={{ 'marginRight': 0, 'backgroundColor': 'transparent', 'color': '#ddd', 'fontSize': '14px' }}>10</IonBadge>*/}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        {/*<IonAccordionGroup multiple={true} value="['keys']">*/}
        {/*  <IonAccordion value="keys">*/}
        {/*    <IonItem slot="header">*/}
        {/*      <IonLabel>Sleutels</IonLabel>*/}
        {/*    </IonItem>*/}
        {/*    <IonList slot="content" className="ion-padding">*/}
        {/*      <IonItem>*/}
        {/*        <IonLabel>Sleutels 1</IonLabel>*/}
        {/*      </IonItem>*/}
        {/*      <IonItem>*/}
        {/*        <IonLabel>Sleutels 2</IonLabel>*/}
        {/*      </IonItem>*/}
        {/*    </IonList>*/}
        {/*  </IonAccordion>*/}
        {/*</IonAccordionGroup>*/}

        {/*<IonList id="labels-list">*/}
        {/*  <IonListHeader>Overig</IonListHeader>*/}
        {/*  {labels.map((label, index) => (*/}
        {/*    <IonItem lines="none" key={index}>*/}
        {/*      <IonIcon slot="start" icon={chevronForwardOutline} />*/}
        {/*      <IonLabel>{label}</IonLabel>*/}
        {/*    </IonItem>*/}
        {/*  ))}*/}
        {/*</IonList>*/}

      </IonContent>
      <IonFooter className="ion-text-center ion-padding-vertical">
        <LanguageSelector color="bc-yellow" />

        <div className="ion-text-center" style={{ 'color': '#999999' }}>

          <p style={{ 'fontSize': '12px' }}>
            &copy; 2022-{new Date().getFullYear()}, {process.env.REACT_APP_BRAND_NAME}
          </p>
          <p style={{ 'fontSize': '11px' }}>
            v{process.env.REACT_APP_VERSION}
            {process.env.REACT_APP_ENV !== 'live' &&
                <span>&nbsp;- {process.env.REACT_APP_ENV}</span>
            }
          </p>

        </div>
      </IonFooter>
    </IonMenu>
  )
}

export default Menu