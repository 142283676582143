import React from "react"

type componentProps = {
    icon:     JSX.Element;
    title:    string;
    subTitle: string;
}

const ItemViewHeader: React.FC<componentProps> = ({icon, title, subTitle}) => {

    return (
        <div className="ion-padding">
            <p>{icon}</p>
            <h3>{title}</h3>
            {subTitle && <p style={{ 'color': 'var(--ion-color-medium)' }}>{subTitle}</p>}
        </div>
    )
}

export default ItemViewHeader