import React from 'react'
import {useTranslation} from "react-i18next"

import {IonIcon, IonItemOption, useIonAlert}    from "@ionic/react"
import {returnUpBackOutline, returnUpBackSharp} from "ionicons/icons"


import KeyType from "../../../types/Key"

import {useKeys as useItems} from "../../../contexts/KeyContext"

interface props { item: KeyType }


const ListItemOptionReturn: React.FC<props> = ({item}) => {
    const { returnKeyPhone } = useItems()
    const [presentAlert]     = useIonAlert()
    const { t }              = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertReturn = async () => {
        return presentAlert({
            header:  t('keys.alerts.return.header'),
            message: t('keys.alerts.return.message'),
            buttons: [{
                text: t('common.cancel'), role: 'cancel',
                handler: () => onCancel(),
            }, {
                text: t('keys.form.return_key'), role: 'confirm',
                handler: () => onReturn(),
            },],
        })
    }

    const onCancel = () => { closeItemSliding() }

    const onReturn = () => {
        closeItemSliding()
        if (item.keyPhone) returnKeyPhone(item.keyPhone)
    }

    return (
        <IonItemOption color="tertiary" onClick={() => alertReturn()}>
            <IonIcon slot="top" ios={returnUpBackOutline} md={returnUpBackSharp} />
            {t('keys.form.return_key')}
        </IonItemOption>
    )
}

export default ListItemOptionReturn