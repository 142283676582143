import React from "react"

import {IonItem, IonLabel, IonList} from "@ionic/react"

import KeyType from "../../types/Key"

import IconKeyInUse from "../common/Icons/KeyInUse"
import LockingSystemZone from "../../types/LockingSystemZone";
import IconKeyInSafe from "../common/Icons/KeyInSafe";
import IconBlocklistKey from "../common/Icons/BlocklistKey";
import {useTranslation} from "react-i18next";
import ItemSegmentListHeader from "../common/Headers/ItemSegmentListHeader";

interface componentProps {
    item: LockingSystemZone | undefined;
    keys: KeyType[] | undefined;
}

const List: React.FC<componentProps> = ({ item, keys }) => {

    const { t } = useTranslation()

    const title: string    = item?.name || '-'
    const subTitle: string = item?.description || '(' + t('common.no_description') + ')'

    return (
        <IonList lines="full" className="maxWidth">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />

            {keys === undefined || keys?.length === 0 ? (
                <IonItem className="ion-text-center">
                    <IonLabel className="ion-text-wrap">
                        {t('locking-system-zones.no_keys_for_zone')}.
                    </IonLabel>
                </IonItem>
            ) : (
                keys.map( (key: KeyType) => {
                    let
                        icon: JSX.Element = <IconKeyInSafe icon={key?.keyType?.icon} slot="start" />,
                        link: string = '/keys-in-safe/' + key.id + '/view'

                    if (key.blocklistKey?.id) {
                        icon = <IconBlocklistKey icon={key?.keyType?.icon} slot="start" />
                        link = '/blocklist-keys/' + key.blocklistKey.id + '/view'
                    } else if (key.personId) {
                        icon = <IconKeyInUse icon={key?.keyType?.icon} slot="start" />
                        link = '/keys-in-use/' + key.id + '/view'
                    }

                    return (
                        <IonItem button detail key={'keys-' + key.id} lines="inset"
                                 routerLink={link}>
                            {icon}
                            <IonLabel>
                                <h2>{key.description}</h2>
                                <p style={{ 'textTransform': 'uppercase' }}>
                                    {key.keyType?.lockingSystemType?.brand?.name} - {key.keyType?.name}
                                </p>
                            </IonLabel>
                        </IonItem>
                    )
                })
            )}
        </IonList>
    )
}

export default List