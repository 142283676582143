import React from "react"
import {useTranslation} from "react-i18next"

import {useKeys as useItems}   from "../../contexts/KeyContext"

import ItemList from '../../components/BlocklistKeys/List'
import PageList from "../common/List"


const PageBlocklistKeysList: React.FC = () => {
    const { t } = useTranslation()

    const
        itemType: string     = 'blocklistKeys',
        itemTypeName: string = t('keys.keyGroup.blocklist.title_list'),
        itemTypeUrl: string  = 'blocklist-keys'

    const { fetchItemsOnBlocklist } = useItems()

    // const { fetchItemsInUse, fetchItemsInSafe, fetchItemsOnBlocklist } = useItems()
    //
    // React.useEffect(() => {
    //     fetchItemsInUse()
    //     fetchItemsInSafe()
    // })

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            fetchItems={fetchItemsOnBlocklist}
            useItems={useItems}
        />
    )
}

export default PageBlocklistKeysList