import React from 'react'
import {useHistory} from "react-router"

import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel} from "@ionic/react"
import {
    keyOutline, keySharp, link, lockClosedOutline, lockClosedSharp
} from "ionicons/icons";

import {LockingSystemPermission as ItemType} from "../../types/LockingSystemPermission"

import ListItemOptionDelete from "./ListItemOptionDelete"
import IconLockingSystemPermission from "../common/Icons/LockingSystemPermission"
import BadgeLockingSystemPermissionType from "../common/Badges/BadgeLockingSystemPermissionType";
import {useTranslation} from "react-i18next";

interface itemProps {
    item: ItemType;
}

const ListItem: React.FC<itemProps> = ({ item}) => {

    const { t } = useTranslation()

    const
        history = useHistory(),
        keysCount: number  = item.keys?.length  || 0,
        locksCount: number = item.locks?.length || 0

    const goToSegment = (ev: any, itemId: number, segment: string) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push('/locking-system-permissions/' + itemId + '/' + segment)
    }

    const itemBadge = () => {
        if (item.lockingSystemPermissionType) {
            return (<BadgeLockingSystemPermissionType item={item.lockingSystemPermissionType} />)
        }
    }

    return (
        <>
            <IonItem button className={item.isHiddenByDefault ? 'hidden-by-default' : ''}
                     detail={true} routerLink={"/locking-system-permissions/" + item.id + '/view'} slot="start">
                <IconLockingSystemPermission slot="start" />
                <IonLabel>
                    {item.name}
                    {item.lockingSystemZone &&
                        <span style={{ 'fontWeight': 200 }}>
                        &nbsp;- {item.lockingSystemZone.name}
                    </span>
                    }
                    <p>{item.description || <i>{'(' + t('common.no_description') + ')'}</i>}</p>
                </IonLabel>
                {itemBadge()}
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton color="medium" fill="clear"
                               onClick={ (ev) => goToSegment(ev, item.id, 'keys') }
                               size="large" style={{ 'fontSize': '17px' }}
                    >
                        {keysCount ? keysCount : '-'}
                        <IonIcon slot="end" color="bc-blue" ios={keyOutline} md={keySharp} />
                    </IonButton>
                    <IonButton color="medium" fill="clear"
                               onClick={ (ev) => goToSegment(ev, item.id, 'locks') }
                               size="large" style={{ 'fontSize': '17px', 'minWidth': '73px' }}
                    >
                        {(locksCount === 1 && item.individualLockId) ? (
                            <IonIcon color="bc-yellow" icon={link} />
                        ) : (
                            <span style={{ 'minWidth': '23px' }}>&nbsp;{locksCount ? locksCount : ' -'}</span>
                        )}
                        <IonIcon slot="end" color="bc-blue" ios={lockClosedOutline} md={lockClosedSharp} />
                    </IonButton>
                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                {(item.options?.isRemovableFromKey && keysCount === 0 && locksCount === 0) && <ListItemOptionDelete item={item} />}
            </IonItemOptions>
        </>
    )
}

export default ListItem