import React from "react"
import {RouteComponentProps} from "react-router"
import {useTranslation} from "react-i18next"

import {useKeys as useItems} from "../../contexts/KeyContext"

import ItemForm from "../../components/Keys/Form"
import PageItem from "../common/Item"
import ItemView from "../../components/KeysInSafe/View"
import LockingSystemPermissionsList from "../../components/Keys/KeyLockingSystemPermissions/List"
import NotFound from "../../components/KeysInSafe/NotFound";

interface pageProps extends RouteComponentProps<{
    id:      string;
    segment: string;
}> {}


const PageKeysInSafeItem: React.FC<pageProps> = ({ match }) => {
    const { t }        = useTranslation()
    const { itemsInSafe: items, fetchItemsInSafe: fetchItems } = useItems()

    const
        itemType:     string = 'KeysInSafe',
        itemTypeName: string = t('keys.keyGroup.keys_in_safe.title_item'),
        itemTypeUrl:  string = 'keys-in-safe'

    return (
        <PageItem
            useItems={useItems}
            fetchItems={fetchItems}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            ItemForm={ItemForm}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            LockingSystemPermissionsList={LockingSystemPermissionsList}
            segment={match.params.segment}
        />
    )
}

export default PageKeysInSafeItem