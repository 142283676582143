import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup} from "@ionic/react"

import Person from "../../../types/Person"


export interface componentProps {
    item: Person
}

const ItemGroupInfo: React.FC<componentProps> = ( {item} ) => {

    const {t} = useTranslation()

    return (

        <IonItemGroup>
            <IonItemDivider>
                {t('people.form.address')}
            </IonItemDivider>
            <IonItem lines="full"
                     style={{ 'lineHeight': 1.5, '--padding-top': '12px', '--padding-bottom': '12px' }}>
                {item.address}<br />
                {item.zipcode} {item.city}<br />
                {item.country}
            </IonItem>
        </IonItemGroup>

    )
}

export default ItemGroupInfo