import React from "react"

interface badgeProps {
    cssClass: string;
    label:    string;
    count?:   number;
}

const BadgeMessage: React.FC<badgeProps> = ({cssClass, label, count = 0}: badgeProps) => {
    return (
        <span className={"message-badge " + cssClass}>
            {label}
            {count > 0 && <strong>&nbsp;{count}</strong>}
        </span>
    )
}

export default BadgeMessage