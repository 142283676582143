import React                 from "react"
import {Redirect, Route} from "react-router-dom"
import {RouteComponentProps} from "react-router"

import {IonPage, IonRouterOutlet} from "@ionic/react";

import PageItem from "../pages/Locks/Item"
import PageList from "../pages/Locks/List"

const Locks: React.FC<RouteComponentProps> = ({ match }) => {
    return (
        <IonPage>
            <IonRouterOutlet animated={false}>
                <Route exact path={match.url}                   component={PageList} />
                <Route       path={`${match.url}/:id/:segment`} component={PageItem} />

                <Redirect exact from={`${match.url}/:id`} to={`${match.url}/:id/view`} />
            </IonRouterOutlet>
        </IonPage>
    )
}

export default Locks