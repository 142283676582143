import React                from "react"
import {useHistory}         from "react-router"
import {useTranslation}     from "react-i18next"

import {IonList, IonRow, IonCol, IonGrid, IonAccordionGroup} from "@ionic/react"

import {Person as ItemType}    from "../../types/Person"
import {usePeople as useItems} from "../../contexts/PersonContext"
import {useAuth}               from "../../contexts/AuthContext"
import {useKeys}               from "../../contexts/KeyContext"
import {useLockingSystems}     from "../../contexts/LockingSystemContext"
import LockingSystem           from "../../types/LockingSystem"

import FormAccordionLockingSystem from "./Form/AccordionLockingSystem"
import FormAccordionPerson        from "./Form/AccordionPerson"
import FormAccordionOther         from "./Form/AccordionOther"
import FormAccordionLinkKey       from "./Form/AccordionLinkKey"
import FormAccordionLinkLockingSystemPermissions from "./Form/AccordionLinkLockingSystemPermissions"
import FormButtonSaveChanges      from "../common/Buttons/FormButtonSaveChanges"
import FormButtonSaveNew          from "../common/Buttons/FormButtonSaveNew"

interface formProps {
    item: ItemType | undefined;
}

const Form: React.FC<formProps> = ({ item }) => {

    const { t } = useTranslation()

    const { addItem, editItem, message } = useItems()
    const { fetchItemsInUse: fetchKeysInUse, fetchItemsInSafe: fetchKeysInSafe } = useKeys()
    const { selectedLockingSystemId }    = useLockingSystems()

    const initialFormData: ItemType = {
        id:                  item?.id          || 0,
        address:             item?.address     || '',
        city:                item?.city        || '',
        companyName:         item?.companyName || '',
        contactInfo:         item?.contactInfo || '',
        country:             item?.country     || '',
        firstName:           item?.firstName   || '',
        lastName:            item?.lastName    || '',
        email:               item?.email       || '',
        lockingSystemId:     item?.lockingSystemId     || selectedLockingSystemId || 0,
        lockingSystemZoneId: item?.lockingSystemZoneId || 0,
        phone1:              item?.phone1  || '',
        zipcode:             item?.zipcode || '',
        linkKey:             'none',
        linkKeyId:           0,
        linkLockingSystemPermissionIds: [],
        keys:                null
    }

    const { userInfo  } = useAuth()
    const history       = useHistory()

    const
        [formData, setFormData] = React.useState(initialFormData),
        accordionGroupRef = React.useRef<null | HTMLIonAccordionGroupElement>(null),
        [
            lockingSystem, setLockingSystem
        ] = React.useState<LockingSystem | undefined>(undefined)

    const updateFormHandler = React.useCallback(
        (type: any) => (event: any) => {
            setFormData({ ...formData, [type]: event.target.value })
        }, [formData]
    )

    React.useEffect(() => {

        setLockingSystem( userInfo?.lockingSystems?.find(item => item.id === selectedLockingSystemId) )

        if (! item && formData.lockingSystemId === 0) {
            setFormData({ ...formData, lockingSystemId: selectedLockingSystemId || 0 })
        }

    }, [item, selectedLockingSystemId])

    React.useEffect(() => {

        setFormData(initialFormData)

        if (! accordionGroupRef.current) return

        accordionGroupRef.current.value = item
            ? ['person']
            : ['lockingSystem', 'person', 'link_key', 'link_locking_system_permissions', ]
    }, [item])


    const handleSave = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        if (item && item.id) {
            const result = await editItem(formData)

            if (result !== undefined || message !== undefined) {
                if (result) {
                    history.push('/people/' + formData.id + '/view')

                    return
                }
            }

        } else {

            const newItem: ItemType = {...formData} // , linkKeyId: 0

            delete newItem.linkKeyId
            delete newItem.linkLockingSystemPermissionIds

            if (formData.linkKey === 'keyPhone') {
                newItem.keys = [{
                    id: 0,
                    description: t('common.phone_from') + ' ' + formData.firstName + ' ' + formData.lastName,
                    keyTypeId:            111, // iloq s50 keyPhone
                    lockingSystemId:      formData.lockingSystemId,
                    lockingSystemPermissions: formData.linkLockingSystemPermissionIds?.map(
                        (lspId) => { return {id: lspId} }),
                    lockingSystemZoneIds: [formData.lockingSystemZoneId],
                    keyPhone: {
                        id: 0,
                        email: formData.email,
                        keyId: 0,
                        phoneNo: formData.phone1 || ''
                    },
                }]

            } else if (formData.linkKey === 'keyFromSafe' && formData.linkKeyId) {
                newItem.keys = [{
                    id: formData.linkKeyId,
                    lockingSystemPermissions: formData.linkLockingSystemPermissionIds?.map((lspId) => {
                        return {id: lspId}
                    })
                }]

            } else {
                newItem.keys = null
            }

            const newPerson = await addItem(newItem)

            if (newPerson) {
                fetchKeysInUse('', true)
                if (formData.linkKey === 'keyFromSafe') fetchKeysInSafe('', true)

                history.push('/people/' + newPerson.id + '/view')
            }
        }
    }

    const FormKeyUp = (e: any) => {
        if (e.key === 'Enter') handleSave(e);
    }

    return (
            <IonList className="maxWidth">
            <form method="POST" id="formPerson" onSubmit={handleSave} onKeyUp={ e => FormKeyUp(e) }>

            <IonAccordionGroup multiple={true} ref={accordionGroupRef}>

                {! item &&
                    <FormAccordionLockingSystem formData={formData} setFormData={setFormData}
                                                lockingSystem={lockingSystem}
                                                updateFormHandler={updateFormHandler} />
                }
                <FormAccordionPerson formData={formData} updateFormHandler={updateFormHandler} />
                <FormAccordionOther  formData={formData} updateFormHandler={updateFormHandler} />
                {! item &&
                    <FormAccordionLinkKey formData={formData} updateFormHandler={updateFormHandler} />
                }
                {! item &&
                    ((formData.linkKey === 'keyFromSafe' && (formData.linkKeyId || 0) > 0)
                        || formData.linkKey === 'keyPhone') &&
                    <FormAccordionLinkLockingSystemPermissions formData={formData}
                                                               setFormData={setFormData} />
                }

            </IonAccordionGroup>

            <IonGrid className="ion-margin-top">
                <IonRow>
                    <IonCol className="ion-text-right">
                        {item?.id ? <FormButtonSaveChanges /> : <FormButtonSaveNew />}
                    </IonCol>
                </IonRow>
            </IonGrid>

            </form>
            </IonList>
    )
}

export default Form