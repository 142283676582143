import React from "react";
import {IonItem, IonLabel, IonList, useIonAlert} from "@ionic/react"

import KeyType                       from "../../types/Key"
import LockingSystemPermission       from "../../types/LockingSystemPermission"

import IconKeyInUse from "../common/Icons/KeyInUse"
import BadgeKeyProgrammingState from "../common/Badges/BadgeKeyProgrammingState";
import ItemSegmentListHeader from "../common/Headers/ItemSegmentListHeader";

interface componentProps {
    item: LockingSystemPermission | undefined;
    keys: KeyType[] | undefined;
}

const List: React.FC<componentProps> = ({ item, keys }) => {

    const title: string    = item?.name + (item?.description ? ' - ' + item?.description : '')
    const subTitle: string = item?.lockingSystemPermissionType?.name || ''

    return (
        <IonList lines="full">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />

            {keys === undefined || keys?.length === 0 ? (
                <IonItem className="ion-text-center">
                    <IonLabel className="ion-text-wrap">
                        Geen sleutels met dit recht erop gevonden.
                    </IonLabel>
                </IonItem>
            ) : (
                keys.map( (key: KeyType) => {

                    return (
                        <IonItem button detail key={'key-' + key.id} lines="inset"
                                 routerLink={'/keys-in-use/' + key.id + '/view'}>
                            <IconKeyInUse icon={key?.keyType?.icon} slot="start" />
                            <IonLabel>
                                <h2>{key.description}</h2>
                                <p style={{ 'textTransform': 'uppercase' }}>
                                    {key.keyType?.lockingSystemType?.brand?.name} - {key.keyType?.name}
                                </p>
                            </IonLabel>
                            {key.keyProgrammingState &&
                                <BadgeKeyProgrammingState item={key.keyProgrammingState} />
                            }
                        </IonItem>
                    )
                })
            )}
        </IonList>
    )

}

export default List