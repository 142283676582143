import React from "react"
import {IonButton, IonIcon, IonText} from "@ionic/react"
import {closeOutline, closeSharp} from "ionicons/icons"

export type MessageType = 'blank' | 'error' | 'info' | 'notice' | 'success' | 'warning'

export interface MessageInterface {
    text:   string,
    title?: string,
    type:   MessageType
}

interface MessageProps {
    message: MessageInterface,
    onClose?: () => void
}

const Message: React.FC<MessageProps> = ({ message, onClose }) => {

    return (
        <div className={"ion-padding message message-" + message.type}>
            <IonText>
                {message.text || '?'}
            </IonText>
            {onClose !== undefined &&
            <IonButton fill="clear" onClick={ () => onClose() }
                       style={ {'color': 'white', 'float': 'right' }} size="small">
                <IonIcon slot="icon-only" ios={closeSharp} md={closeOutline} />
            </IonButton>
            }
        </div>
    )
}

export default Message