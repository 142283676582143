import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import Key              from "../../../types/Key"
import BlocklistKeyType from "../../../types/BlocklistKey"

export interface props {
    blocklistKeyItem?: BlocklistKeyType
    keyItem:           Key
}

const ItemGroupInfo: React.FC<props> = (
    {blocklistKeyItem, keyItem}
) => {

    const { t } = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.info')}</IonItemDivider>
            {blocklistKeyItem &&
            <IonItem lines="full" button={false}>
                <IonLabel>{t('keys.form.blocked_on')}</IonLabel>
                <IonNote slot="end">{blocklistKeyItem.blocklistedAt}</IonNote>
            </IonItem>
            }
            {keyItem.label &&
            <IonItem lines="full">
                <IonLabel>{t('keys.form.label')}</IonLabel>
                <IonNote slot="end">{keyItem.label || '-'}</IonNote>
            </IonItem>
            }
            <IonItem lines="full">
                <IonLabel>{t('keys.form.programming_state')}</IonLabel>
                <IonNote slot="end">{keyItem.keyProgrammingState?.name || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('keys.form.serial_number')}</IonLabel>
                <IonNote slot="end">{keyItem.serial || '-'}</IonNote>
            </IonItem>

            <IonItem lines="full">
                <IonLabel>{t('common.synced_at')}</IonLabel>
                <IonNote slot="end">{keyItem.meta?.syncedAt || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )

}

export default ItemGroupInfo