import React from "react"

import LockingSystemZone from "../../types/LockingSystemZone"

import ViewHeader from "./ViewHeader";
import ViewItemGroupInfo from "./VIewItemGroupInfo";
import ViewItemGroupLinked from "./ViewItemGroupLinked";

export interface props {
    item: LockingSystemZone
}

const View: React.FC<props> = ({ item }) => {

    return (
        <div className="ion-text-center">
            <ViewHeader          item={item} />
            <ViewItemGroupInfo   item={item} />
            <ViewItemGroupLinked item={item} />
        </div>
    )

}

export default View