export interface SyncCollectionItem {
    key:           string;
    name:          string;
    sub?:          boolean;
    url:           string;
    needsFeature?: string;
}

export const SyncCollections: SyncCollectionItem[] = [
    {
        key:  'lockingSystemZones',
        name: 'locking-system-zones.title_list',
        url:  'locking-system-zones',
    },
    {
        key:  'people',
        name: 'people.title_list',
        url:  'people',
    },
    {
        key:  'lockingSystemPermissions',
        name: 'locking-system-permissions.title_list',
        url:  'locking-system-permissions',
    },
    {
        key:  'keys',
        name: 'keys.title_list',
        url:  'keys-in-use',
    },
    {
        key:  'keyLockingSystemPermissions',
        name: 'locking-system-permissions.permissions_for_keys',
        sub:  true,
        url:  'locking-system-permissions',
    },
    {
        key:  'blocklistKeys',
        name: 'keys.keyGroup.blocklist.title_list',
        sub:  true,
        url:  'blocklist-keys',
        needsFeature: 'HAS_KEY_BLOCKLIST',
    },
    {
        key: 'keyPhones',
        name: 'keyPhones.title_list',
        sub:  true,
        url:  'keys-in-use',
        needsFeature: 'CAN_HAVE_PHONE_KEYS',
    },
    {
        key:  'keyLockingSystemZones',
        name: 'locking-system-zones.zones_for_keys',
        sub:  true,
        url:  'locking-system-zones'
    },
    {
        key:  'locks',
        name: 'locks.title_list',
        url:  'locks',
    },
    {
        key:  'lockLockingSystemPermissions',
        name: 'locking-system-permissions.permissions_for_locks',
        sub:  true,
        url:  'locking-system-permissions',
    },
]