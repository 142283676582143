import React from 'react'
import {
    IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonGrid, IonRow, IonText
} from "@ionic/react";
import {useTranslation} from "react-i18next";

import DashboardCardType from "../../types/DashboardCard";
import {useLockingSystems} from "../../contexts/LockingSystemContext";
import {useHistory} from "react-router";

interface DashboardCardProps {
    dashboardCard: DashboardCardType;
    lockingSystemId: number;
}


const Card: React.FC<DashboardCardProps> = ({ dashboardCard, lockingSystemId }) => {
    const history = useHistory()
    const { t }   = useTranslation()
    const { changeSelectedItemId } = useLockingSystems()

    const goTo = (ev: any, url: string) => {
        ev.preventDefault()

        changeSelectedItemId( lockingSystemId )

        history.push(url)
    }

    return (
        <IonCard style={{ 'minWidth': '130px', 'margin': '0' }}
                 onClick={(ev) => goTo(ev, dashboardCard.url)}>
            <IonCardHeader>
                <IonGrid style={{ 'width': '100%' }} className="ion-no-padding">
                    <IonRow>
                        <IonCol size="6">{dashboardCard.icon}</IonCol>
                        <IonCol size="6" className="ion-text-right" style={{ 'fontSize': '24px' }}>
                            <IonText color="dark">{dashboardCard.count || '-'}</IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardHeader>
            <IonCardContent style={{ 'minHeight': '55px' }}>
                <IonCardSubtitle>
                    {t(dashboardCard.title)}
                </IonCardSubtitle>
            </IonCardContent>
        </IonCard>
    )
}

export default Card