import React from "react"

// import {IonCol, IonGrid, IonRow} from "@ionic/react"

import {User as ItemType} from "../../types/User"

// import FormButtonDelete     from "../People/FormButtonDelete"
import ViewHeader           from "./View/Header"
import ViewItemGroupInfo    from "./View/ItemGroupInfo"
import ViewItemDataSource   from "./View/ItemGroupDataSource";

export interface formProps {
    item: ItemType
}

const View: React.FC<formProps> = ({ item }) => {

    // const formButtons = () => {
    //     if (item !== undefined) {
    //         // return (<>
    //         //     {item.options?.isDeletable && <FormButtonDelete item={item} />}
    //         // </>)
    //     }
    // }

    return (
        <div className="ion-text-center">
            <ViewHeader        item={item} />
            <ViewItemGroupInfo item={item} />
            {(item.dataSourceId && item.sourceItemId) &&
                <ViewItemDataSource item={item} />
            }

            {/*<IonGrid className="ion-margin-top">*/}
            {/*    <IonRow>*/}
            {/*        <IonCol className="ion-text-right">*/}
            {/*            {formButtons()}*/}
            {/*        </IonCol>*/}
            {/*    </IonRow>*/}
            {/*</IonGrid>*/}
        </div>
    )
}

export default View