import React from "react"

import {IonButton, IonIcon} from "@ionic/react"

interface iconProps {
    backgroundColor: string
    link?:           string
    ios:             string
    md:              string
    size?:           "small" | "default" | "large"
    slot?:           string
    text?:           string
}
const TypeIcon = ({backgroundColor, link, ios, md, size, slot, text}: iconProps) => {
    let content: string | JSX.Element = ''

    if (text !== undefined && text !== "") {
        const textParts: string[] = text.split(' ')

        textParts.forEach( (p, i) => {
            if (i < 3 || i === textParts.length - 1) {
                content += i === 0 ? p.charAt(0).toUpperCase() : p.charAt(0)
            }
        })
    } else {
        const iconStyle = {'fontSize': '20px'}

        content = <IonIcon size={size || 'default'} slot="icon-only" ios={ios} md={md} style={iconStyle} />
    }

    const
        buttonStyle = {
            '--background': backgroundColor,
            '--background-activated': backgroundColor,
            '--background-hover': backgroundColor,
            '--border-radius': '4px',
            '--color': 'white',
            '--padding-start': '8px',
            '--padding-end': '8px',
            'minHeight': '36px',
            'fontSize': '10px',

            'marginTop': 0,
            'marginBottom': 0,
        }

    return (
        <IonButton className="typeIcon" routerLink={link}
            size={size || 'default'} slot={slot || ''} style={buttonStyle}>
            {content}
        </IonButton>
    )
}

export default TypeIcon