import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import LockingSystemZone from "../../types/LockingSystemZone"


export interface props {
    item: LockingSystemZone
}

const ViewItemGroupLinked: React.FC<props> = ({item} ) => {

    const {t} = useTranslation()

    const
        keysCount: number  = item !== undefined ? item.keys?.length  || 0 : 0,
        locksCount: number = item !== undefined ? item.locks?.length || 0 : 0,
        lockingSystemPermissionsCount: number = item !== undefined ? item.lockingSystemPermissions?.length || 0 : 0

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            <IonItem lines="full" button={keysCount > 0} detail={true}
                     routerLink={'/locking-system-zones/' + item?.id + '/keys'}>
                <IonLabel>{t('keys.title_list')}</IonLabel>
                <IonNote slot="end">{keysCount || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full" button={locksCount > 0} detail={locksCount > 0}
                     routerLink={'/locking-system-zones/' + item?.id + '/locks'}>
                <IonLabel>{t('locks.title_list')}</IonLabel>
                <IonNote slot="end">{locksCount || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full" button={lockingSystemPermissionsCount > 0}
                     detail={lockingSystemPermissionsCount > 0}
                     routerLink={'/locking-system-zones/' + item?.id + '/lockingSystemPermissions'}>
                <IonLabel>{t('locking-system-permissions.title_list')}</IonLabel>
                <IonNote slot="end">{lockingSystemPermissionsCount || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ViewItemGroupLinked