import React from "react"
import {RouteComponentProps} from "react-router"

import {useTranslation} from "react-i18next"

import {useKeys as useItems}        from "../../contexts/KeyContext"

import ItemView                     from "../../components/BlocklistKeys/View"
import List                         from "../../components/Keys/KeyLockingSystemPermissions/List"
import LockingSystemZonesList       from "../../components/Keys/KeyLockingSystemZones/List"
import PageItem                     from "../common/Item"
import NotFound                     from "../../components/BlocklistKeys/NotFound"


interface pageProps extends RouteComponentProps<{
    id: string;
    segment: string;
}> {}


const PageBlocklistKeyItem: React.FC<pageProps> = ({ match }) => {
    const { t }        = useTranslation()
    const { itemsOnBlocklist: items, fetchItemsOnBlocklist: fetchItems } = useItems()

    const
        itemType:     string = 'BlocklistKeys',
        itemTypeName: string = t('keys.keyGroup.blocklist.title_item'),
        itemTypeUrl:  string = 'blocklist-keys'

    return (
        <PageItem
            useItems={useItems}
            fetchItems={fetchItems}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            LockingSystemPermissionsList={List}
            LockingSystemZonesList={LockingSystemZonesList}
            segment={match.params.segment}
        />
    )
}

export default PageBlocklistKeyItem