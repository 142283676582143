import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound                from "../common/Items/ItemNotFound"
import IconLockingSystemPermission from "../common/Icons/LockingSystemPermission"


const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/locking-system-permissions'
    const icon: JSX.Element = <IconLockingSystemPermission link={link} size="large" />

    return (
        <ItemNotFound icon={icon} link={link} subTitle={t('locking-system-permissions.not_found')} />
    )
}

export default NotFound