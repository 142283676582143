import React from "react"

import {IonCol, IonGrid, IonRow} from "@ionic/react"

import ItemType from "../../types/Key"
import {useKeys as useItems} from "../../contexts/KeyContext"

import ButtonBlock      from "../Keys/ButtonBlock"
import ButtonDelete     from "../Keys/ButtonDelete"
import ButtonReset      from "../Keys/ButtonReset"
import ButtonReturn     from "../Keys/ButtonReturn"
import ButtonSync       from "../Keys/ButtonSync"

import ItemGroupInfo     from "../Keys/View/ItemGroupInfo"
import ItemGroupKeyPhone from "../Keys/View/ItemGroupKeyPhone"
import ItemGroupLinked   from "../Keys/View/ItemGroupLinked"
import ViewHeader        from "./ViewHeader"


export interface props {
    item: ItemType
}

const View: React.FC<props> = ({ item }: props) => {

    const { fetchItemsInUse, inProgress, syncItem } = useItems()

    const formButtons = () => {
        if (item !== undefined) {
            return (
                <>
                    {item.options?.isSynchronizable && <ButtonSync item={item} />}
                    <ButtonBlock item={item} />
                    {item.options?.isResetable      && <ButtonReset item={item} />}
                    {item.options?.isReturnable     && <ButtonReturn item={item} />}
                    {item.options?.isDeletable      && <ButtonDelete item={item} />}
                </>
            )
        }
    }

    // useIonViewDidEnter(() => {
    React.useEffect(() => {

        const handle = async () => {
            if (! inProgress && item.options?.isSynchronizable) {
                const newItem = await syncItem(item, true)

                if (newItem && newItem.meta?.isUpdated) fetchItemsInUse('', true)
            }
        }

        handle()
    }, [item])

    return (
        <div className="ion-text-center">
            <ViewHeader item={item} />
            <ItemGroupInfo keyItem={item} />
            {item.keyPhone && <ItemGroupKeyPhone item={item} />}
            <ItemGroupLinked keyCollectionItemUrl={'/keys-in-use/' + item.id} item={item} />

            {/*<IonToolbar>*/}
            {/*    <IonButtons className="ion-margin-vertical" slot="end">*/}
            {/*        {formButtons()}*/}
            {/*    </IonButtons>*/}
            {/*</IonToolbar>*/}


            <IonGrid className="ion-margin-top">
                <IonRow>
                    <IonCol className="ion-text-right">
                    {formButtons()}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )

}

export default View