import React from "react"
import {useTranslation} from "react-i18next"

import {useLockingSystemPermissions as useItems} from "../../contexts/LockingSystemPermissionContext"

import ItemList from '../../components/LockingSystemPermissions/List'
import PageList from '../common/List'


const PageLockingSystemPermissionsList: React.FC = () => {

    const { t } = useTranslation()

    const { fetchItems } = useItems()

    const
        itemType: string     = 'lockingSystemPermissions',
        itemTypeName: string = t('locking-system-permissions.title_list'),
        itemTypeUrl: string  = 'locking-system-permissions'

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            showAddButton={true}
            fetchItems={fetchItems}
            useItems={useItems}
        />
    )
}

export default PageLockingSystemPermissionsList