import React               from "react"
import {useTranslation}    from "react-i18next"

import {IonButton, IonIcon} from "@ionic/react"
import {chevronBackOutline, chevronBackSharp} from "ionicons/icons"

import ItemViewHeader from "../Headers/ItemViewHeader"

interface props {
    icon:     JSX.Element
    link:     string
    subTitle: string
}


const ItemNotFound: React.FC<props> = ( {icon, link, subTitle} ) => {

    const { t } = useTranslation()

    return (
        <div className="ion-text-center">
            <ItemViewHeader icon={icon} title={t('common.not_found')} subTitle={subTitle} />

            <IonButton color="bc-yellow" type="button" fill="solid" routerLink={link}>
                <IonIcon slot="start" ios={chevronBackOutline} md={chevronBackSharp} />
                {t('common.back_to_overview')}
            </IonButton>
        </div>
    )
}

export default ItemNotFound