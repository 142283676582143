import React from "react"

import Key from "../../types/Key"
import IconKeyInUse from "../common/Icons/KeyInUse";
import ItemViewHeader from "../common/Headers/ItemViewHeader";

export interface componentProps {
    item: Key
}

const ViewHeader: React.FC<componentProps> = ({item}) => {

    return <ItemViewHeader
        icon={<IconKeyInUse icon={item?.keyType?.icon} size="large" link={'/keys-in-use/' + item.id + '/form'} />}
        title={item?.description || ''}
        subTitle={item.keyType?.lockingSystemType?.brand.name + ' - ' + item.keyType?.name}
    />

}

export default ViewHeader