import React from 'react'
import {IonBadge, IonItem, IonLabel, IonSkeletonText} from "@ionic/react";

const ListItemSkeleton: React.FC = () => {

    return (
        <IonItem slot="start">
            <IonLabel>
                <IonSkeletonText animated={true} style={{'width': '200px'}}/>
            </IonLabel>
            <IonBadge slot="end" className="keys" color="light">
                <IonSkeletonText animated={true} style={{'width': '40px'}}/>
            </IonBadge>
        </IonItem>
    )

}

export default ListItemSkeleton