import React from "react"
import {IonBadge} from "@ionic/react"
import LockProgrammingState from "../../../types/LockProgrammingState";

interface componentProps {
    item: LockProgrammingState
}

const BadgeLockProgrammingState: React.FC<componentProps> = ({ item }: componentProps) => {

    return (
        <IonBadge className="ion-hide-md-down" slot="end" style={
            {
                '--background': item.backgroundColor,
                'fontWeight': 500,
                'minWidth': '100px',
                '--padding-top': '6px', '--padding-bottom': '6px',
                '--padding-start': '8px', '--padding-end': '8px',
                'marginRight': '6px'
            }
        }>
            {item.nameShort.toLowerCase()}
        </IonBadge>
    )
}

export default BadgeLockProgrammingState