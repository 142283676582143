import React from "react"
import {useTranslation} from "react-i18next"
import {IonItem, IonItemGroup, IonItemSliding, IonLabel, IonList} from "@ionic/react"

import KeyType               from "../../../types/Key"
import LockingSystemZone     from "../../../types/LockingSystemZone"
import IconLockingSystemZone from "../../common/Icons/LockingSystemZone"
import ItemSegmentListHeader from "../../common/Headers/ItemSegmentListHeader"

interface props {
    item: KeyType | undefined;
}

const List: React.FC<props> = ({ item }) => {

    const { t } = useTranslation()

    const title: string    = item?.description + (item?.label ? ' - ' + item.label : '')
    const subTitle: string = item?.keyType?.lockingSystemType?.brand?.name + ' - ' + item?.keyType?.name

    return (
        <IonList lines="full" className="maxWidth">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />
            <IonItemGroup>
                {(item?.lockingSystemZones === undefined || item.lockingSystemZones?.length === 0) &&
                    <IonItem className="ion-text-center">
                        <IonLabel className="ion-text-wrap">
                            {t('keys.no_zones_for_key')}
                        </IonLabel>
                    </IonItem>
                }
                {item?.lockingSystemZones?.map( (lockingSystemZone: LockingSystemZone) => {
                    return (
                        <IonItemSliding key={"locking-system-zone-" + lockingSystemZone.id}>
                            <IonItem button detail lines="inset"
                                     routerLink={"/locking-system-zones/" + lockingSystemZone.id + '/view'}>
                                <IconLockingSystemZone slot="start"/>
                                <IonLabel>
                                    {lockingSystemZone.name}
                                    <p>{lockingSystemZone.description || '(' + t('common.no_description') + ')'}</p>
                                </IonLabel>
                            </IonItem>
                        </IonItemSliding>
                    )
                })}
            </IonItemGroup>
        </IonList>
    )

}

export default List