import React from "react"
import {useHistory} from "react-router"
import {useTranslation} from "react-i18next";

import {
    IonCol, IonGrid, IonInput, IonItem,
    IonLabel, IonList, IonNote, IonRow, IonSelect, IonSelectOption, IonToggle
} from "@ionic/react"

import {LockingSystemPermission as ItemType} from "../../types/LockingSystemPermission"

import {useLockingSystemPermissions as useItems} from "../../contexts/LockingSystemPermissionContext"
import {useAuth} from "../../contexts/AuthContext"
import {useLockingSystems} from "../../contexts/LockingSystemContext"

import LockingSystem     from "../../types/LockingSystem"
import LockingSystemZone from "../../types/LockingSystemZone"

import {LockingSystemZoneApi} from "../../services/LockingSystemZoneApi";

import FormButtonSaveChanges from "../common/Buttons/FormButtonSaveChanges";

export interface formProps {
    item: ItemType | undefined;
}


const Form: React.FC<formProps> = ({ item }) => {
    const { userInfo } = useAuth()
    const history      = useHistory()
    const { t }        = useTranslation()

    const
        [description, setDescription] = React.useState<string>(item?.description || ''),
        [isHiddenByDefault, setIsHiddenByDefault] = React.useState<boolean>(item?.isHiddenByDefault || false),
        [lockingSystemZone, setLockingSystemZone] = React.useState<number>(item?.lockingSystemZoneId || 0),
        [name, setName] = React.useState<string>(item?.name || '')

    const
        isHiddenByDefaultRef = React.useRef<any>(''),
        nameRef          = React.useRef<any>(''),
        descriptionRef   = React.useRef<any>(''),
        lockingSystemRef = React.useRef<any>(0),
        lockingSystemZoneRef= React.useRef<any>(0)

    const { addItem, editItem, closeMessage, inProgress, message } = useItems()
    const { selectedLockingSystemId } = useLockingSystems()

    const [lockingSystemZones, setLockingSystemZones] = React.useState<LockingSystemZone[] | undefined>(undefined)
    const [lockingSystem, setLockingSystem] = React.useState<LockingSystem | undefined>(undefined)

    React.useEffect(() => {
        const selectedLockingSystemId: number = parseInt(
            localStorage.getItem('selectedLockingSystemId') as string
        )

        setLockingSystem( userInfo?.lockingSystems?.find(item => item.id === selectedLockingSystemId) )
    }, [selectedLockingSystemId])

    React.useEffect(() => {
        if (item === undefined) {
            setName('')
            setDescription('')
            //setLockingSystemZone(0)
        }
    }, [item])


    const handleSave = async (e: any) => {

        e.preventDefault()
        e.stopPropagation()

        setDescription(descriptionRef.current.value)
        setIsHiddenByDefault(isHiddenByDefaultRef.current.checked)
        setLockingSystemZone(lockingSystemZoneRef.current.value)
        setName(nameRef.current.value)

        const lockingSystemZoneId: number = parseInt(lockingSystemZoneRef.current.value) || 0

        const newItem: ItemType = {
            id:                  item?.id || 0,
            description:         descriptionRef.current.value,
            isHiddenByDefault:   isHiddenByDefaultRef.current.checked,
            lockingSystemId:     lockingSystemRef.current.value,
            lockingSystemZoneId: lockingSystemZoneId,
            name:                nameRef.current.value,
        }

        const result = item !== undefined
            ? await editItem(newItem)
            : await addItem(newItem)
        ;

        if (result !== undefined || message !== undefined) {
            if (result) {
                history.push('/locking-system-permissions/' + result.id + '/view')
            }
        }
    }

    const FormKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            handleSave(e);
        }
    }

    React.useEffect(() => {
        const fetchLockingSystemZones = async () => {
            if (lockingSystemZones === undefined) {
                setLockingSystemZones(await LockingSystemZoneApi.getAll())
            }
        }

        fetchLockingSystemZones()

        // Check for default zone
        if (lockingSystemZones && lockingSystemZones.length > 0 && item === undefined) {
            const defaultZone = lockingSystemZones.find(z => z.isDefaultForOther)

            if (defaultZone !== undefined) {
                setLockingSystemZone(defaultZone.id)
            }
        }

    }, [item, lockingSystemZones])

    return (
        <div>
        <IonList className="maxWidth">
            <form method="POST" id="formLockingSystemPermission" onSubmit={handleSave} onKeyUp={ e => FormKeyUp(e) }>
            <input type="hidden" name="lockingSystemId" ref={lockingSystemRef} value={lockingSystem ? lockingSystem.id : 0} />
                <IonItem>
                    <IonLabel>{t('keys.form.locking_system')}</IonLabel>
                    <IonNote slot="end">{lockingSystem ? lockingSystem.name : '?'}</IonNote>
                </IonItem>
                <IonItem>
                    <IonToggle checked={isHiddenByDefault} color="bc-yellow" justify="space-between"
                               labelPlacement="start" ref={isHiddenByDefaultRef} value="1">
                        {t('common.hidden_by_default')}
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonInput ref={nameRef} name="name" value={name} type="text"
                        label={t('locking-system-permissions.form.name') as string} labelPlacement="stacked"
                        placeholder={t('locking-system-permissions.form.name') as string}
                        autofocus={true}
                        clearInput={true}
                        disabled={inProgress}
                    />
                </IonItem>
                <IonItem>
                    <IonInput ref={descriptionRef} name="description" type="text" value={description}
                              label={t('locking-system-permissions.form.description') as string}
                              labelPlacement="stacked"
                              placeholder={t('locking-system-permissions.form.description') as string}
                              clearInput={true}
                              disabled={inProgress}
                    />
                </IonItem>
                <IonItem>
                    <IonSelect cancelText={t('common.cancel') as string}
                               interfaceOptions={{cssClass: 'alert-select-width-90'}}
                               label="Zone"
                               labelPlacement="floating"
                               okText={t('common.save') as string}
                               placeholder="Zone"
                               ref={lockingSystemZoneRef}
                               value={lockingSystemZone}>
                        {lockingSystemZones !== undefined && lockingSystemZones.length &&
                            lockingSystemZones.map((lockingSystemZone) => (
                                <IonSelectOption key={lockingSystemZone.id} value={lockingSystemZone.id}>
                                    {lockingSystemZone.name +
                                        (lockingSystemZone.isDefaultForOther ? ' (' +
                                            t('locking-system-zones.form.default_zone') + ')' : '')
                                    }
                                </IonSelectOption>
                                )
                        )}
                    </IonSelect>
                </IonItem>

                <IonGrid className="ion-margin-top">
                    <IonRow>
                        <IonCol className="ion-text-right">
                            <FormButtonSaveChanges />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </form>
        </IonList>
        </div>
    );
}

export default Form