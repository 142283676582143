import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import User from "../../../types/User"


export interface componentProps {
    item: User
}

const ViewItemDataSource: React.FC<componentProps> = ({item} ) => {

    const {t} = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.data_source')}</IonItemDivider>
            <IonItem lines="full">
                <IonLabel>{t('common.source_name')}</IonLabel>
                <IonNote slot="end">{item.dataSource?.name || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('common.source_item_id')}</IonLabel>
                <IonNote slot="end">{item.sourceItemId || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ViewItemDataSource