import React from 'react'
import {useHistory} from "react-router"
import {useTranslation} from "react-i18next"

import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel} from "@ionic/react"
import {banOutline, banSharp, shieldCheckmarkOutline, shieldCheckmarkSharp} from "ionicons/icons"

import {BlocklistKeyType as ItemType} from "../../types/BlocklistKey"

import ListItemOptionUnblock from "../Keys/List/ListItemOptionUnblock"

import IconBlocklistKey from "../common/Icons/BlocklistKey"
import BadgeKeyProgrammingState from "../common/Badges/BadgeKeyProgrammingState";

interface itemProps {
    item: ItemType
}

const ListItem: React.FC<itemProps> = ({ item }) => {
    const itemBaseUrl: string   = '/blocklist-keys/'
    const lockingSystemPermissionsCount:number = item.key.lockingSystemPermissions?.length || 0

    const { t } = useTranslation()
    const history = useHistory()

    const goToKeyLockingSystemPermissions = (ev: any, itemId: number) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push(itemBaseUrl + itemId + '/lockingSystemPermissions')
    }

    const itemTitle = (): string => {
        return ('SPARE_KEY' === item.key.description)
            ? t('keys.spare_key')
            : item.key.description || '-'
    }

    return (
        <>
            <IonItem button detail routerLink={itemBaseUrl + item.id + '/view'} slot="start">
                <IconBlocklistKey icon={item?.key.keyType?.icon} slot="start" />
                <IonLabel>
                    {itemTitle()}
                    {item.key.label &&
                        <span style={{ 'fontWeight': 300 }}>&nbsp;- {item.key.label}</span>
                    }
                    <span style={{ 'fontWeight': '200', 'fontSize': '14px' }}>
                        &nbsp;- {item.key.keyType?.lockingSystemType?.brand?.name} - {item.key.keyType?.name}
                    </span>
                    <p>
                        {item.key.person ? (
                            <span>{t('common.belongs_to')} {item.key.person?.firstName + ' ' + item.key.person?.lastName}</span>
                        ) : (
                            <span>{t('keys.form.serial_number')}: {item.key.serial} ({t('keys.form.not_linked_to_person')})</span>
                        )}
                    </p>
                </IonLabel>
                {item.key.keyProgrammingState &&
                    <BadgeKeyProgrammingState item={item.key.keyProgrammingState} />
                }
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton color="medium" fill="clear"
                               onClick={ (ev) => goToKeyLockingSystemPermissions(ev, item.id) }
                               size="large" style={{ 'fontSize': '17px' }}
                    >
                        {lockingSystemPermissionsCount ? lockingSystemPermissionsCount : '-'}
                        <IonIcon color="bc-blue" slot="end"
                                 ios={lockingSystemPermissionsCount ? shieldCheckmarkOutline : banOutline}
                                 md={lockingSystemPermissionsCount  ? shieldCheckmarkSharp   : banSharp} />
                    </IonButton>
                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                <ListItemOptionUnblock item={item} />
                {/*<ListItemOptionDelete  item={item.key} blocklistKey={item} />*/}
            </IonItemOptions>
        </>
    )
}

export default ListItem