import ApiClient from "./ApiClient"

import BlocklistKeyType from "../types/BlocklistKey"
import KeyType          from "../types/Key"

import SyncResult from "../types/SyncResult"

export const BlocklistKeyApi = {

    apiPath: 'blocklist-keys',

    endpoint: (param: string = ''): string => {
        return param
            ? BlocklistKeyApi.apiPath + '/' + param
            : BlocklistKeyApi.apiPath
    },

    get: async (search: string = ''): Promise<BlocklistKeyType[]> => {
        const items: BlocklistKeyType[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ]);

        if (search) params.append('search', search)

        const response = await ApiClient.get(BlocklistKeyApi.endpoint(), { params })

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    add: async (item: KeyType): Promise<BlocklistKeyType> => {
        const result = await ApiClient.post(BlocklistKeyApi.endpoint(), {'keyId': item.id })

        return result.data?.data
    },

    delete: async (item: BlocklistKeyType): Promise<any> => {

        const result = await ApiClient.delete(BlocklistKeyApi.endpoint(item.id.toString()))

        return result.data?.status
    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {

        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(BlocklistKeyApi.endpoint('sync/all'), { params })

        return result?.data
    },

}

export default BlocklistKeyApi