import ApiClient from "./ApiClient"
import {KeyLockingSystemPermissionAdd} from "../types/KeyLockingSystemPermission"
import SyncResult from "../types/SyncResult"
import Key from "../types/Key";
import LockingSystemPermission from "../types/LockingSystemPermission";

export const KeyLockingSystemPermissionApi = {

    apiPath: 'key-locking-system-permissions',

    add: async (keyLockingSystemPermissions: KeyLockingSystemPermissionAdd): Promise<any> => {

        const result = await ApiClient.post(
            'keys/' + keyLockingSystemPermissions.keyId
            + '/lockingSystemPermissions'
            + '?lockingSystemId=' + keyLockingSystemPermissions.lockingSystemId,
            keyLockingSystemPermissions
        )

        return result.data?.data
    },

    delete: async (key: Key, lockingSystemPermission: LockingSystemPermission): Promise<any> => {

        return await ApiClient.delete('keys/' + key.id
            + '/lockingSystemPermissions/' + lockingSystemPermission.id
            + '?lockingSystemId=' + key.lockingSystemId
        )

    },

    deleteAll: async (key: Key): Promise<any> => {

        return await ApiClient.delete('keys/' + key.id
            + '/lockingSystemPermissions/all'
            + '?lockingSystemId=' + key.lockingSystemId
        )

    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(
            KeyLockingSystemPermissionApi.apiPath + '/sync/all', { params }
        )

        return result?.data
    },

}

export default KeyLockingSystemPermissionApi