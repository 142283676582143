import React                 from "react"
import {RouteComponentProps, useHistory} from "react-router"

import { useAuth } from "../../contexts/AuthContext"
import {IonNote} from "@ionic/react";

interface pageProps extends RouteComponentProps<{
    id: string;
}> {}

const PageCallback: React.FC<pageProps> = () => {
    const { errors, handleCallback } = useAuth()
    const history = useHistory()

    const [message, setMessage] = React.useState<string | undefined>(undefined)

    React.useEffect(( ) => {
        const callback = async (code: string, state: string) => {
            const result = await handleCallback(code, state)

            if (result) history.push('/dashboard')
        }

        const
            params: URLSearchParams = new URLSearchParams(window.location.search),
            code: string | null = params.get('code'),
            state: string | null = params.get('state')

        if (code === null || state === null) {
            setMessage('Code or state value(s) are missing.')
        } else {
            callback(code, state)
        }

    }, [ ]);

    return (
        <div style={{'width': '80%', 'margin': '0 auto', 'textAlign': 'center'}}>
            {(errors.length || message !== undefined) > 0 &&
                <IonNote style={{
                    'width': '100%',
                    'color': 'red',
                    'padding': '8px',
                    'fontWeight': 900
                }}>
                    Error: {errors} {message}
                </IonNote>
            }
            {/*<p>message: {message}</p>*/}
        </div>
    )

}

export default PageCallback
