import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon} from "@ionic/react"
import {refreshCircleOutline, refreshCircleSharp} from "ionicons/icons";

import {useKeys as useItems} from "../../contexts/KeyContext"

import KeyType from "../../types/Key"

interface componentProps {
    item: KeyType
}

const ButtonSync: React.FC<componentProps> = ({item}) => {

    const { t }  = useTranslation()
    const { inProgress, fetchItemsInSafe, fetchItemsInUse, syncItem } = useItems()

    const handleSync = async () => {
        const newItem = await syncItem(item)

        if (newItem) {
            if (newItem.meta?.isUpdated) {
                fetchItemsInUse('', true)
                fetchItemsInSafe('', true)
            }
        }
    }

    return (
        <IonButton disabled={inProgress} type="button" color="bc-yellow" fill="outline"
                   className="ion-padding-start"
                   onClick={() => handleSync()}>
            <IonIcon slot="start" ios={refreshCircleOutline} md={refreshCircleSharp} />
            {t('common.synchronize')}
        </IonButton>
    )

}

export default ButtonSync