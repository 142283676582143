import React, {useEffect, useState} from "react"
import {useHistory} from "react-router"
import {useTranslation} from "react-i18next"

import {
    IonCol, IonGrid, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonModal, IonNote, IonRow,
    IonSelect, IonSelectOption
} from "@ionic/react"
import {personAddOutline} from "ionicons/icons"

import {Key as ItemType} from "../../types/Key"

import {useKeys} from "../../contexts/KeyContext"
import {useLockingSystemZones} from "../../contexts/LockingSystemZoneContext"

import BlocklistKey from "../../types/BlocklistKey"
import {useLockingSystems} from "../../contexts/LockingSystemContext"
import LockingSystem from "../../types/LockingSystem"
import FormButtonSaveChanges from "../common/Buttons/FormButtonSaveChanges"
import FormButtonSaveNew from "../common/Buttons/FormButtonSaveNew"
import SelectPeople from "../common/Modal/SelectPeople";

import PersonSelectOption from "../../types/SelectOptions/PersonSelectOption"

import Person from "../../types/Person"
import {usePeople} from "../../contexts/PersonContext";
import LockingSystemZone from "../../types/LockingSystemZone";


interface props {
    item:           ItemType | undefined
    blocklistItem?: BlocklistKey
}


const Form: React.FC<props> = ({ item, blocklistItem}) => {

    const history = useHistory()
    const { t } = useTranslation()

    const {
        linkKey,
        selectOptions: selectPeopleOptions,
        fetchSelectOptions: fetchSelectPeopleOptions
    } = usePeople()

    const [modalSelectPersonReady, setModalSelectPersonReady] = React.useState<boolean>(false)
    const modalSelectPerson = React.useRef<HTMLIonModalElement>(null);
    const [selectedPeople, setSelectedPeople] = useState<number[]>([])

    const initialStateSelectPersonLabel: string = item?.person
        ? item.person.firstName + ' ' + item.person.lastName
        : '(' + t('keys.form.not_linked_to_person') + ')'

    const [
        selectPersonLabel, setSelectedPersonLabel
    ] = useState<string>(initialStateSelectPersonLabel)

    const initialStateDescription: string = ('SPARE_KEY' === item?.description)
        ? t('keys.spare_key') : item?.description || ''

    const [description, setDescription] = useState<string>(initialStateDescription)

    const [email, setEmail] = React.useState<string>(item?.keyPhone?.email || '')
    const [phoneNo, setPhoneNo] = React.useState<string>(item?.keyPhone?.phoneNo || '')
    const [
        lockingSystemZoneIds, setLockingSystemZoneIds
    ] = useState<number[] | undefined>(undefined)

    const descriptionRef   = React.useRef<any>('')
    const keyTypeRef       = React.useRef<any>(0)
    const lockingSystemRef = React.useRef<any>(0)

    const phoneNoRef = React.useRef<any>('')
    const emailRef = React.useRef<any>('')
    const lockingSystemsZoneRef = React.useRef<any>('')

    const { addItem, editItem, inProgress } = useKeys()
    const { items: lockingSystems, selectedLockingSystemId } = useLockingSystems()
    const { items: lockingSystemZones, fetchItems: fetchLockingSystemZones } = useLockingSystemZones()

    const [lockingSystem, setLockingSystem] = React.useState<LockingSystem | undefined>(undefined)

    const handleSave = async (e: any) => {
        e.preventDefault();

        setDescription(descriptionRef.current.value)
        setLockingSystemZoneIds(lockingSystemsZoneRef.current.value)

        const personId: null | number = selectedPeople.length === 1
            ? selectedPeople[0]
            : item?.personId || null

        const newItem: ItemType = {
            id:              item?.id || 0,
            description:     descriptionRef.current?.value,
            keyPhone: {
                id:      item?.keyPhone?.id || 0,
                email:   emailRef.current.value,
                keyId:   item?.id || 0,
                phoneNo: phoneNoRef.current.value
            },
            keyTypeId:       parseInt(keyTypeRef.current.value),
            lockingSystemId: lockingSystemRef.current.value,
            lockingSystemZoneIds: lockingSystemsZoneRef.current.value,
            personId:        personId
        }

        const result: ItemType | void = item !== undefined
            ? await editItem(item, newItem)
            : await addItem(newItem)

        if (result !== undefined) {
            let nextUrl: string = blocklistItem
                ? '/blocklist-keys'
                : (personId ? '/keys-in-use' : '/keys-in-safe')

            if (item?.id && ! blocklistItem) nextUrl += '/' + item.id + '/view'

            if (personId) linkKey(result)

            setLockingSystemZoneIds(undefined)
            setDescription('')
            setSelectedPeople([])
            setSelectedPersonLabel(initialStateSelectPersonLabel)

            history.push(nextUrl)
        }
    }

    const FormKeyUp = (e: any) => {
        if (e.key === 'Enter') handleSave(e);
    }

    useEffect(() => {
        setModalSelectPersonReady(false)

        setTimeout(() => setModalSelectPersonReady(true), 750)

        fetchLockingSystemZones()

        if (selectedLockingSystemId === undefined) return

        setLockingSystem( lockingSystems?.find(item => item.id === selectedLockingSystemId) )

    }, [selectedLockingSystemId])

    useEffect(() => {
        const fetchPeople = async () => fetchSelectPeopleOptions()

        fetchPeople()

    }, [selectPeopleOptions])

    useEffect(() => {

        if (lockingSystemZones?.length && lockingSystemZoneIds === undefined) {
            let foundDefaultZoneForKeys: LockingSystemZone | undefined

            if (lockingSystem !== undefined && lockingSystem.defaultLockingSystemZoneIdKeys !== undefined) {
                // check  if zone exists
                foundDefaultZoneForKeys = lockingSystemZones.find(
                    (zone) => zone.id === lockingSystem.defaultLockingSystemZoneIdKeys
                )
            }

            if (foundDefaultZoneForKeys === undefined) {
                foundDefaultZoneForKeys = lockingSystemZones
                    .find((zone) => zone.isDefaultForKeys)
            }

            if (undefined !== foundDefaultZoneForKeys) {
                setLockingSystemZoneIds([foundDefaultZoneForKeys.id])
            }
        }

    }, [lockingSystemZones])

    const filterAvailablePeople = (): PersonSelectOption[] | [] => {
        const availablePeople: PersonSelectOption[] = []

        const existingPeople: Person[] = item?.person ? [item.person] : []

        if (existingPeople && selectPeopleOptions) {
            selectPeopleOptions.forEach((selectOption) => {
                const found = existingPeople.find(
                    existingPerson => selectOption.id === existingPerson.id
                )

                if (undefined === found) availablePeople.push(selectOption)
            })
        }

        return availablePeople
    }

    const personSelectionChanged = async (people: number[]) => {
        setModalSelectPersonReady(false)

        setSelectedPeople(people);
        modalSelectPerson.current?.dismiss();

        if (selectPeopleOptions && people.length === 1) {
            const foundPerson = selectPeopleOptions
                .find(person => person.id === people[0])

            if (foundPerson) {
                setEmail(foundPerson.email || '')
                setPhoneNo(foundPerson.phone || '')
                setSelectedPersonLabel(foundPerson.title)

                setDescription(item === undefined
                    ? t('keys.form.mobile') + ' ' + foundPerson.name
                    : (item.keyType?.prefix || 'Key') + ' ' + foundPerson.name
                )
            }
        } else {
            setEmail('')
            setPhoneNo('')
            setSelectedPersonLabel(initialStateSelectPersonLabel)
        }

        setModalSelectPersonReady(true)
    }

    return (
        <IonList className="maxWidth">
            <form method="POST" id="formKeys"
                  onKeyUp={ e => FormKeyUp(e) } onSubmit={handleSave}
            >
            <input type="hidden" name="lockingSystemId" ref={lockingSystemRef} value={lockingSystem ? lockingSystem.id : 0} />
            <input type="hidden" name="keyTypId" ref={keyTypeRef} value={item ? item.keyTypeId : 111} />
                <IonItem>
                    <IonLabel>{t('keys.form.locking_system')}</IonLabel>
                    <IonNote slot="end">{lockingSystem ? lockingSystem.name : '?'}</IonNote>
                </IonItem>
                <IonItem>
                    <IonLabel>{t('keys.type')}</IonLabel>
                    <IonNote slot="end">{item?.keyType?.name || 'S50 Phone key'}</IonNote>
                </IonItem>
                <IonItem className={(! item || ! item.label) ? 'ion-hide' : ''}>
                    <IonLabel>{t('keys.form.label')}</IonLabel>
                    <IonNote slot="end">{item?.label || '-'}</IonNote>
                </IonItem>
                <IonItem className={!item ? 'ion-hide' : ''}>
                    <IonLabel>{t('keys.form.serial_number')}</IonLabel>
                    <IonNote slot="end">{item?.serial}</IonNote>
                </IonItem>

                {item === undefined &&
                    <IonItem>
                        <IonSelect cancelText={t('common.cancel') as string}
                                   interfaceOptions={{cssClass: 'alert-select-width-90'}}
                                   label="Zone"
                                   labelPlacement="floating"
                                   multiple={true}
                                   okText={t('common.save') as string}
                                   placeholder="Zone"
                                   ref={lockingSystemsZoneRef}
                                   value={lockingSystemZoneIds}>
                            {(lockingSystemZones !== undefined && lockingSystemZones.length) &&
                                lockingSystemZones.map((lockingSystemZone) => (
                                        <IonSelectOption key={lockingSystemZone.id} value={lockingSystemZone.id}>
                                            {lockingSystemZone.name +
                                                (lockingSystemZone.isDefaultForKeys
                                                    ? ' (' + t('locking-system-zones.form.default_zone') + ')' : '')
                                            }
                                        </IonSelectOption>
                                    )
                                )}
                        </IonSelect>
                    </IonItem>
                }

                {item?.person &&
                    <IonItem button detail={true} routerLink={`/people/${item.person.id}/view`}>
                        <IonLabel>{t('keys.form.linked_person')}</IonLabel>
                        <IonNote slot="end">
                            {item.person.firstName + ' ' + item.person.lastName}
                        </IonNote>
                    </IonItem>
                }
                {(item === undefined || item.person === null) &&
                    <IonItem button detail detailIcon={personAddOutline}
                             id={"select-key-" + (item ? item.id : 'new' + "-person")} slot="start">
                        <IonLabel>{t('keys.form.linked_person')}</IonLabel>
                        <IonNote slot="end">{selectPersonLabel}</IonNote>
                    </IonItem>
                }

                <IonItem>
                    <IonInput name="description" type="text" value={description}
                              ref={descriptionRef}
                              label={t('keys.form.description') as string}
                              labelPlacement="stacked"
                              placeholder={t('keys.form.description') as string}
                              clearInput={true}
                              autofocus={true} required
                    />
                </IonItem>


                {/*<IonItem>*/}
                {/*    <IonSelect ref={personRef}*/}
                {/*               okText={t('common.save') as string}*/}
                {/*               cancelText={t('common.cancel') as string}*/}
                {/*               label={t('keys.form.linked_person') as string}*/}
                {/*               labelPlacement="floating"*/}
                {/*               onIonChange={(e) => handleChange()}*/}
                {/*               placeholder={t('keys.form.linked_person') as string}*/}
                {/*               value={personId}*/}
                {/*               disabled={*/}
                {/*                   (item && item.keyPhone && item.keyPhone.id > 0 ? true : false)*/}
                {/*               }*/}
                {/*               interfaceOptions={{cssClass: 'alert-select-width-90'}}*/}
                {/*    >*/}
                {/*        /!*<IonSelectOption key="0" value="0">*!/*/}
                {/*        /!*    -- {t('keys.form.not_linked_to_person')}*!/*/}
                {/*        /!*</IonSelectOption>*!/*/}

                {/*        /!*{people.length && people.map((person) => (*!/*/}
                {/*        /!*    <IonSelectOption key={person.id} value={person.id}>*!/*/}
                {/*        /!*        {person.firstName} {person.lastName}*!/*/}
                {/*        /!*    </IonSelectOption>*!/*/}
                {/*        /!*))}*!/*/}

                {/*    </IonSelect>*/}
                {/*</IonItem>*/}


                {(selectedPeople.length > 0 &&
                        (parseInt(keyTypeRef.current.value) === 11 || parseInt(keyTypeRef.current.value) === 111)) &&
                    <IonItemGroup className="ion-padding-top">
                        <IonItemDivider>{t('keyPhones.title_item')}</IonItemDivider>
                        <IonItem>
                            <IonInput name="phone_no" type="tel" value={phoneNo}
                                      ref={phoneNoRef}
                                      label={t('keyPhones.form.phone_no') as string}
                                      labelPlacement="stacked"
                                      placeholder={t('people.form.mobile_placeholder') as string}
                                      clearInput={true}
                                      required
                            />
                        </IonItem>
                        <IonItem>
                            <IonInput name="email" type="email" value={email}
                                      ref={emailRef}
                                      label={t('keyPhones.form.email') as string}
                                      labelPlacement="stacked"
                                      placeholder={t('people.form.email') as string}
                                      clearInput={true}
                            />
                        </IonItem>
                    </IonItemGroup>
                }

                <IonGrid className="ion-margin-top">
                    <IonRow>
                        <IonCol className="ion-text-right">
                            {item?.id
                                ? <FormButtonSaveChanges disabled={inProgress} />
                                : <FormButtonSaveNew     disabled={inProgress} />
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {/*keepContentsMounted={true}*/}

                {(undefined === item || item.person === null) &&
                <IonModal ref={modalSelectPerson}
                          trigger={modalSelectPersonReady
                              ? "select-key-" + (item ? item.id : 'new' + "-person")
                              : undefined}
                    >
                    <SelectPeople
                        hasShowHidden={true}
                        items={filterAvailablePeople()}
                        selectedItems={selectedPeople}
                        selectTypeTitle={t('people.title_list')}
                        title={t('people.title_item').toString()}
                        onSelectionCancel={() => modalSelectPerson.current?.dismiss()}
                        onSelectionChange={personSelectionChanged}
                    />
                </IonModal>
                }

            </form>
        </IonList>
    );
}

export default Form