import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import Key from "../../../types/Key"

export interface props {
    keyCollectionItemUrl: string
    item:                 Key
}

const ItemGroupLinked: React.FC<props> = ( {keyCollectionItemUrl, item} ) => {

    const { t } = useTranslation()

    const permissionsCount: number = item.lockingSystemPermissions?.length || 0
    const zonesCount: number       = item.lockingSystemZones?.length       || 0

    return (
        <IonItemGroup className="ion-margin-top">
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            <IonItem lines="full" button={permissionsCount > 0} detail={true}
                     routerLink={keyCollectionItemUrl + '/lockingSystemPermissions'}>
                <IonLabel>{t('keys.form.permissions_on_key')}</IonLabel>
                <IonNote slot="end">{permissionsCount || '-'}</IonNote>
            </IonItem>
            <IonItem button={zonesCount > 0} detail={true} lines="full"
                     routerLink={keyCollectionItemUrl + '/lockingSystemZones'}>
                <IonLabel>{t('keys.form.zones_for_key')}</IonLabel>
                <IonNote slot="end">{zonesCount || '-'}</IonNote>
            </IonItem>
            {item.person &&
                <IonItem button={true} detail={true} lines="full"
                         routerLink={'/people/' + item.personId + '/view'}>
                    <IonLabel>{t('keys.form.linked_person')}</IonLabel>
                    <IonNote slot="end">{item.person?.firstName} {item.person?.lastName}</IonNote>
                </IonItem>
            }
        </IonItemGroup>
    )

}

export default ItemGroupLinked