import React from "react"
import {Redirect, Route} from "react-router-dom"

import {IonRouterOutlet} from "@ionic/react"

import ForgotPassword from "../pages/ForgotPassword/ForgotPassword"
import Login          from "../routes/Login"
import ResetPassword  from "../pages/ResetPassword/ResetPassword"

import CallBack       from "../pages/Auth/CallBack"
import Logout         from "../pages/Auth/Logout";

const RoutesUnauthenticated = () => {
    return (
        <IonRouterOutlet id="main">
            <Route    exact path="/forgot-password"             component={ForgotPassword} />
            <Route          path="/login"                       component={Login}          />
            <Route    exact path="/logout"                      component={Logout} />
            <Route    exact path="/reset-password/:uuid/:token" component={ResetPassword}  />

            <Route    exact path="/callback"                    component={CallBack} />

            <Redirect exact from="/" to="/login" />

            <Route    render={() => <Redirect to="/login" />} />
        </IonRouterOutlet>
    )
}

export default RoutesUnauthenticated