import React from "react"
import {useTranslation} from "react-i18next"

import {useLocks as useItems} from "../../../contexts/LockContext"

import Lock                    from "../../../types/Lock"
import LockingSystemPermission from "../../../types/LockingSystemPermission"

import ListItemButtonDelete from "../../common/Buttons/ListItemButtonDelete"


interface props {
    lock:                    Lock
    lockingSystemPermission: LockingSystemPermission

}


const ButtonDelete: React.FC<props> = ({lock, lockingSystemPermission}) => {

    const { t } = useTranslation()

    const {deleteLockingSystemPermission} = useItems()

    const onDelete = async () => {
        await deleteLockingSystemPermission(lock, lockingSystemPermission)
    }

    return (
        <ListItemButtonDelete
            headerRemovableTxt={t('lockLockingSystemPermissions.alerts.delete.header')}
            headerNotRemovableTxt={t('lockLockingSystemPermissions.alerts.delete_not_possible.header')}
            messageRemovableTxt={t('lockLockingSystemPermissions.alerts.delete.message')}
            messageNotRemovableTxt={t('lockLockingSystemPermissions.alerts.delete_not_possible.message')}
            isRemovable={lockingSystemPermission.options?.isRemovableFromLock}
            onDelete={() => { onDelete() }}
        />
    )
}

export default ButtonDelete