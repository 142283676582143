import React from 'react'
import {useTranslation} from "react-i18next"

import {IonIcon, IonItemOption, useIonAlert} from "@ionic/react"
import {closeCircleOutline, closeCircleSharp} from "ionicons/icons"

import BlocklistKey          from "../../../types/BlocklistKey"
import Key                   from "../../../types/Key"
import {useKeys as useItems} from "../../../contexts/KeyContext";
import {usePeople}           from "../../../contexts/PersonContext"
import {useHistory} from "react-router";

interface componentProps {
    item:          Key
    blocklistKey?: BlocklistKey
}


const ListItemOptionReset: React.FC<componentProps> = ({item, blocklistKey}) => {

    const history = useHistory()
    const { resetItem } = useItems()
    const { unlinkKey } = usePeople()
    const [presentAlert] = useIonAlert()
    const { t } = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertReset = async () => {
        return presentAlert({
            header:    t('keys.alerts.reset.header'),
            subHeader: t('keys.alerts.reset.subHeader'),
            message:   t('keys.alerts.reset.message'),
            buttons: [{
                text:    t('common.cancel'),
                role:    'cancel',
                handler: () => onCancel(),
            }, {
                text:    t('common.reset'),
                role:    'confirm',
                handler: () => onReset(),
            },],
        })
    }

    const onCancel = () => { closeItemSliding() }

    const onReset = async () => {
        closeItemSliding()
        const newKey = await resetItem(item, blocklistKey)

        if (newKey) {
            unlinkKey(item)
        }

        history.push('/keys-in-safe')
    }

    return (
        <IonItemOption color="bc-yellow" onClick={() => alertReset()}>
            <IonIcon slot="top" ios={closeCircleOutline} md={closeCircleSharp} />
            {t('common.reset')}
        </IonItemOption>
    )
}

export default ListItemOptionReset