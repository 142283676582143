import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import Person from "../../../types/Person"


export interface componentProps {
    item: Person
}

const ItemGroupInfo: React.FC<componentProps> = ({item} ) => {

    const {t} = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>{t('people.form.contact_info')}</IonItemDivider>
            <IonItem lines="full">
                <IonLabel>{t('people.form.email')}</IonLabel>
                <IonNote slot="end">{item.email || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('people.form.mobile')}</IonLabel>
                <IonNote slot="end">{item.phone1 || '-'}</IonNote>
            </IonItem>
            {item.companyName &&
                <IonItem lines="full">
                    <IonLabel>{t('people.form.company_name')}</IonLabel>
                    <IonNote slot="end">{item.companyName}</IonNote>
                </IonItem>
            }
            {item.meta?.syncedAt &&
                <IonItem lines="full">
                    <IonLabel>{t('common.synced_at')}</IonLabel>
                    <IonNote slot="end">{item.meta.syncedAt || '-'}</IonNote>
                </IonItem>
            }
        </IonItemGroup>
    )
}

export default ItemGroupInfo