import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemGroup, IonItemSliding, IonLabel, IonList} from "@ionic/react"

import LockingSystemPermission from "../../types/LockingSystemPermission";
import IconLockingSystemPermission from "../common/Icons/LockingSystemPermission";
import BadgeLockingSystemPermissionType from "../common/Badges/BadgeLockingSystemPermissionType";
import LockingSystemZone from "../../types/LockingSystemZone"
import ItemSegmentListHeader from "../common/Headers/ItemSegmentListHeader";

interface componentProps {
    item: LockingSystemZone | undefined;
}

const LockingSystemPermissionsList: React.FC<componentProps> = ({ item }) => {

    const { t } = useTranslation()

    const itemBadge = (item: LockingSystemPermission) => {
        if (item.lockingSystemPermissionType) {
            return (
                <BadgeLockingSystemPermissionType item={item.lockingSystemPermissionType} />
            )
        }
    }

    const title: string    = item?.name || '-'
    const subTitle: string = item?.description || '(' + t('common.no_description') + ')'

    return (
        <IonList lines="full" className="maxWidth">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />

            <IonItemGroup>
                {(item?.lockingSystemPermissions === undefined || item?.lockingSystemPermissions?.length === 0) &&
                    <IonItem className="ion-text-center">
                        <IonLabel className="ion-text-wrap">
                            {t('locking-system-zones.no_permissions_for_zone')}
                        </IonLabel>
                    </IonItem>
                }
                {item?.lockingSystemPermissions?.map( (lockingSystemPermission: LockingSystemPermission) => {
                    return (
                        <IonItemSliding
                            className={lockingSystemPermission.isHiddenByDefault ? 'hidden-by-default' : ''}
                            key={"locking-system-permission-" + lockingSystemPermission.id}>
                            <IonItem button className={lockingSystemPermission.isHiddenByDefault ? 'hidden-by-default' : ''}
                                     detail lines="inset"
                                     routerLink={"/locking-system-permissions/" + lockingSystemPermission.id + '/view'}>
                                <IconLockingSystemPermission slot="start"/>
                                <IonLabel>
                                    {lockingSystemPermission.name}
                                    <p>{lockingSystemPermission.description}</p>
                                </IonLabel>
                                {itemBadge(lockingSystemPermission)}
                            </IonItem>
                        </IonItemSliding>
                    )
                })}
            </IonItemGroup>
        </IonList>
    )

}

export default LockingSystemPermissionsList