import React from "react"
import {useTranslation} from "react-i18next"
import {IonItem, IonLabel} from "@ionic/react";

const ListItemNoResult = () => {
    const { t } = useTranslation()

    return (
        <IonItem lines="full">
            <IonLabel className="ion-text-center">{t('common.no_results_found')}.</IonLabel>
        </IonItem>
    )
}

export default ListItemNoResult