import {
    banOutline, banSharp,
    cardOutline, cardSharp,
    cubeOutline, cubeSharp,
    helpOutline, helpSharp,
    keyOutline, keySharp,
    phonePortraitOutline,
    phonePortraitSharp,
    radioButtonOnOutline,
    radioButtonOnSharp
} from "ionicons/icons";

const icons: any[] = [
    {
        index: 'key',
        ios: keyOutline,
        md: keySharp,
    },
    {
        index: 'phone',
        ios: phonePortraitOutline,
        md: phonePortraitSharp,
    },
    {
        index: 'fob',
        ios: radioButtonOnOutline,
        md: radioButtonOnSharp,
    },
    {
        index: 'card',
        ios: cardOutline,
        md: cardSharp,
    },
    {
        index: 'unknown',
        ios: helpSharp,
        md: helpOutline,
    },
    {
        index: 'in-safe',
        ios: cubeOutline,
        md: cubeSharp,
    },
    {
        index: 'on-blocklist',
        ios:   banOutline,
        md:    banSharp,
    },
]

export const getIosIcon = (index: string) => {
    const icon = icons.find(i => i.index === index)

    return icon.ios || helpOutline
}

export const getMDIcon = (index: string) => {
    const icon = icons.find(i => i.index === index)

    return icon.md || helpSharp
}