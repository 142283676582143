import React, {FormEvent} from 'react'
import {
    IonCard, IonCardTitle, IonCardHeader, IonCardContent,
    IonItem, IonInput, IonButton, IonContent, IonPage, IonNote, IonCardSubtitle, useIonViewWillEnter
} from '@ionic/react'
import { useHistory     } from "react-router"
import { useTranslation } from "react-i18next"

import '../Auth/Login.css';

import { useAuth      } from "../../contexts/AuthContext";
import LanguageSelector from "../../components/common/LanguageSelector";
import Message from "../../components/common/Message";


const ForgotPassword: React.FC = () => {

    const {
        handleForgotPassword, errors, clearErrors, message, inProgress
    } = useAuth()
    const history         = useHistory()
    const { t }     = useTranslation()

    const email   = React.useRef<HTMLIonInputElement>(null);

    useIonViewWillEnter(() => {
        if (clearErrors !== undefined) {
            clearErrors()
        }
    })

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const status = await handleForgotPassword(email.current?.value)

        if ('success' === status) {
            history.push('/login')
        }
    }

    return (
        <IonPage>
            <IonContent className="login" forceOverscroll={false}>
                <div className="bc-flex-center">
                    <IonCard>
                        <img className="logo" src="assets/img/logo.svg" width="180" height="80" alt="{process.env.REACT_APP_BRAND_NAME}" />
                        <IonCardHeader>
                            <IonCardTitle style={{ 'fontSize': '24px', 'fontWeight': 400, 'lineHeight': 1.5 }}>
                                {t('forgot-password.title')}
                            </IonCardTitle>
                            <IonCardSubtitle>
                                {t('forgot-password.explain')}
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {errors.length > 0 &&
                                <IonNote style={{
                                    'width': '100%',
                                    'backgroundColor': 'red',
                                    'color': 'white',
                                    'padding': '8px',
                                    'fontWeight': 900
                                }}>
                                    {errors}
                                </IonNote>
                            }
                            {message && <Message message={message} />}

                            <form onSubmit={handleSubmit}>
                                <IonItem>
                                    <IonInput name="email" type="email" ref={email}
                                              label="E-mail" labelPlacement="floating"
                                              placeholder={t('forgot-password.email_placeholder') as string}
                                        // helperText={t('login.supply_email') as string}
                                        // errorText="Ongeldig e-mailadres"
                                              autofocus={true} required
                                    />
                                </IonItem>

                                <IonButton className="buttonLogin ion-margin-top" color="success"
                                           disabled={inProgress} expand="full" type="submit">
                                    {t('forgot-password.request_password_reset')}
                                </IonButton>

                                <p>
                                    <IonButton className="buttonForgot ion-margin-top" color="dark" expand="full"
                                               fill="clear" routerLink="/login">
                                        {t('forgot-password.back_to_login')}
                                    </IonButton>
                                </p>
                            </form>

                        </IonCardContent>
                        <div className="ion-text-center ion-padding-top">
                            <LanguageSelector color="medium" />
                        </div>
                        <div className="ion-text-center ion-padding-top" style={{ 'fontSize': '12px' }}>
                            <p>
                                &copy; 2022-{new Date().getFullYear()}, {process.env.REACT_APP_BRAND_NAME},
                                v{process.env.REACT_APP_VERSION}
                            </p>
                        </div>
                    </IonCard>

                </div>
            </IonContent>
        </IonPage>
    );
};

export default ForgotPassword