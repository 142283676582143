import React            from "react"
import {useHistory}     from "react-router"
import {useTranslation} from "react-i18next"


import {IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"
import {ellipsisHorizontalOutline, ellipsisHorizontalSharp}       from "ionicons/icons"

import {SyncCollectionItem, SyncCollections} from "../../types/SyncCollections";
import LockingSystem                         from "../../types/LockingSystem";
import {useLockingSystems} from "../../contexts/LockingSystemContext";
import ButtonSync from "./ButtonSync";

interface componentProps {
    item: LockingSystem
}

const SyncCollectionsList: React.FC<componentProps> = ({item}) => {

    const history = useHistory()
    const { t } = useTranslation()

    const {changeSelectedItemId, hasFeature: hasLockingSystemFeature, isSynchronizing} = useLockingSystems()

    const iconSub: JSX.Element = <IonIcon style={{ 'marginRight': '12px' }}
                             ios={ellipsisHorizontalOutline} md={ellipsisHorizontalSharp} />

    const goTo = (ev: any, segment: string) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (isSynchronizing === null) {
            changeSelectedItemId(item.id)
            history.push('/' + segment)
        }
    }

    const collectionName = (collectionItem: SyncCollectionItem) => {
        return (
            <IonLabel>
            {collectionItem.sub === true && iconSub}
            {t(collectionItem.name)}
            </IonLabel>
        )
    }

    const syncElement = (collectionItem: SyncCollectionItem): JSX.Element => {
        if (isSynchronizing === collectionItem.key) {
            return (<IonNote slot="end">{t('common.busy')}...</IonNote>)
        }

        return (
            <ButtonSync collectionItem={collectionItem} lockingSystemItem={item} />
        )
    }

    return (
        <IonItemGroup key={'locking-system-' + item.id + '-view-linked'}>
            <IonItemDivider>{t('common.linked')}</IonItemDivider>
            {SyncCollections.map((collectionItem: SyncCollectionItem, index) => {
                const count = item.counts[collectionItem.key] || 0

                if (collectionItem.needsFeature) {
                    if (! hasLockingSystemFeature(collectionItem.needsFeature)) return (<div></div>)
                }

                return (
                    <IonItem button detail={true} key={'counter-' + item.id + '-' + collectionItem.key} lines="full"
                             onClick={(ev) => goTo(ev, collectionItem.url)}>
                        {collectionName(collectionItem)}
                        <IonNote className="ion-margin-end" style={{ 'textOverflow': 'unset' }} slot="end">
                            {count || '-'}
                        </IonNote>
                        {item.lockingSystemApi?.id && syncElement(collectionItem)}
                    </IonItem>
                )
            })}
        </IonItemGroup>
    )
}

export default SyncCollectionsList