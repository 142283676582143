import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound          from "../common/Items/ItemNotFound"
import IconLockingSystemZone from "../common/Icons/LockingSystemZone"


const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/locking-system-zones'
    const icon: JSX.Element = <IconLockingSystemZone link={link} size="large" />

    return (
        <ItemNotFound icon={icon} link={link} subTitle={t('locking-system-zones.not_found')} />
    )
}

export default NotFound