import React from "react"
import {useTranslation} from "react-i18next"

import {
    IonAvatar, IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonList,
    IonMenuButton, IonPopover, IonTitle, IonToolbar
} from "@ionic/react";
import {logOutOutline, logOutSharp, notificationsOutline, notificationsSharp} from "ionicons/icons"

import {useAuth} from "../../../contexts/AuthContext";


const NavBar: React.FC = () => {
    const [
        isOpenUserMenu, setIsOpenUserMenu
    ] = React.useState<boolean>(false)
    const [
        isOpenNotificationMenu, setIsOpenNotificationMenu
    ] = React.useState<boolean>(false)

    const { userInfo } = useAuth()
    const { t} = useTranslation()

    const nameParts: string[] = userInfo?.user.name.split(' ')

    const nameInitials: string = nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0)

    return (
        <IonHeader>
            <IonToolbar className="navBar" style={{ 'padding': '4px 0' }}>
                <IonButtons slot="start">
                    <IonMenuButton style={{ '--color': 'white' }} />
                </IonButtons>
                <IonTitle style={{ 'paddingInlineStart': '60px', 'color': '#ffffff', 'textAlign': 'left', 'fontSize': '20px' }}>
                    {process.env.REACT_APP_NAME}
                </IonTitle>
                <IonButtons slot="end" style={{ 'paddingInlineEnd': '10px' }}>
                    <IonButton id="notificationMenu" onClick={() => setIsOpenNotificationMenu(true)}>
                        <IonIcon slot="icon-only" ios={notificationsOutline} md={notificationsSharp} color="bc-white" />
                    </IonButton>
                    <IonButton id="userMenu" onClick={() => setIsOpenUserMenu(true)}
                               style={{ 'height': '48px' }}>
                        <IonAvatar color="medium"
                                   style={{ 'backgroundColor': '#888', 'color': 'white',
                                       'width': '36px', 'height': '36px',
                                       'fontSize': '16px',
                                       'textAlign': 'center', 'lineHeight': '36px',
                                       'cursor': 'pointer'
                                   }}>
                            {nameInitials}
                        </IonAvatar>
                    </IonButton>
                </IonButtons>

                <IonPopover reference="trigger" side="bottom" alignment="center" trigger="notificationMenu"
                            size="auto" isOpen={isOpenNotificationMenu}
                            style={{ '--min-width': '270px' }}
                >
                    <div className="ion-padding ion-text-center">
                        <h4>{t('notifications.title_list')}</h4>
                        <p>{t('notifications.no_new_notifications')}</p>
                    </div>
                </IonPopover>

                <IonPopover arrow={false} reference="trigger" side="bottom" alignment="end" trigger="userMenu"
                            size="auto" isOpen={isOpenUserMenu}
                            style={{ '--min-width': '270px' }}
                    >
                    <div className="ion-padding ion-text-center ion-text-nowrap">
                        <h5>
                            {userInfo?.account?.name}
                        </h5>
                        <IonAvatar color="medium"
                                   style={{ 'backgroundColor': 'var(--ion-color-bc-blue)', 'color': 'white',
                                       'width': '48px', 'height': '48px',
                                       'fontSize': '22px',
                                       'textAlign': 'center', 'lineHeight': '48px',
                                       'margin': '0 auto'
                                   }}>
                            {nameInitials}
                        </IonAvatar>
                        <h4>{userInfo?.user.name}</h4>
                        <p style={{ 'color': '#888888', 'marginTop': '4px' }}>
                            {userInfo?.user.email}
                        </p>
                        {/*<p>*/}
                        {/*    <IonButton color="medium" size="small">*/}
                        {/*        Manage Account*/}
                        {/*    </IonButton>*/}
                        {/*</p>*/}
                    </div>
                    <IonList lines="full" style={{ 'borderTop': '1px solid #ccc' }}>
                        <IonItem lines="none" button routerLink={'/logout'}>
                            <IonIcon ios={logOutOutline} md={logOutSharp} slot="start" />
                            {t('menu.logout')}
                        </IonItem>
                    </IonList>
                </IonPopover>
            </IonToolbar>
        </IonHeader>
    )
}

export default NavBar