import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon} from "@ionic/react"
import {chevronForwardOutline, chevronForwardSharp} from "ionicons/icons";

interface buttonProps {
    disabled?: boolean
}

const FormButtonSaveNew: React.FC<buttonProps> = ({disabled}) => {

    const { t } = useTranslation()

    disabled ??= false

    return (
        <IonButton color="bc-yellow" disabled={disabled} fill="solid" type="submit">
            {t('common.save_new')}
            <IonIcon slot="end" ios={chevronForwardOutline} md={chevronForwardSharp} />
        </IonButton>
    )
}

export default FormButtonSaveNew
