import React from 'react'
import {IonIcon, IonItemOption, useIonAlert} from "@ionic/react";
import {trashOutline, trashSharp} from "ionicons/icons";
import {useTranslation} from "react-i18next";

import {default as ItemType  } from "../../types/LockingSystemPermission";
import {useLockingSystemPermissions as useItems} from "../../contexts/LockingSystemPermissionContext";

interface componentProps {
    item: ItemType;
}

const ListItemOptionDelete: React.FC<componentProps> = ({item}) => {

    const { deleteItem } = useItems()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertDelete = async () => {
        const
            subHeaderTxt: string = 'Let op: Een recht kan alleen verwijderd worden als het niet gebruikt wordt.',
            messageTxt: string = 'Bevestig om door te gaan:'

        return presentAlert({
            header:  'Recht verwijderen?',
            subHeader: subHeaderTxt,
            message: messageTxt,
            buttons: [
                {
                    text:    t('common.cancel'),
                    role:    'cancel',
                    handler: () => onCancel(),
                },
                {
                    text:    t('common.delete'),
                    role:    'confirm',
                    handler: () => onDelete(),
                },
            ],
        })
    }

    const onCancel = () => {
        closeItemSliding()
    }

    const onDelete = () => {
        closeItemSliding()

        deleteItem(item.id)
    }

    return (
        <IonItemOption color="danger" onClick={() => alertDelete()}>
            <IonIcon slot="top" ios={trashOutline} md={trashSharp} />
            {t('common.delete')}
        </IonItemOption>
    )
}

export default ListItemOptionDelete