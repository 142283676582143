import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon, useIonAlert} from "@ionic/react"
import {alertOutline, alertSharp, trashOutline, trashSharp} from "ionicons/icons"

interface props {
    headerRemovableTxt:     string
    headerNotRemovableTxt:  string
    isRemovable?:           boolean
    messageRemovableTxt:    string
    messageNotRemovableTxt: string
    onDelete:               any
}

const ListItemButtonDelete: React.FC<props> = ({
    headerRemovableTxt, headerNotRemovableTxt, isRemovable = true,
    messageRemovableTxt, messageNotRemovableTxt, onDelete
}: props) => {

    const { t } = useTranslation()

    const [ presentAlert ] = useIonAlert()

    return (
        <IonButton fill="clear" slot="end" onClick={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()

            if (isRemovable) {
                return presentAlert({
                    header:  headerRemovableTxt,
                    message: messageRemovableTxt,
                    buttons: [{
                        text: t('common.cancel'),
                        role: 'cancel'
                    }, {
                        text:    t('common.delete'),
                        role:    'confirm',
                        handler: onDelete,
                    },],
                })
            }

            return presentAlert({
                header:  headerNotRemovableTxt,
                message: messageNotRemovableTxt,
                buttons: [{
                    text: t('common.cancel'),
                    role: 'cancel',
                }]
            })


        }}>
            {isRemovable ?
                <IonIcon color="medium" ios={trashOutline} md={trashSharp} slot="icon-only" /> :
                <IonIcon color="medium" ios={alertOutline} md={alertSharp} slot="icon-only" />
            }
        </IonButton>
    )
}

export default ListItemButtonDelete