import React               from "react"
import {useTranslation}    from "react-i18next"

import IconLockingSystem from "../common/Icons/LockingSystem"
import ItemNotFound      from "../common/Items/ItemNotFound"


const NotFound: React.FC = () => {

    const { t } = useTranslation(),
        collection: string = 'locking-systems'

    return (
        <ItemNotFound icon={<IconLockingSystem link={`/${collection}`} size="large" />}
                      link={`/${collection}`}
                      subTitle={t(`${collection}.not_found`)} />
    )
}

export default NotFound