import React from "react"
import {useTranslation} from "react-i18next"
import {IonButton, IonIcon, useIonAlert} from "@ionic/react"
import {chevronForwardOutline, chevronForwardSharp} from "ionicons/icons"

import KeyType from "../../types/Key";
import {useKeys as useItems} from "../../contexts/KeyContext";


interface componentProps {
    item: KeyType;
}

const ButtonReRegister: React.FC<componentProps> = ({item}) => {

    const [presentAlert] = useIonAlert()
    const { t } = useTranslation()
    const { reRegisterKeyPhone }  = useItems()

    const alertReRegister = async() => {
        return presentAlert({
            header: 'Telefoon opnieuw registreren?',
            buttons: [
                {
                    text:    t('common.cancel'),
                    role:    'cancel'
                },
                {
                    text:    t('common.continue'),
                    role:    'confirm',
                    handler: () => onReRegister(),
                },
            ]
        })
    }

    const onReRegister = () => {
        if (item.keyPhone) {
            reRegisterKeyPhone(item.keyPhone)
        }
    }

    return (
        <IonButton type="button" color="bc-yellow" className="ion-padding-vertical"
                   onClick={() => alertReRegister()}>
            {t('keyPhones.form.ReRegister')}
            <IonIcon ios={chevronForwardOutline} md={chevronForwardSharp} slot="end"/>
        </IonButton>
    )
}

export default ButtonReRegister