import ApiClient from "./ApiClient"
import {default as ItemType} from "../types/LockingSystemZone"
import SyncResult from "../types/SyncResult"

export const LockingSystemZoneApi = {

    apiPath: 'locking-system-zones',

    getAll: async (search?: string): Promise<ItemType[]> => {
        const items: ItemType[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get(LockingSystemZoneApi.apiPath, { params });

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    add: async (newItem: ItemType, handleAddItem: (id: number) => void): Promise<ItemType> => {

        const result = await ApiClient.post(LockingSystemZoneApi.apiPath, newItem)

        handleAddItem(result.data.data)

        return result?.data.data
    },

    edit: async (item: ItemType): Promise<any> => {

        const result = await ApiClient.put(LockingSystemZoneApi.apiPath + '/' + item.id, item);

        return result.data?.data;
    },

    delete: async(id: number): Promise<void> => {

        await ApiClient.delete(LockingSystemZoneApi.apiPath + '/' + id);

        return
    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {

        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(LockingSystemZoneApi.apiPath + '/sync/all', { params })

        return result?.data
    },

}