import React, {FormEvent, useRef} from "react"
import {useTranslation} from "react-i18next"

import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonInput, IonItem, IonNote, IonPage, useIonViewWillEnter
} from "@ionic/react"

import LanguageSelector from "../../components/common/LanguageSelector"
import {useAuth} from "../../contexts/AuthContext"
import {RouteComponentProps, useHistory} from "react-router";
import Message from "../../components/common/Message";

interface props extends RouteComponentProps<{
    uuid:  string
    token: string
}> {}

const ResetPassword: React.FC<props> = ({ match}) => {

    const { t} = useTranslation()

    const history= useHistory()

    const { handleResetPassword, errors, clearErrors, message } = useAuth()

    const passwordRef = useRef<HTMLIonInputElement>(null)

    useIonViewWillEnter(() => {
        if (clearErrors !== undefined) {
            clearErrors()
        }
    })

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const password: string = (passwordRef.current?.value || '').toString()

        const result = await handleResetPassword(password, match.params.uuid, match.params.token)

        if (result) history.push('/login')
    }

    return (
        <IonPage>
            <IonContent className="login" forceOverscroll={false}>
                <div className="bc-flex-center">
                    <IonCard>
                        <img className="logo" src="assets/img/logo.svg" width="180" height="80" alt="{process.env.REACT_APP_BRAND_NAME}" />
                        <IonCardHeader>
                            <IonCardTitle style={{ 'fontSize': '24px', 'fontWeight': 400, 'lineHeight': 1.5 }}>
                                {t('reset-password.title')}
                            </IonCardTitle>
                            <IonCardSubtitle>
                                {t('reset-password.explain')}
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {errors.length > 0 &&
                                <IonNote style={{
                                    'width': '100%',
                                    'backgroundColor': 'red',
                                    'color': 'white',
                                    'padding': '8px',
                                    'fontWeight': 900
                                }}>
                                    {errors}
                                </IonNote>
                            }
                            {message && <Message message={message} />}

                            <form onSubmit={handleSubmit}>
                                <IonItem>
                                    <IonInput name="password" type="password" ref={passwordRef}
                                              label={t('reset-password.new_password') as string} labelPlacement="floating"
                                              minlength={8} maxlength={128}
                                              placeholder={t('reset-password.new_password') as string}
                                        // helperText={t('login.supply_password') as string}
                                        // errorText="Ongeldig wachtwoord"
                                              required
                                    />
                                </IonItem>

                                <IonButton type="submit" className="buttonLogin ion-margin-top" color="success" expand="full">
                                    {t('reset-password.reset_password')}
                                </IonButton>

                                <p>
                                    <IonButton className="buttonForgot ion-margin-top" color="dark" expand="full"
                                               fill="clear" routerLink="/login">
                                        {t('forgot-password.back_to_login')}
                                    </IonButton>
                                </p>
                            </form>

                        </IonCardContent>
                        <div className="ion-text-center ion-padding-top">
                            <LanguageSelector color="medium" />
                        </div>
                        <div className="ion-text-center ion-padding-top" style={{ 'fontSize': '12px' }}>
                            <p>
                                &copy; 2022-{new Date().getFullYear()}, {process.env.REACT_APP_BRAND_NAME},
                                v{process.env.REACT_APP_VERSION}
                            </p>
                        </div>
                    </IonCard>

                </div>
            </IonContent>
        </IonPage>
    );

}

export default ResetPassword