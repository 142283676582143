import React from "react"
import {RouteComponentProps} from "react-router"
import {Route} from "react-router-dom"

import {IonPage, IonRouterOutlet} from "@ionic/react"

import PageLoginForm   from "../pages/Auth/LoginForm"
import PageLoginCustom from "../pages/Auth/LoginCustom"

const Login: React.FC<RouteComponentProps> = ({match}) => {
    return (
        <IonPage>
            <IonRouterOutlet>
                <Route exact path={match.url}          component={PageLoginForm}   />
                <Route       path={`${match.url}/:id`} component={PageLoginCustom} />

            </IonRouterOutlet>
        </IonPage>
    )
}

export default Login