import React from "react"

import Person         from "../../../types/Person"
import IconPerson     from "../../common/Icons/Person"
import ItemViewHeader from "../../common/Headers/ItemViewHeader"

export interface componentProps {
    item: Person
}

const Header: React.FC<componentProps> = ({item}) => {

    const
        linkSegment = item.dataSourceId ? 'view' : 'form',
        text = item.firstName || item.lastName ? item.firstName + ' ' + item.lastName : '',
        title = text ? text : (item.email ? item.email : '')

    return <ItemViewHeader
        icon={<IconPerson link={'/people/' + item.id + '/' + linkSegment} text={text} />}
        title={title}
        subTitle={item.description || ''}
    />

}

export default Header