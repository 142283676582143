import React from "react"
import {useTranslation} from "react-i18next"

import LockingSystemZone from "../../types/LockingSystemZone"
import IconLockingSystemZone from "../common/Icons/LockingSystemZone"
import ItemViewHeader from "../common/Headers/ItemViewHeader"


export interface componentProps {
    item: LockingSystemZone
}

const ViewHeader: React.FC<componentProps> = ({item}) => {

    const { t } = useTranslation()

    return <ItemViewHeader
        icon={<IconLockingSystemZone link={'/locking-system-zones/' + item.id + '/form'}
                                           text={item.name} />}
        title={item.name}
        subTitle={item?.description || '(' + t('common.no_description') + ')'}
    />

}

export default ViewHeader