import React from 'react'
import {useTranslation} from "react-i18next"

import {IonIcon, IonItemOption, useIonAlert}  from "@ionic/react"
import {closeCircleOutline, closeCircleSharp} from "ionicons/icons"


import KeyType from "../../../types/Key"
import {useKeys as useItems} from "../../../contexts/KeyContext"

interface componentProps {
    item: KeyType;
}

const ListItemOptionBlock: React.FC<componentProps> = ({item}) => {
    const { addToBlocklist } = useItems()
    const [presentAlert]     = useIonAlert()
    const { t }              = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertBlock = async () => {
        return presentAlert({
            header:  t('keys.alerts.block.header'),
            message: t('keys.alerts.block.message'),
            buttons: [{
                text:    t('common.cancel'),
                role:    'cancel',
                handler: () => onCancel(),
            }, {
                text:    t('common.block'),
                role:    'confirm',
                handler: () => onBlock(),
            },],
        })
    }

    const onCancel = () => { closeItemSliding() }

    const onBlock = () => {
        closeItemSliding()
        addToBlocklist(item)
    }

    return (
        <IonItemOption color="dark" onClick={() => alertBlock()}>
            <IonIcon slot="top" ios={closeCircleOutline} md={closeCircleSharp} />
            {t('common.block')}
        </IonItemOption>
    )
}

export default ListItemOptionBlock