import React from "react"
import {useHistory} from "react-router"
import {useTranslation} from "react-i18next"

import {IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonNote, IonRow} from "@ionic/react"

import {LockType as ItemType} from "../../types/Lock"
import { useLocks }           from "../../contexts/LockContext"
import FormButtonSaveChanges from "../common/Buttons/FormButtonSaveChanges";

export interface formProps {
    item: ItemType | undefined;
}


const Form: React.FC<formProps> = ({ item}) => {
    const history = useHistory()

    const { t } = useTranslation()

    const
        [description, setDescription] = React.useState<string>(item?.description || ''),
        [locationCode, setLocationCode] = React.useState<string>(item?.locationCode || ''),
        [lockingObject, setLockingObject] = React.useState<string>(item?.lockingObject || '')

    const
        descriptionRef   = React.useRef<any>(''),
        locationCodeRef = React.useRef<any>(''),
        lockingObjectRef = React.useRef<any>('')

    const { editItem } = useLocks()

    const itemById = item;

    const handleSave = async (e: any) => {
        e.preventDefault();

        setDescription(descriptionRef.current.value)
        setLocationCode(locationCodeRef.current.value)
        setLockingObject(lockingObjectRef.current.value)

        const item: ItemType = {
            id:            itemById ? itemById.id : 0,
            description:   descriptionRef.current.value,
            locationCode:  locationCodeRef.current.value,
            lockingObject: lockingObjectRef.current.value,
        };

        if (item && item.id) {
            const result = await editItem(item);

            if (result !== undefined) {
                history.push('/locks')
            }
        }
    }

    const FormKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            handleSave(e);
        }
    }

    return (
        <IonList className="maxWidth">
        <form method="POST" id="formLock" onSubmit={handleSave} onKeyUp={ e => FormKeyUp(e) }>
            <IonItem>
                <IonLabel>{t('locks.type')}</IonLabel>
                <IonNote slot="end">{itemById?.lockType?.name}</IonNote>
            </IonItem>
            <IonItem>
                <IonInput name="location_code" type="text" ref={locationCodeRef}
                          label={t('locks.form.location_code') as string} labelPlacement="stacked"
                          value={locationCode}
                          placeholder={t('locks.form.location_code') as string}
                          clearInput={true}
                />
            </IonItem>
            <IonItem>
                <IonInput ref={lockingObjectRef} name="locking_object" type="text"
                          label={t('locks.form.object') as string} labelPlacement="stacked"
                          value={lockingObject}
                          clearInput={true}
                          placeholder={t('locks.form.object') as string} required />
            </IonItem>
            <IonItem>
                <IonInput name="description" type="text" ref={descriptionRef}
                          label={t('locks.form.description') as string} labelPlacement="stacked"
                          value={description}
                          placeholder={t('locks.form.description') as string}
                          clearInput={true}
                          autofocus={true}
                />
            </IonItem>

            <IonGrid className="ion-margin-top">
                <IonRow>
                    <IonCol className="ion-text-right">
                        <FormButtonSaveChanges />
                    </IonCol>
                </IonRow>
            </IonGrid>

        </form>
        </IonList>
    );
}

export default Form