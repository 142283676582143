import React               from "react"
import {useTranslation}    from "react-i18next"

import ItemNotFound from "../common/Items/ItemNotFound"
import IconPerson   from "../common/Icons/Person"

const NotFound: React.FC = () => {

    const { t } = useTranslation()

    const link: string = '/people'

    return (
        <ItemNotFound icon={<IconPerson link={link} size="large" />}
                      link={link}
                      subTitle={t('common.item_not_found', { itemType: t('people.title_item').toLowerCase() })} />
    )
}

export default NotFound