import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import LockingSystemPermission from "../../types/LockingSystemPermission"

export interface props {
    item: LockingSystemPermission
}

const ViewItemGroupInfo: React.FC<props> = ({item}: props) => {

    const {t} = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.info')}</IonItemDivider>
            {item.lockingSystemPermissionType &&
                <IonItem lines="full">
                    <IonLabel>{t('common.type')}</IonLabel>
                    <IonNote slot="end">{item.lockingSystemPermissionType.name || '-'}</IonNote>
                </IonItem>
            }
            <IonItem lines="full">
                <IonLabel>{t('common.hidden_by_default')}</IonLabel>
                <IonNote color={item.isHiddenByDefault ? "warning" : 'undefined'} slot="end">
                    {item.isHiddenByDefault ? t('common.yes') : t('common.no')}
                </IonNote>
            </IonItem>
            {item.individualLockId &&
                <IonItem lines="full">
                    <IonLabel>{t('locking-system-permissions.individual_permission')}</IonLabel>
                    <IonNote slot="end">{t('common.yes')}</IonNote>
                </IonItem>
            }
            <IonItem lines="full">
                <IonLabel>{t('common.synced_at')}</IonLabel>
                <IonNote slot="end">{item.meta?.syncedAt || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ViewItemGroupInfo