import React from "react";

import { DE as FlagDE, GB as FlagEN, NL as FlagNL } from 'country-flag-icons/react/3x2'
import {IonButton, IonIcon, IonItem, IonList, IonListHeader, IonPopover} from "@ionic/react";
import {globeOutline, globeSharp} from "ionicons/icons";
import {useTranslation} from "react-i18next";

interface componentProps {
    color: string;
}

const LanguageSelector: React.FC<componentProps> = ({ color = 'medium' }) => {

    const { t, i18n } = useTranslation()
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)

        setIsOpen(false)
    }

    return (
        <>
        <IonButton size="small" color={color} fill="outline" id="languagePopover"
                   onClick={() => setIsOpen(true)}
                   style={{ '--padding-top': '6px', '--padding-bottom': '6px', 'height': '36px' }}
        >
            <IonIcon slot="start" ios={globeOutline} md={globeSharp}/>
            {t('common.language_current')}
        </IonButton>
        <IonPopover reference="trigger" arrow={false}
                    dismissOnSelect={true}
                    side="top" alignment="center"
                    trigger="languagePopover"
                    size="auto"
                    className="languagePopover"
                    isOpen={isOpen}>
            <IonList>
                <IonListHeader lines="full" style={{
                    'fontSize': '20px',
                    'paddingBottom': '8px'
                }}>{t('common.change_language')}:</IonListHeader>
                <IonItem button onClick={() => changeLanguage('en')} disabled={i18n.language === 'en'}>
                    <FlagEN title="English" style={{'width': '20px', 'marginRight': '8px'}}/>
                    English
                </IonItem>
                <IonItem button onClick={() => changeLanguage('de')} disabled={i18n.language === 'de'}>
                    <FlagDE title="English" style={{'width': '20px', 'marginRight': '8px'}}/>
                    Deutsch
                </IonItem>
                <IonItem button onClick={() => changeLanguage('nl')} disabled={i18n.language === 'nl'}>
                    <FlagNL title="English" style={{'width': '20px', 'marginRight': '8px'}}/>
                    Nederlands
                </IonItem>
            </IonList>
        </IonPopover>
        </>
    )
}

export default LanguageSelector