import React            from "react"
import {useHistory}     from "react-router"
import {useTranslation} from "react-i18next"

import {IonButton, IonIcon, useIonAlert}        from "@ionic/react"
import {returnUpBackOutline, returnUpBackSharp} from "ionicons/icons"

import KeyType from "../../types/Key"
import {useKeys as useItems} from "../../contexts/KeyContext"

interface componentProps {
    item: KeyType;
}


const ButtonReturn: React.FC<componentProps> = ({item}) => {
    const { inProgress, returnKeyPhone }  = useItems()
    const history        = useHistory()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const alertReturn = async () => {
        return presentAlert({
            header: t('keys.alerts.return.header'),
            message: t('keys.alerts.return.message'),
            buttons: [
                {
                    text:    t('common.cancel'),
                    role:    'cancel'
                },
                {
                    text:    t('keys.form.return_key'),
                    role:    'confirm',
                    handler: () => onReturn(),
                },
            ],
        })
    }

    const onReturn = async () => {
        if (item.keyPhone) {
            await returnKeyPhone(item.keyPhone)

            history.push('/keys-in-use')
        }
    }

    return (
        <IonButton disabled={inProgress} type="button" color="tertiary" fill="outline" className="ion-padding-start"
                   onClick={() => alertReturn()}>
            <IonIcon slot="start" ios={returnUpBackOutline} md={returnUpBackSharp} />
            {t('keys.form.return_key')}
        </IonButton>
    )
}

export default ButtonReturn
