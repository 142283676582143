import React from 'react'
import {useHistory}     from "react-router"
import {useTranslation} from "react-i18next"

import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel} from "@ionic/react"
import {banOutline, banSharp, shieldCheckmarkOutline, shieldCheckmarkSharp} from "ionicons/icons"

import {Key as ItemType}   from "../../types/Key"

import ListItemOptionBlock  from "../Keys/List/ListItemOptionBlock"
import ListItemOptionDelete from "../Keys/List/ListItemOptionDelete"

import IconKeyInSafe from "../common/Icons/KeyInSafe"
import BadgeKeyProgrammingState from "../common/Badges/BadgeKeyProgrammingState";

interface props {
    item: ItemType
}

const ListItem: React.FC<props> = ({ item }: props) => {
    const keyLockingSystemPermissionsCount: number = item.lockingSystemPermissions?.length || 0;
    const itemBaseUrl: string         = '/keys-in-safe/'

    const { t }   = useTranslation()
    const history = useHistory()

    const goToKeyLockingSystemPermissions = (ev: any, itemId: number) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push(itemBaseUrl + itemId + '/lockingSystemPermissions')
    }

    const itemTitle = (): string => {
        return ('SPARE_KEY' === item.description)
            ? t('keys.spare_key')
            : item.description || '-'
    }

    return (
        <>
            <IonItem button detail routerLink={itemBaseUrl + item.id + '/view'} slot="start">
                <IconKeyInSafe icon={item?.keyType?.icon} slot="start" />
                <IonLabel>
                    {itemTitle()}
                    {item.label &&
                        <span style={{ 'fontWeight': 300 }}>&nbsp;- {item.label}</span>
                    }
                    <span style={{ 'fontWeight': '200', 'fontSize': '14px' }}>
                        &nbsp;- {item.keyType?.lockingSystemType?.brand?.name} - {item.keyType?.name}
                    </span>
                    <p>{t('keys.form.serial_number')}: {item.serial}</p>
                </IonLabel>
                {item.keyProgrammingState &&
                    <BadgeKeyProgrammingState item={item.keyProgrammingState} />
                }
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton color="medium" fill="clear"
                               // onClick={ (ev) => goToKeyLockingSystemPermissions(ev, item.id) }
                               size="large" style={{ 'fontSize': '17px' }}
                    >
                        {keyLockingSystemPermissionsCount > 1 ? keyLockingSystemPermissionsCount : '-'}
                        <IonIcon color="bc-blue" slot="end"
                                 ios={keyLockingSystemPermissionsCount ? shieldCheckmarkOutline : banOutline}
                                 md={keyLockingSystemPermissionsCount  ? shieldCheckmarkSharp   : banSharp} />
                    </IonButton>
                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                <ListItemOptionBlock  item={item} />
                <ListItemOptionDelete item={item} />
            </IonItemOptions>
        </>
    )
}

export default ListItem