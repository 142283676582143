import React from "react"
import {useTranslation} from "react-i18next"

import {IonCol, IonGrid, IonItem, IonItemSliding, IonLabel, IonList, IonModal, IonRow} from "@ionic/react"

import Key                     from "../../../types/Key"
import LockingSystemPermission from "../../../types/LockingSystemPermission"

import {useKeys}                     from "../../../contexts/KeyContext";
import {useLockingSystemPermissions} from "../../../contexts/LockingSystemPermissionContext"

import SelectLockingSystemPermissions from "../../common/Modal/SelectLockingSystemPermissions"
import SelectOption                   from "../../../types/SelectOptions/LockingSystemPermissionSelectOption"

import ButtonDeleteAll       from "./ButtonDeleteAll"
import ListItem              from "./ListItem"
import ItemSegmentListHeader from "../../common/Headers/ItemSegmentListHeader";

interface props {
    item: Key
}


const List: React.FC<props> = ({ item }) => {

    const { t } = useTranslation()

    const { addLockingSystemPermissions } = useKeys()
    const { selectOptions, fetchSelectOptions } = useLockingSystemPermissions()

    const [ modalReady, setModalReady ] = React.useState<boolean>(false)
    const [
        selectedLockingSystemPermissions, setSelectedLockingSystemPermissions
    ] = React.useState<number[]>([])

    const selectModal = React.useRef<HTMLIonModalElement>(null)

    const hasRemoveAll = (): boolean => {

        if (item.lockingSystemPermissions?.length) {
            const notRemovableFromKey: LockingSystemPermission | undefined
                = item.lockingSystemPermissions.find(p => ! p.options?.isRemovableFromKey)

            return ! notRemovableFromKey
        }

        return false
    }

    React.useEffect(() => {
        setModalReady(false)

        // console.log('setTimeout modal ready')

        setTimeout(() => setModalReady(true), 750)

    }, [item.lockingSystemPermissions])

    React.useEffect(() => {
        const fetch = async () => {
            fetchSelectOptions()
        }

        fetch()

    }, [selectOptions])

    const filterAvailableLockingSystemPermissions = (): SelectOption[] | [] => {
        const availableLockingSystemPermissions: SelectOption[] = []

        const existingPermissions: LockingSystemPermission[] = item?.lockingSystemPermissions || []

        if (existingPermissions && selectOptions) {
            selectOptions.forEach((selectOption: SelectOption) => {
                const found = existingPermissions
                    .find(eLSP => selectOption.id === eLSP.id)

                if (undefined === found && selectOption.options.isAddableToKey) {
                    availableLockingSystemPermissions.push(selectOption)
                }
            })
        }

        return availableLockingSystemPermissions
    }

    const lockingSystemPermissionSelectionChanged = async (lockingSystemPermissions: number[]) => {

        setSelectedLockingSystemPermissions(lockingSystemPermissions);
        selectModal.current?.dismiss();

        if (lockingSystemPermissions.length > 0) {
            setModalReady(false)

            await addSelectedPermissions(lockingSystemPermissions)
        }
    }

    const addSelectedPermissions = async (lockingSystemPermissions: number[]) => {
        if (undefined === item || lockingSystemPermissions.length < 1) return

        await addLockingSystemPermissions(item, lockingSystemPermissions)

        setSelectedLockingSystemPermissions([])
    }

    const listButtons = () => {
        if (hasRemoveAll()) {
            return (
                <IonCol className="ion-text-center">
                    {item?.lockingSystemPermissions && item.lockingSystemPermissions?.length > 0
                        && <ButtonDeleteAll item={item} setSelectedLockingSystemPermissions={setSelectedLockingSystemPermissions} />}
                </IonCol>
            )
        }
    }

    const listLockingSystemPermissions = (lockingSystemPermissions: LockingSystemPermission[]) => {
        if (lockingSystemPermissions.length === 0) {
            return (
                <IonItem className="ion-text-center">
                    <IonLabel className="ion-text-wrap">{t('keys.no_permissions_for_key')}</IonLabel>
                </IonItem>
            )
        }

        return lockingSystemPermissions.map((lockingSystemPermission: LockingSystemPermission) => {
            return (
                <IonItemSliding className={lockingSystemPermission.isHiddenByDefault ? 'hidden-by-default' : ''}
                                key={"item-sliding-key-locking-system-permission-" + lockingSystemPermission.id}>
                    <ListItem item={lockingSystemPermission} keyItem={item} />
                </IonItemSliding>
            )
        })
    }

    const title: string    = item?.description + (item?.label ? ' - ' + item.label : '')
    const subTitle: string = item?.keyType?.lockingSystemType?.brand?.name + ' - ' + item?.keyType?.name

    return (
        <>
            <IonList lines="full">
                <ItemSegmentListHeader title={title} subTitle={subTitle} />
                {listLockingSystemPermissions(item.lockingSystemPermissions || [])}
            </IonList>

            <IonGrid className="ion-margin-top">
                <IonRow>{listButtons()}</IonRow>
            </IonGrid>

            <IonModal trigger={modalReady ? "select-key-locking-system-permissions" : undefined} ref={selectModal}>
                <SelectLockingSystemPermissions
                    hasShowHidden={true}
                    items={filterAvailableLockingSystemPermissions()}
                    selectedItems={selectedLockingSystemPermissions}
                    selectTypeTitle={t('locking-system-permissions.title_list')}
                    title={t('locking-system-permissions.add_permissions').toString()}
                    onSelectionCancel={() => selectModal.current?.dismiss()}
                    onSelectionChange={lockingSystemPermissionSelectionChanged}
                />
            </IonModal>
        </>
    )
}

export default List