import React from "react"
import {personCircleOutline, personCircleSharp} from "ionicons/icons"

import TypeIcon from "./TypeIcon"

interface props {
    link?: string
    size?: "small" | "default" | "large"
    slot?: string
    text?: string
}

const IconUser: React.FC<props> = ({ link, size, slot, text }) => {

    return <TypeIcon
        backgroundColor={'gold'}
        link={link}
        ios={personCircleOutline}
        md={personCircleSharp}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconUser