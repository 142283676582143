import React from "react"
import {bookmarkOutline, bookmarkSharp} from "ionicons/icons"
import TypeIcon from "./TypeIcon"

interface componentProps {
    link?: string
    size?: "small" | "default" | "large"
    slot?: string
    text?: string
}

const IconLockingSystem: React.FC<componentProps> = (
    { link, size, slot, text }
) => {

    return <TypeIcon
        backgroundColor={'#0a0a0a'}
        link={link}
        ios={bookmarkOutline}
        md={bookmarkSharp}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconLockingSystem