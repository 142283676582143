import React from "react"
import {RouteComponentProps} from "react-router"
import {useTranslation} from "react-i18next"

import {useLockingSystems as useItems} from "../../contexts/LockingSystemContext"

import ItemForm from "../../components/LockingSystems/Form"
import ItemView from "../../components/LockingSystems/View"
import PageItem from "../common/Item";
import NotFound from "../../components/LockingSystems/NotFound"

interface pageProps extends RouteComponentProps<{
    id:      string
    segment: string
}> {}


const PageLockingSystemItem: React.FC<pageProps> = ({ match }) => {
    const { t }        = useTranslation()
    const { fetchItems, items} = useItems()

    const
        itemType:     string = 'LockingSystem',
        itemTypeName: string = t('locking-systems.title_item'),
        itemTypeUrl:  string = 'locking-systems'

    return (
        <PageItem
            useItems={useItems}
            fetchItems={fetchItems}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            ItemForm={ItemForm}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            segment={match.params.segment}
        />
    )
}

export default PageLockingSystemItem