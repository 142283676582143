import ApiClient from "./ApiClient"

import SyncResult from "../types/SyncResult"
import {LockLockingSystemPermissionAdd} from "../types/LockLockingSystemPermission"

export const LockLockingSystemPermissionApi = {

    apiPath: 'lock-locking-system-permissions',

    endpoint: (param: string = ''): string => {
        return param
            ? LockLockingSystemPermissionApi.apiPath + '/' + param
            : LockLockingSystemPermissionApi.apiPath
    },

    add: async (lockLockingSystemPermissions: LockLockingSystemPermissionAdd): Promise<any> => {

        const result = await ApiClient.post(
            'locks/' + lockLockingSystemPermissions.lockId + '/lockingSystemPermissions',
            lockLockingSystemPermissions
        )

        return result.data?.data
    },

    delete: async (lockId: number, lockingSystemPermissionId: number): Promise<void> => {

        return await ApiClient.delete('locks/' + lockId + '/lockingSystemPermissions/' + lockingSystemPermissionId)

    },

    deleteAll: async (lockId: number): Promise<any> => {

        return await ApiClient.delete('locks/' + lockId + '/lockingSystemPermissions/all')

    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient
            .get(LockLockingSystemPermissionApi.endpoint('sync/all'), { params })

        return result?.data
    },

}

export default LockLockingSystemPermissionApi