import ApiClient from "./ApiClient";
import {default as ItemType} from "../types/LockingSystem";

export const LockingSystemApi = {

    apiPath: 'locking-systems',

    getAll: async (query?: string): Promise<ItemType[]> => {
        const items: ItemType[] = []
        const params = new URLSearchParams(
            query ? [['search', query]] : []
        )

        const response = await ApiClient.get(LockingSystemApi.apiPath, { params });

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    add: async (newItem: ItemType, handleAddItem: (id: number) => void): Promise<void> => {

        const result = await ApiClient.post(LockingSystemApi.apiPath, newItem);

        const itemRef = result.data.data;

        handleAddItem(itemRef.id);

        return
    },

    edit: async (item: ItemType): Promise<any> => {

        const result = await ApiClient.put(LockingSystemApi.apiPath + '/' + item.id, item);

        return result.data?.data;
    },

    delete: async(id: number): Promise<void> => {

        await ApiClient.delete(LockingSystemApi.apiPath + '/' + id);

        return
    },
}