import React from "react"

import {useAuth} from "../../contexts/AuthContext";

const PageLogout: React.FC = () => {

    const { handleLogout, inProgress } = useAuth()

    React.useEffect(() => {
        const logout = async () => {
            if (inProgress === false) await handleLogout()
        }

        logout().catch(console.error)
    })

    return (
        <div></div>
    )
}

export default PageLogout