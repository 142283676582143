import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import User from "../../../types/User"


export interface componentProps {
    item: User
}

const ViewItemGroupInfo: React.FC<componentProps> = ({item} ) => {

    const {t} = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.info')}</IonItemDivider>
            <IonItem lines="full">
                <IonLabel>{t('users.form.name')}</IonLabel>
                <IonNote slot="end">{item.name || '-'}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('users.form.email')}</IonLabel>
                <IonNote slot="end">{item.email || '-'}</IonNote>
            </IonItem>
            {item.meta?.lastLoginAt &&
                <IonItem lines="full">
                    <IonLabel>{t('users.last_login_at')}</IonLabel>
                    <IonNote slot="end">{item.meta?.lastLoginAt || '-'}</IonNote>
                </IonItem>
            }
        </IonItemGroup>
    )
}

export default ViewItemGroupInfo