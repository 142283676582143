import React from 'react'
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next"

import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel} from "@ionic/react"
import {banOutline, banSharp, shieldCheckmarkOutline, shieldCheckmarkSharp} from "ionicons/icons"

import {Key as ItemType}    from "../../types/Key"

import ListItemOptionDelete from "../Keys/List/ListItemOptionDelete"
import ListItemOptionBlock  from "../Keys/List/ListItemOptionBlock"
import ListItemOptionReset  from "../Keys/List/ListItemOptionReset"

import IconKeyInUse             from "../common/Icons/KeyInUse"
import ListItemOptionReturn     from "../Keys/List/ListItemOptionReturn"
import BadgeKeyProgrammingState from "../common/Badges/BadgeKeyProgrammingState"

interface props { item: ItemType }

const ListItem: React.FC<props> = ({ item }: props) => {
    const keyLockingSystemPermissionsCount:number = item.lockingSystemPermissions?.length || 0;
    const itemBaseUrl: string = '/keys-in-use/'

    const { t }   = useTranslation()
    const history = useHistory()

    const goToKeyLockingSystemPermissions = (ev: any, itemId: number) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push(itemBaseUrl + itemId + '/lockingSystemPermissions')
    }

    return (
        <>
            <IonItem button detail routerLink={itemBaseUrl + item.id + '/view'} slot="start">
                <IconKeyInUse icon={item?.keyType?.icon} slot="start" />
                <IonLabel>
                    <h2>
                        {item.description}
                        {item.label &&
                            <span style={{ 'fontWeight': 300 }}>&nbsp;- {item.label}</span>
                        }
                        <span style={{ 'fontWeight': '200', 'fontSize': '14px' }}>
                            &nbsp;- {item.keyType?.lockingSystemType?.brand?.name} - {item.keyType?.name}
                        </span>
                    </h2>
                    <h3 style={{ 'color': "#777" }}>
                        <span>{t('common.belongs_to')} {item.person?.firstName + ' ' + item.person?.lastName}</span>
                    </h3>
                </IonLabel>
                {item.keyProgrammingState &&
                    <BadgeKeyProgrammingState item={item.keyProgrammingState} />
                }
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton color="medium" fill="clear"
                               onClick={ (ev) => goToKeyLockingSystemPermissions(ev, item.id) }
                               size="large" slot="end" style={{ 'fontSize': '17px' }}
                    >
                        {keyLockingSystemPermissionsCount ? keyLockingSystemPermissionsCount : '-'}
                        <IonIcon color="bc-blue" slot="end"
                                 ios={keyLockingSystemPermissionsCount ? shieldCheckmarkOutline : banOutline}
                                 md={keyLockingSystemPermissionsCount  ? shieldCheckmarkSharp   : banSharp} />
                    </IonButton>
                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                <ListItemOptionBlock  item={item} />
                {item.options?.isReturnable && <ListItemOptionReturn item={item} />}
                {item.options?.isResetable  && <ListItemOptionReset  item={item} />}
                {item.options?.isDeletable  && <ListItemOptionDelete item={item} />}
            </IonItemOptions>
        </>
    )
}

export default ListItem