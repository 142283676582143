import React from "react"
import {IonButton, IonIcon, useIonAlert} from "@ionic/react";
import {trashOutline, trashSharp} from "ionicons/icons";
import {useTranslation} from "react-i18next";

import KeyType from "../../types/Key";
import {useKeys as useItems} from "../../contexts/KeyContext";
import {useHistory} from "react-router";
import BlocklistKey from "../../types/BlocklistKey";

interface componentProps {
    item: KeyType;
    blocklistKey?: BlocklistKey;
}

const ButtonDelete: React.FC<componentProps> = ({item, blocklistKey}) => {
    const { deleteItem } = useItems()
    const history        = useHistory()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const alertDelete = async () => {
        return presentAlert({
            header: 'Wil je deze sleutel verwijderen?',
            buttons: [
                {
                    text:    t('common.cancel'),
                    role:    'cancel'
                },
                {
                    text:    t('common.delete'),
                    role:    'confirm',
                    handler: () => onDelete(),
                },
            ],
        })
    }

    const onDelete = () => {
        deleteItem(item)

        if (blocklistKey !== undefined) {
            history.push('/blocklist-keys')

            return
        }

        history.push(typeof item.personId === 'number' ? '/keys-in-use' : '/keys-in-safe')
    }

    return (
        <IonButton type="button" color="danger" fill="outline" className="ion-padding-start"
                   onClick={() => alertDelete()}>
            <IonIcon slot="start" ios={trashOutline} md={trashSharp} />
            {t('common.delete')}
        </IonButton>
    )
}

export default ButtonDelete
