import React from "react"
import {RouteComponentProps} from "react-router"
import {useTranslation} from "react-i18next"

import {useLockingSystemPermissions as useItems} from "../../contexts/LockingSystemPermissionContext"

import ItemForm  from "../../components/LockingSystemPermissions/Form"
import ItemView  from "../../components/LockingSystemPermissions/View";
import KeysList  from "../../components/LockingSystemPermissions/KeysList"
import LocksList from "../../components/LockingSystemPermissions/LocksList"

import PageItem from "../common/Item"
import NotFound from "../../components/LockingSystemPermissions/NotFound"

interface pageProps extends RouteComponentProps<{
    id:      string;
    segment: string;
}> {}


const PageLockingSystemPermissionsItem: React.FC<pageProps> = ({ match }) => {
    const { t }        = useTranslation()
    const {fetchItems, items} = useItems()

    const
        itemType:     string = 'LockingSystemPermission',
        itemTypeName: string = t('locking-system-permissions.title_item'),
        itemTypeUrl:  string = 'locking-system-permissions'

    return (
        <PageItem
            fetchItems={fetchItems}
            useItems={useItems}
            itemId={match.params.id}
            items={items}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            ItemForm={ItemForm}
            ItemNotFound={NotFound}
            ItemView={ItemView}
            KeysList={KeysList}
            LocksList={LocksList}
            segment={match.params.segment}
        />
    )
}

export default PageLockingSystemPermissionsItem