import React from "react"
import {useTranslation} from "react-i18next"

import {usePeople as useItems} from "../../contexts/PersonContext"

import ItemList from '../../components/People/List'
import PageList from '../common/List'

const PagePeopleList: React.FC = () => {

    const { t } = useTranslation()

    const { fetchItems } = useItems()

    const
        itemType: string     = 'People',
        itemTypeName: string = t('people.title_list'),
        itemTypeUrl: string  = 'people'

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            showAddButton={true}
            fetchItems={fetchItems}
            useItems={useItems}
        />
    )
}

export default PagePeopleList