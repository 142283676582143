import React            from "react"
import {useTranslation} from "react-i18next"

import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react"

import "./ItemPageHeader.css"
import {addOutline, addSharp} from "ionicons/icons";

type props = {
    inProgress:      boolean;
    itemId?:         number | undefined;
    itemType:        string;
    itemTypeName:    string;
    itemTypeUrl:     string
    pageHeaderTitle: string
    buttonAddShow?:  boolean
    buttonAddId?:    string
    showButtonEdit?: boolean
    showButtonSave?: boolean
}


const ItemPageHeader: React.FC<props> = ({
    inProgress, itemId, itemType, itemTypeName, itemTypeUrl, pageHeaderTitle,
    buttonAddShow, buttonAddId,
    showButtonEdit = false, showButtonSave = false
}: props) => {

    const {t} = useTranslation()

    let formId = "form" + itemType

    if (itemType === 'BlocklistKeys' || itemType === 'KeysInSafe' || itemType === 'KeysInUse') {
        formId = 'formKeys'
    }

    const buttonsEnd = () => {
        if (inProgress) return

        if (buttonAddShow) {
            return (
                <IonButton id={buttonAddId} type="button">
                    <IonIcon color="dark" slot="icon-only" ios={addOutline} md={addSharp} />
                </IonButton>
            )
        }

        if (showButtonEdit) {
            return (
                <IonButton color="dark" routerLink={`/${itemTypeUrl}/${itemId}/form`} type="button">
                    {t('common.edit')}
                </IonButton>
            )
        }

        if (showButtonSave) {
            return (<IonButton color="dark" form={formId} type="submit">{t('common.save')}</IonButton>)
        }
    }

    return (
        <IonHeader className="item-page-header">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton color="dark" defaultHref={`/${itemTypeUrl}`} text="" />
                </IonButtons>
                <IonTitle slot="start" color="bc-blue">
                    {pageHeaderTitle || itemTypeName}
                </IonTitle>
                <IonButtons slot="end">
                    {buttonsEnd()}
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}

export default ItemPageHeader