import React from "react"
import ApiClient from "./ApiClient"
import LockingSystem from "../types/LockingSystem"
import {AuthType} from "../types/Auth"

export const AuthProvider = {

    wait: function(ms: number) {
        return new Promise( (resolve) => {setTimeout(resolve, ms)});
    },

    getCsrf: async function() {
        const result = await ApiClient.get('csrf');

        return result?.status;
    },

    getMe: async function() {

        // ff paar sec wachten als test
        // await this.wait(250);

        const result = await ApiClient.get('me');

        return result.data?.data?.session;
    },

    forgotPassword: async (email: string, setErrors: React.Dispatch<any>): Promise<any> => {

        setErrors([])

        // try {
            const result = await ApiClient.post('forgot-password', {email: email})

            return result.data.status
        // } catch (err: any) {
        //     setErrors((prev: any) => ([...prev, err.response.data.message]))
        // }

    },

    resetPassword: async (password: string, uuid: string, token: string, setErrors: React.Dispatch<any>): Promise<any> => {
        setErrors([])

        try {
            const result = await ApiClient.post('reset-password', {
                password: password, uuid: uuid, token: token
            })

            return result.data.status
        } catch (err: any) {
            setErrors((prev: any) => ([...prev, err.response.data.message]))
        }
    },

    callback: async(code: string, state: string, setUserInfo: React.Dispatch<any>): Promise<any> => {
        const params: URLSearchParams = new URLSearchParams([
            ['code', code], ['state', state]
        ])

        const result = await ApiClient.get('callback', { params })

        const session: AuthType = result?.data?.data?.session;

        if (undefined !== session && session.user ) {
            setUserInfo({
                account:        session.account,
                lockingSystems: session.lockingSystems,
                user:           session.user,
            })

            AuthProvider.session(session)

        } else {
            setUserInfo(null);
        }

        return true
    },

    custom: async(customId: string): Promise<any> => {

        return await ApiClient.get('login/' + customId)

    },

    login: async (email: any, password: any, setUserInfo: React.Dispatch<any>, setErrors: React.Dispatch<any>): Promise<boolean | void> => {

        setErrors([])

        await AuthProvider.getCsrf();

        const result= await ApiClient
            .post('login', {email: email, password: password})

        const session: AuthType = result?.data?.data?.session;

        if (undefined !== session && session.user ) {
            setUserInfo({
                account:        session.account,
                lockingSystems: session.lockingSystems,
                user:           session.user,
            })

            AuthProvider.session(session)

        } else {
            setUserInfo(null);
        }

        return true

    },
    logout: async (setUserInfo: React.Dispatch<any>, setErrors: React.Dispatch<any>) => {
        setErrors([]);

        try {
            const result = await ApiClient.get('logout')

            if (result.data.url !== undefined && result.data.url !== null) {
                window.location.replace(result.data.url)
            } else {
                window.location.replace('/login')
            }
        } catch (err) {
            setErrors((prev: any) => ([...prev, err]))
            setUserInfo(null)
        }
    },

    observe: async (setUserInfo: React.Dispatch<any>): Promise<void> => {

        const pathParts: string[] = window.location.pathname.split('/').filter(n => n)

        if (pathParts.length) {
            // In case of login or logout - do not get session info
            if (pathParts[0] === 'login' || pathParts[0] === 'logout' || pathParts[0] === 'callback') {
                setUserInfo(null);

                return
            }
        }

        const session: AuthType = await AuthProvider.getMe()

        if (session.user !== null) {
            setUserInfo({
                account:        session.account,
                lockingSystems: session.lockingSystems,
                user:           session.user,
            })

            AuthProvider.session(session)

        } else {
            // When there is no logged-in user, set useInfo to null
            setUserInfo(null);
        }

        return
    },

    session: function(session: AuthType) {
        // If no selectedLockingSystemId, select the default for account
        const defaultLockingSystem: LockingSystem | undefined = session.lockingSystems?.find(
            (item: { isDefault: boolean }) => item.isDefault
        )

        const selectedAccountId: number | null | undefined = parseInt(
            localStorage.getItem('selectedAccountId') as string
        )
        const selectedLockingSystemId: number | null | undefined = parseInt(
            localStorage.getItem('selectedLockingSystemId') as string
        )

        if (session.account !== null && selectedAccountId === session.account.id) {
            if (selectedLockingSystemId) {
                // Check if it exists for this session
                const
                    lockingSystemIndex= session.lockingSystems?.find(i => i.id === selectedLockingSystemId)

                if (lockingSystemIndex !== undefined) return

                if (! session.lockingSystems || session.lockingSystems?.length === 0) {
                    localStorage.setItem('selectedLockingSystemId', '')
                } else if (defaultLockingSystem) {
                    localStorage.setItem('selectedLockingSystemId', defaultLockingSystem.id.toString())
                } else if (session.lockingSystems?.length) {
                    localStorage.setItem('selectedLockingSystemId', session.lockingSystems[0].id.toString())
                }

                return
            }
        }

        if (session.account !== null && session.account?.id > 0) {
            localStorage.setItem('selectedAccountId', session.account.id.toString())
        }

        // In case there are no lockingSystems for this account
        if (! session.lockingSystems || session.lockingSystems?.length === 0) {
            localStorage.setItem('selectedLockingSystemId', '')
        } else {
            if (defaultLockingSystem) {
                localStorage.setItem('selectedLockingSystemId', defaultLockingSystem.id.toString())
            } else if (session.lockingSystems?.length) {
                localStorage.setItem('selectedLockingSystemId', session.lockingSystems[0].id.toString())
            }
        }

    }
}

export default AuthProvider