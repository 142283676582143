import React, {CSSProperties} from "react"
import {useTranslation} from "react-i18next"

import {IonContent, IonPage, IonProgressBar, useIonViewWillEnter} from "@ionic/react"

import ItemPageHeader from "../../components/common/Headers/ItemPageHeader"
import Message from "../../components/common/Message";

import {useLockingSystems} from '../../contexts/LockingSystemContext'

interface pageProps {
    fetchItems:       any;
    useItems:         () => any;
    itemId:           string;
    segment:          string;
    items:            any[] | undefined;
    itemType:         string;
    itemTypeName:     string;
    itemTypeUrl:      string;
    pageHeaderTitle?: string;

    ItemForm?:                     any
    ItemNotFound?:                 any
    ItemView:                      any
    KeysList?:                     any
    LocksList?:                    any
    LockingSystemPermissionsList?: any
    LockingSystemZonesList?:       any
}

const PageItem: React.FC<pageProps> = ({
    fetchItems, items, useItems,
    itemId, segment, itemType, itemTypeName, itemTypeUrl, pageHeaderTitle,
    ItemForm, ItemNotFound, ItemView, KeysList, LocksList, LockingSystemPermissionsList, LockingSystemZonesList
}) => {
    const {t} = useTranslation()

    // make sure lockingSystems are loaded
    const { fetchItems: fetchLockingSystems } = useLockingSystems()

    const {inProgress, closeMessage, message} = useItems()

    const item = items?.find((item: { id: number; }) => item.id === parseInt(itemId))

    const [selectedSegment, setSelectedSegment] = React.useState<string>('item')

    useIonViewWillEnter(() => {
        if (closeMessage !== undefined) closeMessage()
    })

    React.useEffect(() => {
        fetchLockingSystems()
        fetchItems()

        let s = 'view'

        if (   (segment === 'form'                     && ItemForm  !== undefined)
            || (segment === 'keys'                     && KeysList  !== undefined)
            || (segment === 'locks'                    && LocksList !== undefined)
            || (segment === 'lockingSystemPermissions' && LockingSystemPermissionsList !== undefined)
            || (segment === 'lockingSystemZones'       && LockingSystemZonesList !== undefined)
        ) {
            s = segment
        }

        setSelectedSegment(s)
    }, [itemId, segment])

    let
        pageContent,
        pageItemType:   string  = 'view',
        buttonAddShow:  boolean = false,
        buttonAddId:    string  = 'button-add-id',
        showButtonEdit: boolean = false,
        showButtonSave: boolean = false

    pageHeaderTitle = pageHeaderTitle || itemTypeName

    if (items !== undefined) {

        if (itemId === 'new') {
            pageHeaderTitle = itemTypeName + ' ' + t('common.add').toLowerCase()
            pageItemType    = 'new'
            pageContent     = <ItemForm item={item} />
            showButtonSave  = true
        } else if (selectedSegment === 'form' && item !== undefined) {
            pageHeaderTitle = itemTypeName + ' ' + t('common.edit').toLowerCase()
            pageItemType    = 'edit'

            pageContent = itemType === 'BlocklistKeys'
                ? <ItemForm item={item.key} blocklistItem={item} />
                : <ItemForm item={item} />
            ;

            showButtonSave  = true
        } else if (item === undefined) {
            pageHeaderTitle = itemTypeName
            pageItemType    = 'error'

            pageContent = undefined !== ItemNotFound
                ? <ItemNotFound />
                : <div className="ion-padding">404 Not Found</div>

        } else if (selectedSegment === 'keys') {
            pageHeaderTitle = t('keys.title_list').toString()
            pageItemType    = 'keys'
            pageContent     = <KeysList item={item} keys={item.keys} />
        } else if (selectedSegment === 'locks') {
            pageHeaderTitle = t('locks.title_list').toString()
            pageItemType    = 'locks'
            pageContent = <LocksList item={item} locks={item?.locks}/>
        } else if (selectedSegment === 'lockingSystemPermissions') {
            pageHeaderTitle = t('locking-system-permissions.title_list').toString()
            pageItemType    = 'lockingSystemPermissions'
            pageContent     = <LockingSystemPermissionsList item={itemType === 'BlocklistKeys' ? item.key : item} />
        } else if (selectedSegment === 'lockingSystemZones') {
            pageHeaderTitle = t('locking-system-zones.title_list').toString()
            pageItemType    = 'lockingSystemZones'
            pageContent     = <LockingSystemZonesList item={itemType === 'BlocklistKeys' ? item.key : item} />
        } else {
            pageContent    = <ItemView item={item} />
            showButtonEdit = ItemForm !== undefined

            // No edit for items from a dataSource
            if (itemType === 'Person' && item.dataSourceId !== undefined && item.dataSourceId !== null) {
                showButtonEdit = false
            }
        }

        if (segment === 'lockingSystemPermissions' && (itemType === 'KeysInUse' || itemType === 'Lock')) {
            buttonAddShow = true

            if (itemType === 'KeysInUse') buttonAddId = 'select-key-locking-system-permissions'
            if (itemType === 'Lock')      buttonAddId = 'select-lock-locking-system-permissions'
        }
    }

    let style: CSSProperties = inProgress ? { 'opacity': 0.4, 'filter': 'grayscale(100%)' } : {}

    return (
        <IonPage className={"page-item page-item-" + pageItemType}>
            <ItemPageHeader
                inProgress={inProgress}
                itemId={item?.id} itemTypeName={itemTypeName} itemType={itemType} itemTypeUrl={itemTypeUrl}
                pageHeaderTitle={pageHeaderTitle}
                buttonAddShow={buttonAddShow} buttonAddId={buttonAddId}
                showButtonEdit={showButtonEdit} showButtonSave={showButtonSave}
            />
            <IonContent forceOverscroll={false}>
                <div className="pageContentContainer">
                    <div className="maxWidth" style={style}>
                        {inProgress && <IonProgressBar type="indeterminate"></IonProgressBar>}
                        {! inProgress && <div style={{ 'width': '100%', 'height': '4px' }}>&nbsp;</div>}
                        {message && <Message message={message} onClose={closeMessage} />}
                        {pageContent}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default PageItem