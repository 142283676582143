import React from "react"

import {IonCol, IonGrid, IonRow} from "@ionic/react"

import DashboardCardType from "../../types/DashboardCard"
import LockingSystem     from "../../types/LockingSystem"
import Card              from "./Card"

import IconBlocklistKey            from "../common/Icons/BlocklistKey"
import IconKeyInSafe               from "../common/Icons/KeyInSafe"
import IconKeyInUse                from "../common/Icons/KeyInUse"
import IconLock                    from "../common/Icons/Lock"
import IconPerson                  from "../common/Icons/Person"
import IconLockingSystemPermission from "../common/Icons/LockingSystemPermission"
import IconLockingSystemZone       from "../common/Icons/LockingSystemZone"
import {useLockingSystems} from "../../contexts/LockingSystemContext";

const dashboardCards: DashboardCardType[] = [
    {
        url: 'keys-in-use',
        title: 'keys.keyGroup.keys_in_use.title_list',
        subtitle: 'subtitle',
        count: 0,
        icon: <IconKeyInUse icon="key" />
    },
    {
        url: 'keys-in-safe',
        title: 'keys.keyGroup.keys_in_safe.title_list',
        count: 0,
        icon: <IconKeyInSafe icon="in-safe" />,
        needsFeature: 'HAS_KEY_SAFE',
    },
    {
        url: 'blocklist-keys',
        title: 'keys.keyGroup.blocklist.title_list',
        count: 0,
        icon: <IconBlocklistKey icon="on-blocklist" />,
        needsFeature: 'HAS_KEY_BLOCKLIST',
    },
    {
        url: 'locks',
        title: 'locks.title_list',
        count: 0,
        icon: <IconLock />
    },
    {
        url: 'people',
        title: 'people.title_list',
        count: 0,
        icon: <IconPerson />
    },
    {
        url: 'locking-system-permissions',
        title: 'locking-system-permissions.title_list',
        count: 0,
        icon: <IconLockingSystemPermission />
    },
    {
        url: 'locking-system-zones',
        title: 'locking-system-zones.title_list',
        count: 0,
        icon: <IconLockingSystemZone />
    }
];

interface itemProps {
    item: LockingSystem;
}

const Grid: React.FC<itemProps> = ({ item }) => {

    const { hasFeature: hasLockingSystemFeature } = useLockingSystems()

    return (
        <IonGrid className="maxWidth" style={{ 'padding': '0' }}>
            <IonRow>
                {dashboardCards.map((dashboardCard, index) => {

                    if (dashboardCard.needsFeature) {
                        const hasFeature = hasLockingSystemFeature(dashboardCard.needsFeature)

                        if (! hasFeature) {
                            return
                        }
                    }

                    const counterKey = dashboardCard.counterKey
                        ?? dashboardCard.url.replace(/-([a-z])/g, (m, w) => {
                            return w.toUpperCase()
                    })

                    if ( item.counts.hasOwnProperty(counterKey)) {
                        dashboardCard.count = item.counts[counterKey]
                    }

                    return (
                        <IonCol key={'dashboard-' + index}
                                size="6" sizeSm="4" sizeMd="3" sizeXl="2"
                                // style={{ 'paddingLeft': 0, 'paddingRight': '12px' }}
                            >
                            <Card dashboardCard={dashboardCard} lockingSystemId={item.id} />
                        </IonCol>
                    );
                })}
            </IonRow>
        </IonGrid>
    )
}

export default Grid