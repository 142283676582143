import React from "react"
import {lockClosedOutline, lockClosedSharp} from "ionicons/icons"
import TypeIcon from "./TypeIcon"

interface componentProps {
    link?: string
    size?: "small" | "default" | "large"
    slot?: string
    text?: string
}

const IconLock: React.FC<componentProps> = ({ link, size, slot, text }) => {

    return <TypeIcon
        backgroundColor={'blue'}
        link={link}
        ios={lockClosedOutline}
        md={lockClosedSharp}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconLock