import React from "react"
import {IonButton, IonIcon, useIonAlert} from "@ionic/react"
import {trashOutline, trashSharp} from "ionicons/icons"
import {useHistory}     from "react-router"
import {useTranslation} from "react-i18next"

import {Person as ItemType}    from "@customTypes/Person"
import {usePeople as useItems} from "../../../contexts/PersonContext"

interface componentProps {
    item: ItemType;
}

const ButtonDelete: React.FC<componentProps> = ({item}) => {
    const { deleteItem } = useItems()
    const history        = useHistory()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const alertDelete = async () => {

        if (item.keys && item.keys?.length > 0) {
            return presentAlert({
                header:  t('people.alerts.delete.header_not_possible'),
                message: t('people.alerts.delete.message_not_possible'),
                buttons: [
                    {
                        text:    t('common.cancel'),
                        role:    'cancel'
                    },
                ],
            })
        }

        return presentAlert({
            header:  t('people.alerts.delete.header'),
            message: t('people.alerts.delete.message'),
            buttons: [{
                text:    t('common.cancel'),
                role:    'cancel'
            }, {
                text:    t('common.delete'),
                role:    'confirm',
                handler: () => onDelete(),
            },],
        })
    }

    const onDelete = () => {
        deleteItem(item)

        history.push('/people')
    }

    return (
        <IonButton type="button" color="danger" fill="outline" className="ion-padding-start"
                   onClick={() => alertDelete()}>
            <IonIcon slot="start" ios={trashOutline} md={trashSharp} />
            {t('common.delete')}
        </IonButton>
    )
}

export default ButtonDelete
