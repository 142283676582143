import React from "react"
import {useTranslation} from "react-i18next"
import {IonAccordion, IonButton, IonIcon, IonItem, IonLabel, IonModal} from "@ionic/react"
import {addOutline, addSharp} from "ionicons/icons"

import LockingSystemPermission from "@customTypes/LockingSystemPermission"
import Person from "@customTypes/Person"
import SelectOption from "@customTypes/SelectOptions/LockingSystemPermissionSelectOption"

import SelectLockingSystemPermissions from "../../common/Modal/SelectLockingSystemPermissions"

import {useLockingSystemPermissions} from "../../../contexts/LockingSystemPermissionContext"


interface componentProps {
    formData:    Person
    setFormData: React.Dispatch<React.SetStateAction<Person>>
}

const AccordionLinkLockingSystemPermissions: React.FC<componentProps> = ({
    formData, setFormData
}) => {

    const {t} = useTranslation()

    const { selectOptions, fetchSelectOptions } = useLockingSystemPermissions()

    const [ modalReady, setModalReady ] = React.useState<boolean>(false)
    const selectModal = React.useRef<HTMLIonModalElement>(null)
    const [
        selectedLockingSystemPermissions, setSelectedLockingSystemPermissions
    ] = React.useState<number[]>([])

    React.useEffect(() => {
        setModalReady(false)

        setTimeout(() => setModalReady(true), 750)
    })

    React.useEffect(() => {
        const fetch = async () => fetchSelectOptions()

        fetch()

    }, [selectOptions])

    const filterAvailableLockingSystemPermissions = (): SelectOption[] | [] => {
        const availableLockingSystemPermissions: SelectOption[] = []

        const existingPermissions: LockingSystemPermission[] = []

        if (existingPermissions && selectOptions) {
            selectOptions.forEach((selectOption: SelectOption) => {
                const found = existingPermissions
                    .find(eLSP => selectOption.id === eLSP.id)

                if (undefined === found && selectOption.options.isAddableToKey) {
                    availableLockingSystemPermissions.push(selectOption)
                }
            })
        }

        return availableLockingSystemPermissions
    }

    const lockingSystemPermissionSelectionChanged = async (lockingSystemPermissions: number[]) => {
        setModalReady(false)

        setSelectedLockingSystemPermissions(lockingSystemPermissions)
        setFormData({ ...formData, linkLockingSystemPermissionIds: lockingSystemPermissions })

        selectModal.current?.dismiss()
    }

    return (
    <>
        <IonAccordion toggleIconSlot="start" value="link_locking_system_permissions">
            <IonItem className="bc-accordion" slot="header">
                <IonLabel>{t('locking-system-permissions.title_list')}</IonLabel>
            </IonItem>
            <IonItem lines="none" slot="content">
                <IonButton color="dark" fill="clear" id="person-key-locking-system-permissions">
                    <IonIcon slot="start" ios={addOutline} md={addSharp} />
                    <>{t('locking-system-permissions.add_permissions')}
                    {formData.linkLockingSystemPermissionIds && formData.linkLockingSystemPermissionIds.length > 0 &&
                        <span>&nbsp; ({formData.linkLockingSystemPermissionIds.length})</span>
                    }</>
                </IonButton>
            </IonItem>
        </IonAccordion>

        <IonModal trigger={modalReady ? "person-key-locking-system-permissions" : undefined} ref={selectModal}>
            <SelectLockingSystemPermissions
                hasShowHidden={true}
                items={filterAvailableLockingSystemPermissions()}
                selectedItems={selectedLockingSystemPermissions}
                selectTypeTitle={t('locking-system-permissions.title_list')}
                title={t('locking-system-permissions.add_permissions').toString()}
                onSelectionCancel={() => selectModal.current?.dismiss()}
                onSelectionChange={lockingSystemPermissionSelectionChanged}
            />
        </IonModal>
    </>
    )

}

export default AccordionLinkLockingSystemPermissions