import React from "react"
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react"
import {addOutline, addSharp, refreshOutline, refreshSharp} from "ionicons/icons"

import "./ListPageHeader.css"

type PageHeaderProps = {
    itemType:       string;
    itemTypeName:   string;
    itemTypeUrl:    string;
    handleRefresh:  (ev: any) => void;
    showAddButton?: boolean;
}


const ListPageHeader: React.FC<PageHeaderProps> = (
    { itemType, itemTypeName, itemTypeUrl, handleRefresh, showAddButton = false }: PageHeaderProps
) => {
    const defaultHref = '/dashboard'

    return (
        <IonHeader className="list-page-header">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton color="dark" defaultHref={defaultHref} text="" />
                </IonButtons>
                <IonTitle slot="start" color="bc-blue">
                    {itemTypeName}
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={(ev) => handleRefresh(ev)}>
                        <IonIcon color="dark" slot="icon-only" ios={refreshOutline} md={refreshSharp} />
                    </IonButton>
                    {showAddButton &&
                    <IonButton routerLink={`/${itemTypeUrl}/new/form`}>
                        <IonIcon color="dark" slot="icon-only" ios={addOutline} md={addSharp} />
                    </IonButton>
                    }
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}

export default ListPageHeader