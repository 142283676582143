import React from "react"

import {LockingSystemPermissionApi  as ItemApi } from "../services/LockingSystemPermissionApi"
import {LockingSystemPermission, LockingSystemPermission as ItemType} from "../types/LockingSystemPermission"

import LockApi from "../services/LockApi"

import {MessageInterface} from "../components/common/Message"

import KeyLockingSystemPermissionApi from "../services/KeyLockingSystemPermissionApi"
import SelectOption from "../types/SelectOptions/LockingSystemPermissionSelectOption"
import Key from "../types/Key";


export type LockingSystemPermissionContextType = {
    items:        ItemType[] | undefined;
    addItem:      (item: ItemType) => Promise<ItemType | void> | void;
    deleteItem:   (id: number) => void;
    editItem:     (item: ItemType) => Promise<ItemType | void> | void;
    fetchItems:   (search?: string, force?: boolean) => void;
    loadingItems: boolean;

    inProgress:   boolean;

    message:      MessageInterface | undefined
    closeMessage: () => void

    fetchSelectOptions: (force?: boolean) => void
    selectOptions: SelectOption[] | undefined

    unlinkKey:    (key: Key, lockingSystemPermission: LockingSystemPermission) => void
    unlinkLock:   (lockId: number, lockingSystemPermissionId: number) => void
}

const initialState = {

    addItem:      () => {},
    deleteItem:   () => {},
    editItem:     () => {},
    fetchItems:   () => {},
    items:        undefined,
    loadingItems: false,

    inProgress:   false,

    message:      undefined,
    closeMessage: () => {},

    fetchSelectOptions: () => {},
    selectOptions: undefined,

    unlinkKey:    () => {},
    unlinkLock:   () => {},
}

export const LockingSystemPermissionContext = React.createContext<LockingSystemPermissionContextType>(initialState);

interface providerProps {
    children: React.ReactNode
}

const LockingSystemPermissionProvider: React.FC<providerProps> = ( { children }) => {

    const [
        items, setItems
    ] = React.useState<ItemType[] | undefined>(initialState.items)
    const [
        loadingItems, setLoadingItems
    ] = React.useState<boolean>(initialState.loadingItems)
    const [
        inProgress, setInProgress
    ] = React.useState<boolean>(initialState.inProgress)
    const [
        message, setMessage
    ] = React.useState<MessageInterface | undefined>(initialState.message)

    const [
        selectOptions, setSelectOptions
    ] = React.useState<SelectOption[] | undefined>(initialState.selectOptions)


    const fetchItems = async (search?: string, force: boolean = false) => {
        if (items === undefined || force) {
            setLoadingItems(true)

            setItems( await ItemApi.getAll(search) );

            setLoadingItems(false)
        }
    }

    const fetchSelectOptions = async (force: boolean = false): Promise<SelectOption[] | void> => {

        if (undefined === selectOptions || force) {
            setLoadingItems(true)

            const result = await ItemApi.getSelectOptions()
                .catch((e) => {
                    setMessage({
                        text: e.response.data.message || '?',
                        type: 'error'
                    })

                    setLoadingItems(false)
                })

            if (result) {
                setSelectOptions(result)

                setLoadingItems(false)
            }
        }
    }

    const addItem = async (newItem: ItemType): Promise<LockingSystemPermission | void> => {
        closeMessage()
        setInProgress(true)

        const result = await ItemApi
            .add(newItem, () => {})
            .catch((e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)
            })

        if (items && result) {
            await fetchSelectOptions(true)

            setInProgress(false)

            setItems([result, ...items])
        }

        return result || undefined
    }

    const editItem = async (editedItem: ItemType): Promise<LockingSystemPermission | void> => {
        closeMessage()
        setInProgress(true)

        const result = await ItemApi
            .edit(editedItem)
            .catch( (e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return
            })

        if (items && result) {
            const newItems: LockingSystemPermission[] = items.map(oldItem => {
                if (oldItem.id === editedItem.id && result.id) {

                    return {...result}

                    // return {
                    //     ...oldItem,
                    //     name:        result.name,
                    //     description: result.description,
                    // }
                } else {
                    return oldItem
                }
            })

            await fetchSelectOptions(true)

            setInProgress(false)

            setItems(newItems)
        }

        return result
    }

    const deleteItem = async (id: number): Promise<any> => {
        closeMessage()
        setInProgress(true)

        const result = await ItemApi
            .delete(id)
            .catch( (e) => {
                setMessage({
                    text: e.response.data.message || '?',
                    type: 'error'
                })

                setInProgress(false)

                return
            })

        if (items && result !== undefined ) {
            const newItems = [...items];
            newItems.splice(newItems.findIndex(i => i.id === id), 1);

            setInProgress(false)

            setItems(newItems)
        }

        return result
    }

    const unlinkKey = async (key: Key, lockingSystemPermission: LockingSystemPermission) => {

        await KeyLockingSystemPermissionApi.delete(key, lockingSystemPermission)

        const newItems = items?.map(item => {
            if (item.id !== lockingSystemPermission.id) {
                return item
            }

            const newKeys: Key[] = [...item.keys || []]
            newKeys.splice(newKeys.findIndex(i => i.id === key.id), 1)

            return {
                ...item,
                keys: newKeys
            }
        })

        setItems(newItems)
    }

    const unlinkLock = async (lockId: number, lockingSystemPermissionId: number) => {

        await LockApi.deleteLockingSystemPermission(lockId, lockingSystemPermissionId)

        const newItems = items?.map(item => {
            if (item.id !== lockingSystemPermissionId) {
                return item
            }

            const newLocks = [...item.locks || []]
            newLocks.splice(newLocks.findIndex(i => i.id === lockId), 1)

            return {
                ...item,
                locks: newLocks
            }

        })

        setItems(newItems)
    }

    const closeMessage = () => {
        setMessage(undefined)
    }

    return (
        <LockingSystemPermissionContext.Provider
            value={{
                items,
                addItem,
                deleteItem,
                editItem,
                fetchItems,
                fetchSelectOptions,
                loadingItems,
                inProgress,

                message,
                closeMessage,

                selectOptions,

                unlinkKey,
                unlinkLock,
            }}
        >
            {children}
        </LockingSystemPermissionContext.Provider>
    );
}

export const useLockingSystemPermissions = () => {
    return React.useContext(LockingSystemPermissionContext) as LockingSystemPermissionContextType
}

export default LockingSystemPermissionProvider