import React from "react"
import {useTranslation} from "react-i18next"

import {IonButton} from "@ionic/react"

import {SyncCollectionItem} from "../../types/SyncCollections"
import LockingSystem        from "../../types/LockingSystem"
import SyncResult           from "../../types/SyncResult"
import {useLockingSystems} from "../../contexts/LockingSystemContext";
import BadgeMessage from "../common/Badges/BadgeMessage";
import {useKeys} from "../../contexts/KeyContext";
import {useLockingSystemZones} from "../../contexts/LockingSystemZoneContext";
import {usePeople} from "../../contexts/PersonContext";
import {useLockingSystemPermissions} from "../../contexts/LockingSystemPermissionContext";
import {useLocks} from "../../contexts/LockContext";

interface componentProps {
    collectionItem:    SyncCollectionItem;
    lockingSystemItem: LockingSystem;
}


const ButtonSync: React.FC<componentProps> = ({collectionItem, lockingSystemItem}) => {

    const { t } = useTranslation()
    const {fetchItems: fetchLockingSystems, getCollectionApi, isSynchronizing, setCustomMessage, syncCollection} = useLockingSystems()
    const {fetchItemsInSafe: fetchKeysInSafe, fetchItemsInUse: fetchKeysInUse, fetchItemsOnBlocklist: fetchBlocklistKeys} = useKeys()
    const {fetchItems: fetchLockingSystemPermissions} = useLockingSystemPermissions()
    const {fetchItems: fetchLockingSystemZones} = useLockingSystemZones()
    const {fetchItems: fetchLocks} = useLocks()
    const {fetchItems: fetchPeople} = usePeople()

    const fetchAfterChanges = async (collection: string): Promise<any> => {
        fetchLockingSystems('', true)

        switch (collection) {
            case 'blocklistKeys':
            case 'keyPhones':
            case 'keys': {
                fetchKeysInUse('', true)
                fetchKeysInSafe('', true)
                fetchBlocklistKeys('', true)
                fetchPeople('', true)
                return
            }
            case 'keyLockingSystemPermissions': {
                fetchLockingSystemPermissions('', true)
                fetchKeysInUse('', true)
                fetchKeysInSafe('', true)
                fetchBlocklistKeys('', true)
                return
            }
            case 'keyLockingSystemZones': {
                fetchLockingSystemZones('', true)
                fetchKeysInUse('', true)
                fetchKeysInSafe('', true)
                fetchBlocklistKeys('', true)
                return
            }
            case 'lockingSystemPermissions': {
                fetchLockingSystemPermissions('', true)
                return
            }
            case 'lockingSystemZones': {
                fetchLockingSystemZones('', true)
                return
            }
            case 'lockLockingSystemPermissions': {
                fetchLockingSystemPermissions('', true)
                fetchLocks('', true)
                return
            }
            case 'locks': {
                fetchLocks('', true)
                fetchLockingSystemZones('', true)
                return
            }
            case 'people': {
                fetchPeople('', true)
                return
            }
        }
    }


    const handleSyncCollection = async (collectionItem: SyncCollectionItem, item: LockingSystem) => {

        const api = getCollectionApi(collectionItem.key)

        if (api !== undefined) {
            const result: SyncResult | void = await syncCollection(collectionItem.key, api, item.id)

            if (result) {
                const syncCounters = result.sync.counters

                if (syncCounters.same !== syncCounters.total) await fetchAfterChanges(collectionItem.key)

                let text: any = []

                if (syncCounters.total === 0) {
                    text.push(<BadgeMessage key="badge-message-no-results" cssClass="text" label={t('common.no_results')} />)
                } else if (syncCounters.total === syncCounters.same) {
                    text.push(<BadgeMessage key="badge-message-no-changes" cssClass="text" label={t('common.no_changes')} />)
                } else {
                    if (syncCounters.delete) {
                        text.push(<BadgeMessage count={syncCounters.delete} cssClass="removed"
                                                key="badge-message-removed" label={t('common.removed')} />)
                    }
                    if (syncCounters.insert > 0) {
                        text.push(<BadgeMessage count={syncCounters.insert} cssClass="new"
                                                key="badge-message-new" label={t('common.new')} />)
                    }
                    if (syncCounters.update > 0) {
                        text.push(<BadgeMessage count={syncCounters.update} cssClass="changed"
                                                key="badge-message-changed"  label={t('common.changed')} />)
                    }
                    if (syncCounters.same > 0) {
                        text.push(<BadgeMessage count={syncCounters.same} cssClass="same"
                                                key="badge-message-same" label={t('common.unchanged')} />)
                    }
                }

                text.push(<BadgeMessage count={syncCounters.total} cssClass="total"
                                        key="badge-message-total" label={t('common.total')}  />)

                setCustomMessage([
                    <span className="ion-margin-end" key="message-sync-results">
                        <span style={{ 'fontWeight': 'bolder' }}>{t('common.sync_results')}</span>
                        &nbsp;| {t(collectionItem.name)}
                    </span>,
                    text
                ], 'notice')
            }
        }
    }

    return (
        <IonButton color="bc-yellow" disabled={isSynchronizing !== null} fill="outline" size="small" slot="end"
                   onClick={(ev) => {
                       ev.preventDefault()
                       ev.stopPropagation()

                       handleSyncCollection(collectionItem, lockingSystemItem)
                   }}
        >
            <span className="ion-hide-sm-down">{t('locking-systems.api_sync_manual')}</span>
            <span className="ion-hide-sm-up">sync</span>
        </IonButton>
    )

}

export default ButtonSync