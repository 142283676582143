import React from "react"

import Lock from "../../types/Lock"
import IconLock from "../common/Icons/Lock"
import ItemViewHeader from "../common/Headers/ItemViewHeader"

export interface componentProps {
    item: Lock
}

const ViewHeader: React.FC<componentProps> = ({item}) => {

    return <ItemViewHeader
        icon={<IconLock link={'/locks/' + item.id + '/form'} />}
        title={item.description || item.lockingObject || ''}
        subTitle={item.lockType?.lockingSystemType?.brand.name + ' - ' + item.lockType?.name}
    />

}

export default ViewHeader