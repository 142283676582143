import React from "react"
import {useTranslation} from "react-i18next";

import {useLockingSystems as useItems} from "../../contexts/LockingSystemContext"

import ItemList from '../../components/LockingSystems/List'
import PageList from '../common/List'

const PageLockingSystemsList: React.FC = () => {

    const { t } = useTranslation()

    const { fetchItems } = useItems()

    const
        itemType: string     = 'LockingSystems',
        itemTypeName: string = t('locking-systems.title_list'),
        itemTypeUrl: string  = 'locking-systems'

    return (
        <PageList
            ItemList={ItemList}
            itemType={itemType}
            itemTypeName={itemTypeName}
            itemTypeUrl={itemTypeUrl}
            fetchItems={fetchItems}
            useItems={useItems}
        />
    )
}

export default PageLockingSystemsList