import React from 'react'
import {IonAvatar, IonButton, IonItem, IonLabel, IonSkeletonText} from "@ionic/react"

const ListItemSkeleton: React.FC = () => {

    return (
        <IonItem>
            <IonAvatar slot="start">
                <IonSkeletonText animated={true} style={{ 'width': '32px', 'height': '32px' }} />
            </IonAvatar>
            <IonLabel>
                <h2><IonSkeletonText animated={true} style={{'width': '65%'}}/></h2>
                <p><IonSkeletonText animated={true} style={{'width': '80%'}}/></p>
            </IonLabel>
            <IonButton slot="end" className="keys" color="light">
                <IonSkeletonText animated={true} color="light" style={{'width': '32px'}}/>
            </IonButton>
        </IonItem>
    )

}

export default ListItemSkeleton