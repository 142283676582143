import React from "react"
import {shieldCheckmarkOutline, shieldCheckmarkSharp} from "ionicons/icons"

import TypeIcon from "./TypeIcon"

interface componentProps {
    link?: string
    size?: "small" | "default" | "large"
    slot?: string
    text?: string
}

const IconLockingSystemPermission: React.FC<componentProps> = ({ link, size, slot, text }) => {

    return <TypeIcon
        backgroundColor={'orange'}
        link={link}
        ios={shieldCheckmarkOutline}
        md={shieldCheckmarkSharp}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconLockingSystemPermission