import ApiClient from "./ApiClient"
import SyncResult from "../types/SyncResult"

export const KeyLockingSystemZoneApi = {

    apiPath: 'key-locking-system-zones',

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(
            KeyLockingSystemZoneApi.apiPath + '/sync/all', { params }
        )

        return result?.data
    },
}

export default KeyLockingSystemZoneApi