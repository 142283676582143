import React from "react"

import LockingSystem from "../../types/LockingSystem"
import {useTranslation} from "react-i18next";
import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react";

export interface componentProps {
    item: LockingSystem
}

const ItemGroupApiLink: React.FC<componentProps> = ({item}) => {

    const { t } = useTranslation()

    return (
        <IonItemGroup className="ion-margin-top" key={'location-system-' + item.id + '-api-link'}>
            <IonItemDivider>{t('locking-systems.api_link')}</IonItemDivider>
            <IonItem key='api-status' lines="full">
                <IonLabel>{t('common.active_since')}</IonLabel>
                <IonNote slot="end">
                    {item.lockingSystemApi?.createdAt ? item.lockingSystemApi.createdAt : '-'}
                </IonNote>
            </IonItem>
            {/*{item.lockingSystemApi &&*/}
            {/*<IonItem key="api-sync" lines="full">*/}
            {/*    <IonLabel>*/}
            {/*        {t('locking-systems.api_sync_manual')}*/}
            {/*    </IonLabel>*/}
            {/*    {! isSynchronizing &&*/}
            {/*    <IonButton color="bc-yellow" size="small" style={{ 'height': '32px' }} fill="outline"*/}
            {/*               onClick={(ev) => handleSync(ev)}*/}
            {/*               slot="end">*/}
            {/*        Sync alles*/}
            {/*    </IonButton>*/}
            {/*    }*/}
            {/*</IonItem>*/}
            {/*}*/}
        </IonItemGroup>
    )
}

export default ItemGroupApiLink