import React from 'react'
import {useTranslation} from "react-i18next";

import {IonButton, IonIcon, IonItem, IonItemOptions, IonLabel, IonNote, useIonAlert} from "@ionic/react"
import {lockClosedOutline, lockClosedSharp} from "ionicons/icons";

import {User as ItemType} from "../../types/User"
import IconUser           from "../common/Icons/User";

interface itemProps {
    item: ItemType
}

const ListItem: React.FC<itemProps> = ({item}) => {

    const
        [presentAlert] = useIonAlert(),
        {t} = useTranslation()

    const alertDataSource = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        return presentAlert({
            header:  t('common.alerts.data_source.header'),
            message: t('common.alerts.data_source.message', { itemType: t('users.title_item').toLowerCase() }),
            buttons: [{
                text: t('common.cancel'),
                role: 'cancel'
            }]
        })
    }

    return (
        <>
            <IonItem button detail routerLink={"/users/" + item.id + "/view"} slot="start">
                <IconUser slot="start" />
                <IonLabel>
                    {item.name}
                    <p>{item.email}</p>
                </IonLabel>
                <IonNote slot="end">
                    {item.dataSourceId !== null &&
                        <IonButton color="medium" fill="clear"
                                   onClick={(e) => alertDataSource(e)}
                                   title={t('common.alerts.data_source.header').toString()}>
                            <IonIcon ios={lockClosedOutline} md={lockClosedSharp} />
                        </IonButton>
                    }
                </IonNote>
            </IonItem>
            <IonItemOptions slot="end">
                {/*options*/}
            </IonItemOptions>
        </>
    )
}

export default ListItem