import React from "react";

import {IonContent, IonPage, IonSpinner} from '@ionic/react';
import './Loader.css';

const Loader: React.FC = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="flex-center">
                    <IonSpinner color="dark"></IonSpinner>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Loader