import React from "react"
import {useTranslation} from "react-i18next"

import {IonAccordion, IonInput, IonItem, IonLabel} from "@ionic/react"

import {usePeople as useItems} from "../../../contexts/PersonContext"
import Person from "@customTypes/Person";

interface componentProps {
    formData:          Person
    updateFormHandler: any
}

const AccordionPerson: React.FC<componentProps> = ({
    formData, updateFormHandler
}) => {

    const { t } = useTranslation()

    const { inProgress } = useItems()

    return (
        <IonAccordion toggleIconSlot="start" value="person">
            <IonItem className="bc-accordion" slot="header">
                <IonLabel>{t('people.title_item')}</IonLabel>
            </IonItem>
            <IonItem slot="content">
                <IonInput name="firstName" type="text"
                          label={t('people.form.firstName') as string} labelPlacement="stacked"
                          value={formData.firstName}
                          minlength={2} maxlength={128}
                          placeholder={t('people.form.firstName') as string}
                          clearInput={true}
                          autofocus={true}
                          required
                          disabled={inProgress}
                          tabIndex={1}
                          onIonChange={updateFormHandler('firstName')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="lastName" type="text"
                          label={t('people.form.lastName') as string} labelPlacement="stacked"
                          value={formData.lastName}
                          minlength={2} maxlength={128}
                          placeholder={t('people.form.lastName') as string}
                          clearInput={true}
                          required
                          disabled={inProgress}
                          tabIndex={2}
                          onIonChange={updateFormHandler('lastName')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="email" type="email"
                          label={t('people.form.email') as string} labelPlacement="stacked"
                          value={formData.email}
                          minlength={2} maxlength={128}
                          placeholder={t('people.form.email_placeholder') as string}
                          clearInput={true}
                          disabled={inProgress}
                          tabIndex={3}
                          onIonChange={updateFormHandler('email')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="phone1" type="tel"
                          label={t('people.form.mobile') as string} labelPlacement="stacked"
                          value={formData.phone1}
                          minlength={10} maxlength={16}
                          placeholder={t('people.form.mobile_placeholder') as string}
                          clearInput={true}
                          disabled={inProgress}
                          required={formData.linkKey === 'keyPhone'}
                          onIonChange={updateFormHandler('phone1')}
                />
            </IonItem>
        </IonAccordion>
    )

}

export default AccordionPerson