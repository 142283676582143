import React from "react"
import {useTranslation} from "react-i18next"

import {IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNote} from "@ionic/react"

import LockingSystemZone from "../../types/LockingSystemZone"


export interface componentProps {
    item: LockingSystemZone
}

const ViewItemGroupInfo: React.FC<componentProps> = ({item} ) => {

    const {t} = useTranslation()

    return (
        <IonItemGroup>
            <IonItemDivider>{t('common.info')}</IonItemDivider>
            <IonItem lines="full">
                <IonLabel>{t('locking-system-zones.form.default_for_keys')}</IonLabel>
                <IonNote slot="end">{t('common.' + (item.isDefaultForKeys ? 'yes' : 'no'))}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('locking-system-zones.form.default_for_other')}</IonLabel>
                <IonNote slot="end">{t('common.' + (item.isDefaultForOther ? 'yes' : 'no'))}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('locking-system-zones.form.default_for_people')}</IonLabel>
                <IonNote slot="end">{t('common.' + (item.isDefaultForPeople ? 'yes' : 'no'))}</IonNote>
            </IonItem>
            <IonItem lines="full">
                <IonLabel>{t('common.synced_at')}</IonLabel>
                <IonNote slot="end">{item.syncedAt || '-'}</IonNote>
            </IonItem>
        </IonItemGroup>
    )
}

export default ViewItemGroupInfo