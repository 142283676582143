import React from "react";
import {IonItem, IonLabel, IonList} from "@ionic/react"

import LockType from "../../types/Lock"

import LockingSystemPermission from "../../types/LockingSystemPermission"

import IconLock from "../common/Icons/Lock"
import ItemSegmentListHeader from "../common/Headers/ItemSegmentListHeader"

interface componentProps {
    item: LockingSystemPermission | undefined;
    locks: LockType[] | undefined;
}

const List: React.FC<componentProps> = ({ item, locks }) => {

    const title: string    = item?.name + (item?.description ? ' - ' + item?.description : '')
    const subTitle: string = item?.lockingSystemPermissionType?.name || ''

    return (
        <IonList lines="full" className="maxWidth">
            <ItemSegmentListHeader title={title} subTitle={subTitle} />

            {locks === undefined || locks?.length === 0 ? (
                <IonItem className="ion-text-center">
                    <IonLabel className="ion-text-wrap">
                        Geen sloten met dit recht erop gevonden.
                    </IonLabel>
                </IonItem>
            ) : (
                locks.map( (lock: LockType) => {

                    return (
                        <IonItem button
                                 detail={true}
                                 key={'lock-' + lock.id}
                                 lines="inset"
                                 routerLink={'/locks/' + lock.id + '/view'}
                        >
                            <IconLock slot="start" />
                            <IonLabel>
                                <h2>
                                    {lock.lockingObject}
                                    {lock.serial &&
                                        <span style={{ 'fontWeight': '200', 'fontSize': '14px' }}>
                                        &nbsp;- {lock.serial}
                                    </span>
                                    }
                                </h2>
                                <h3 style={{ 'color': "#777" }}>
                                    {lock.lockType?.lockingSystemType?.brand?.name} - {lock.lockType?.name}
                                </h3>
                            </IonLabel>
                            {/*<ListItemButtonDelete*/}
                            {/*    isRemovable={item?.options?.isRemovableFromLock}*/}
                            {/*    headerTxt='Wil je dit recht van dit slot halen?'*/}
                            {/*    messageTxt='message'*/}
                            {/*    onDelete={() => onDelete(lock)}*/}
                            {/*/>*/}
                        </IonItem>
                    )
                })
            )}
        </IonList>
    )

}

export default List