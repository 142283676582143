import React from 'react'
import {useTranslation} from "react-i18next"

import {IonIcon, IonItemOption, useIonAlert} from "@ionic/react"
import {trashOutline, trashSharp}            from "ionicons/icons"


import BlocklistKey from "../../../types/BlocklistKey"
import Key          from "../../../types/Key"

import {useKeys as useItems} from "../../../contexts/KeyContext"
import {usePeople}           from "../../../contexts/PersonContext"

interface componentProps {
    item:          Key
    blocklistKey?: BlocklistKey
}


const ListItemOptionDelete: React.FC<componentProps> = ({item, blocklistKey}) => {
    const { deleteItem } = useItems()
    const { unlinkKey }  = usePeople()
    const [presentAlert] = useIonAlert()
    const { t }          = useTranslation()

    const closeItemSliding = () => {
        document.querySelector('ion-item-sliding')?.closeOpened();
    }

    const alertDelete = async () => {
        return presentAlert({
            header:  t('keys.alerts.delete.header'),
            message: t('keys.alerts.delete.message'),
            buttons: [{
                text:    t('common.cancel'),
                role:    'cancel',
                handler: () => onCancel(),
            }, {
                text:    t('common.delete'),
                role:    'confirm',
                handler: () => onDelete(),
            },],
        })
    }

    const onCancel = () => { closeItemSliding() }

    const onDelete = async () => {
        closeItemSliding()

        const result = await deleteItem(item, blocklistKey)

        if (result) unlinkKey(item)
    }

    return (
        <IonItemOption color="danger" onClick={() => alertDelete()}>
            <IonIcon slot="top" ios={trashOutline} md={trashSharp} />
            {t('common.delete')}
        </IonItemOption>
    )
}

export default ListItemOptionDelete