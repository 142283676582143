import React from "react"
import {useTranslation} from "react-i18next"

import {
    IonAccordion, IonIcon, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonSelect, IonSelectOption
} from "@ionic/react"
import {banOutline, banSharp, cubeOutline, cubeSharp, phonePortraitOutline, phonePortraitSharp} from "ionicons/icons"

import {useLockingSystems} from "../../../contexts/LockingSystemContext"

import Key    from "@customTypes/Key"
import KeyApi from "../../../services/KeyApi"
import Person from "@customTypes/Person"

interface componentProps {
    formData:          Person
    updateFormHandler: any
}

const AccordionLinkKey: React.FC<componentProps> = ({
    formData, updateFormHandler
}) => {

    const {t} = useTranslation()

    const {hasFeature: hasLockingSystemFeature} = useLockingSystems()
    const [keysInSafe, setKeysInSafe] = React.useState<Key[] | undefined>(undefined)

    React.useEffect(() => {
        const fetchKeysInSafe = async () => {
            if (keysInSafe === undefined) setKeysInSafe(await KeyApi.getKeysInSafe())
        }

        //if (formData.linkKey === 'keyFromSafe') {
            fetchKeysInSafe()
        //}
    })

    const keyName = (key: Key): string => {
        const name = ('SPARE_KEY' === key.description)
            ? t('keys.spare_key')
            : key.description || '-'

        return name + ' - ' + key.label + ' - ' + key.serial
    }

    return (
        <IonAccordion toggleIconSlot="start" value="link_key">
            <IonItem className="bc-accordion" slot="header">
                <IonLabel>{t('keys.title_item')}</IonLabel>
            </IonItem>
            <IonItem lines="none" slot="content">
                <IonList style={{ 'width': '100%' }}>
                    <IonRadioGroup onIonChange={updateFormHandler('linkKey')} value={formData.linkKey}>
                        <IonItem lines="full">
                            <IonRadio slot="start" labelPlacement="end" value="none">
                                {t('people.form.key_none')}
                            </IonRadio>
                            <IonIcon  slot="end" ios={banOutline} md={banSharp} />
                        </IonItem>
                        {hasLockingSystemFeature('CAN_HAVE_PHONE_KEYS') === true &&
                            <IonItem lines="full">
                                <IonRadio slot="start" labelPlacement="end" value="keyPhone">
                                    {t('people.form.key_new_keyPhone')}
                                </IonRadio>
                                <IonIcon slot="end" ios={phonePortraitOutline} md={phonePortraitSharp} />
                            </IonItem>
                        }
                        {hasLockingSystemFeature('HAS_KEY_SAFE') === true &&
                            <IonItem lines="full">
                                {keysInSafe && keysInSafe.length > 0 ? (
                                <IonRadio slot="start" labelPlacement="end" value="keyFromSafe">
                                    {t('people.form.key_from_safe')}
                                </IonRadio>
                                ) : (
                                <IonLabel color="medium">
                                    ({t('people.form.key_select_from_safe_unavailable')})
                                </IonLabel>
                                )}
                                <IonIcon slot="end" ios={cubeOutline} md={cubeSharp} />
                            </IonItem>
                        }
                    </IonRadioGroup>

                    {hasLockingSystemFeature('HAS_KEY_SAFE') === true && formData.linkKey === 'keyFromSafe' &&
                        <IonItem lines="full">
                            <IonSelect
                                okText={t('common.select') as string}
                                cancelText={t('common.cancel') as string}
                                label={t('people.form.key_select_from_safe').toString()}
                                labelPlacement="floating"
                                value={formData.linkKeyId} tabIndex={99}
                                interfaceOptions={{cssClass: 'alert-select-width-90'}}
                                onIonChange={updateFormHandler('linkKeyId')}
                            >
                                {keysInSafe?.length && keysInSafe.map((key: Key) => (
                                    <IonSelectOption key={key.id} value={key.id}>
                                        {keyName(key)}
                                    </IonSelectOption>
                                ))}
                                {keysInSafe?.length === 0 &&
                                    <IonSelectOption key="0" value="0">
                                        {t('common.no_results_found')}
                                    </IonSelectOption>
                                }
                            </IonSelect>
                        </IonItem>
                    }

                </IonList>
            </IonItem>
        </IonAccordion>
    )
}

export default AccordionLinkKey