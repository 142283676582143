import React from "react"
import {expandOutline, expandSharp} from "ionicons/icons";
import TypeIcon from "./TypeIcon";

interface componentProps {
    link?: string
    size?: "small" | "default" | "large"
    slot?: string
    text?: string
}

const IconLockingSystemZone: React.FC<componentProps> = ({ link, size, slot, text }) => {

    return <TypeIcon
        backgroundColor={'DarkTurquoise'}
        link={link}
        ios={expandOutline}
        md={expandSharp}
        size={size}
        slot={slot}
        text={text}
    />

}

export default IconLockingSystemZone