import React from "react"
import {useTranslation} from "react-i18next"

import {IonAccordion, IonInput, IonItem, IonLabel, IonTextarea} from "@ionic/react"

import {usePeople as useItems} from "../../../contexts/PersonContext"
import Person from "@customTypes/Person";

interface componentProps {
    formData:          Person
    updateFormHandler: any
}

const AccordionOther: React.FC<componentProps> = ({formData, updateFormHandler}) => {

    const { t } = useTranslation()
    const { inProgress } = useItems()

    return (
        <IonAccordion toggleIconSlot="start" value="other">
            <IonItem className="bc-accordion" slot="header">
                <IonLabel>{t('common.other')}</IonLabel>
            </IonItem>
            <IonItem slot="content">
                <IonInput name="companyName" type="text"
                          label={t('people.form.company_name') as string} labelPlacement="stacked"
                          value={formData.companyName}
                          placeholder={t('people.form.company_name') as string}
                          clearInput={true}
                          disabled={inProgress}
                          onIonChange={updateFormHandler('companyName')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="address" type="text"
                          label={t('people.form.address') as string} labelPlacement="stacked"
                          value={formData.address}
                          placeholder={t('people.form.address_placeholder') as string}
                          clearInput={true}
                          disabled={inProgress}
                          onIonChange={updateFormHandler('address')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="zipcode" type="text"
                          label={t('people.form.zipcode') as string} labelPlacement="stacked"
                          value={formData.zipcode}
                          placeholder={t('people.form.zipcode_placeholder') as string}
                          clearInput={true}
                          disabled={inProgress}
                          onIonChange={updateFormHandler('zipcode')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="city" type="text"
                          label={t('people.form.city') as string} labelPlacement="stacked"
                          value={formData.city}
                          placeholder={t('people.form.city_placeholder') as string}
                          clearInput={true}
                          disabled={inProgress}
                          onIonChange={updateFormHandler('city')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonInput name="country" type="text"
                          label={t('people.form.country') as string} labelPlacement="stacked"
                          value={formData.country}
                          placeholder={t('people.form.country_placeholder') as string}
                          clearInput={true}
                          disabled={inProgress}
                          onIonChange={updateFormHandler('country')}
                />
            </IonItem>
            <IonItem slot="content">
                <IonTextarea name="contactInfo"
                         label={t('people.form.contact_info') as string} labelPlacement="stacked"
                         value={formData.contactInfo}
                         placeholder={t('people.form.contact_info_placeholder') as string}
                         disabled={inProgress}
                         autoGrow={true}
                         onIonChange={updateFormHandler('contactInfo')}
                />
            </IonItem>
        </IonAccordion>
    )
}

export default AccordionOther