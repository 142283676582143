import ApiClient from "./ApiClient"

import Person       from "../types/Person"
import SelectOption from "../types/SelectOptions/PersonSelectOption"
import SyncResult   from "../types/SyncResult"


export const PersonApi = {

    apiPath: 'people',

    getAll: async (search?: string): Promise<Person[]> => {
        const items: Person[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        if (search) params.append('search', search)

        const response = await ApiClient.get(PersonApi.apiPath, { params });

        for (let item of response.data.data) {
            items.push(item)
        }

        return items
    },

    getSelectOptions: async(): Promise<SelectOption[]> => {
        const selectOptions: SelectOption[] = []
        const params = new URLSearchParams([
            ['lockingSystemId', localStorage.getItem('selectedLockingSystemId') as string]
        ])

        const response = await ApiClient.get(
            'select-options/' + PersonApi.apiPath, { params }
        );


        for (let selectOption of response.data.data) {
            selectOptions.push(selectOption)
        }

        return selectOptions
    },

    add: async (newPerson: Person, handleAddItem: (id: number) => void): Promise<Person> => {

        const result = await ApiClient.post(PersonApi.apiPath, newPerson);

        const itemRef = result.data.data;

        handleAddItem(itemRef.id);

        return result?.data.data
    },

    edit: async (person: Person): Promise<Person> => {

        const result = await ApiClient.put(PersonApi.apiPath + '/' + person.id, person);

        return result?.data.data
    },

    delete: async(id: number): Promise<any> => {

        return await ApiClient.delete(PersonApi.apiPath + '/' + id);

    },

    syncAllByLockingSystemId: async (lockingSystemId: number): Promise<SyncResult> => {
        const params = new URLSearchParams([['lockingSystemId', lockingSystemId.toString()]])

        const result = await ApiClient.get(PersonApi.apiPath + '/sync/all', { params })

        return result?.data
    },

}