import React from "react"

import User           from "../../../types/User"
import IconUser       from "../../common/Icons/User"
import ItemViewHeader from "../../common/Headers/ItemViewHeader"

export interface componentProps {
    item: User
}

const Header: React.FC<componentProps> = ({item}) => {

    const
        linkSegment = item.dataSourceId ? 'view' : 'form',
        text = item.name ? item.name : '',
        title = text ? text : (item.email ? item.email : '')

    return <ItemViewHeader
        icon={<IconUser link={'/users/' + item.id + '/' + linkSegment} text={text} />}
        title={title}
        subTitle={''}
    />

}

export default Header