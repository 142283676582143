import React from "react"

import ItemViewHeader   from "../common/Headers/ItemViewHeader"
import IconBlocklistKey from "../common/Icons/BlocklistKey"
import BlocklistKeyType from "../../types/BlocklistKey";

export interface componentProps {
    item: BlocklistKeyType
}

const ViewHeader: React.FC<componentProps> = ({item}) => {

    return <ItemViewHeader
        icon={<IconBlocklistKey icon={item?.key.keyType?.icon} size="large" />}
        title={item?.key.description || ''}
        subTitle={item.key.keyType?.lockingSystemType?.brand.name + ' - ' + item.key.keyType?.name}
    />

}

export default ViewHeader