import React from 'react'
import {useHistory} from "react-router"

import {IonButton, IonButtons, IonIcon, IonItem, IonItemOptions, IonLabel, IonNote, useIonAlert} from "@ionic/react"
import {keyOutline, keySharp, lockClosedOutline, lockClosedSharp} from "ionicons/icons"

import {Person as ItemType}     from "../../types/Person"
import ListItemOptionDelete     from "../People/ListItemOptionDelete"
import IconPerson               from "../common/Icons/Person"
import {useTranslation} from "react-i18next";

interface itemProps {
    item: ItemType;
}

const ListItem: React.FC<itemProps> = ({ item }) => {

    const
        history = useHistory(),
        keysCount: number = item.keys?.length || 0,
        [presentAlert] = useIonAlert(),
        {t} = useTranslation()

    const alertDataSource = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        return presentAlert({
            header:  t('common.alerts.data_source.header'),
            message: t('common.alerts.data_source.message', { itemType: t('people.title_item').toLowerCase() }),
            buttons: [
                {
                    text: t('common.cancel'),
                    role: 'cancel'
                }
            ]
        })
    }

    const goToPersonKeys = (ev: any, personId: number) => {
        ev.preventDefault()
        ev.stopPropagation()

        history.push('/people/' + personId + '/keys')
    }

    return (
        <>
            <IonItem button detail routerLink={"/people/" + item.id + '/view'} slot="start">
                <IconPerson slot="start" />
                <IonLabel>
                    {! item.firstName && ! item.lastName
                        ? <i>(geen naam)</i>
                        : item.firstName + ' ' + item.lastName }
                    <p>
                        {item.companyName && <span style={{ 'fontWeight': 300 }}>{item.companyName}</span>}
                        {(item.companyName && item.email) && <span>&nbsp;-&nbsp;</span>}
                        <span>{item.email || <br />}</span>
                    </p>
                </IonLabel>
                <IonNote slot="end">
                    {item.dataSourceId !== null &&
                        <IonButton color="medium" fill="clear"
                                   onClick={(e) => alertDataSource(e)}
                                   title={t('common.alerts.data_source.header').toString()}>
                            <IonIcon ios={lockClosedOutline} md={lockClosedSharp} />
                        </IonButton>
                    }
                </IonNote>
                <IonButtons className="ion-hide-sm-down" slot="end">
                    <IonButton color="medium" fill="clear"
                               onClick={ (ev) => goToPersonKeys(ev, item.id) }
                               size="large" style={{ 'fontSize': '17px' }}
                    >
                        {keysCount ? keysCount : '-'}
                        <IonIcon slot="end" color="bc-blue" ios={keyOutline} md={keySharp} />
                    </IonButton>
                </IonButtons>
            </IonItem>
            <IonItemOptions slot="end">
                {item.options?.isDeletable && <ListItemOptionDelete item={item} />}
            </IonItemOptions>
        </>
    )
}

export default ListItem