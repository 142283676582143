import React from "react"
import {RouteComponentProps} from "react-router"

import { useAuth } from "../../contexts/AuthContext"

interface pageProps extends RouteComponentProps<{
    id: string;
}> {}

const PageLoginCustom: React.FC<pageProps> = ({match}) => {
    const { handleCustomLogin } = useAuth()

    React.useEffect(() => {
        const redirectToCustomLogin = async (customId: string) => {
            const result = await handleCustomLogin(customId)

            // console.log(result.data)

            if (result.data.redirect_uri !== undefined) {
                window.location.replace(result.data.redirect_uri)
            }
        }

        redirectToCustomLogin(match.params.id);
    }, [])

    return (
        <div>Please wait...</div>
    )

}

export default PageLoginCustom